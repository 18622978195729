import React from 'react';
import { icon_gnb_sub, sprite_sub_3x, subMenuIcon } from 'assets/images';
import { Image, useContext, useRoutes, uuid } from 'lib';
import { useAppDispatch } from 'model';
import { gnbAction } from 'model/modules';
import styled from 'styled-components';
import { GlobalNavType } from 'types';
import { IMenuItems } from '@/types/menu';

type Props = {
  item: IMenuItems;
  depth1: number;
  depth2: number;
};

/**
 * @name 메인메뉴
 * @description
 */
export default function Contents({ item, depth1, depth2 }: Props) {
  const { navi, sitemap } = useContext();
  const { navigate } = useRoutes();
  const { name } = item;
  const dispatch = useAppDispatch();
  const isActived = !!(navi.depth1 === depth1 && navi.depth2 === depth2);
  const actived = isActived ? 'actived' : '';

  // 케이스별이름변경
  const setName = (name: string) => {
    if (name === '충전기 현황') return '충전기 현황';
    return name;
  };
  //
  return (
    <Content
      className={`gnb-menu ${actived}`}
      onClick={() => {
        const _temp = name.replace(/ /gi, '');
        const _menu = sitemap.find((element: any) => element.name.replace(/ /gi, '') === _temp);
        if (!_menu) {
          // console.log('메뉴가 없습니다.');
          // console.log(_temp);
          return;
        }
        dispatch(gnbAction.setNavi({ depth1, depth2, depth2_name: name, depth2_path: _menu.path }));
        // navigate(_menu?.path, { state: item?.link_url });
        navigate(_menu?.path);
      }}
      key={uuid()}>
      <figure className="sub" />
      {/* <Image src={subMenuIcon} style={{ width: 24, height: 24 }} /> */}
      {/* <label className="body4">{name}</label> */}
      <label className="body4">{setName(name)}</label>
    </Content>
  );
}

const Content = styled.button`
  /**-------본문--*/
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 44px;
  margin-bottom: 4px;
  padding-left: 16px;
  border-radius: 8px;
  label {
    margin-left: 4px;
  }
  /**-------아이콘--*/
  figure {
    background-image: url(${sprite_sub_3x});
  }
`;
