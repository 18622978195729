import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'model';
import { SESSION_STORAGE, validationStationName } from 'lib';
import ChargerRegisterPresenter from './ChargerRegister.presenter';
import { IChargepoint, IChargePointModelDetail, IVendor, ReqChargerRegister } from '@/types/infra';
import { SCREEN_MODE } from '@/types/mode';
import { actions } from '@/model/modules/device';
import { IResponse } from '@/lib/apis/api/type';
import {
  getCharger,
  getCheckChargepointNameDuplicated,
  getModel,
  patchCharger,
  registerCharger,
} from '@/lib/apis/infra';
import { MENU } from '@/pages/router';
import { RoamingField } from '@/pages/infra/charger-manage/ChargerRegister/ChargerRegister.type';

export default function ChargerRegisterContainer() {
  const { id } = useParams();
  const { t } = useTranslation('common');
  const { selectedStation, payload, mode, duplicatedCheck, view } = useAppSelector(state => state.device);
  const { cpo_seq } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchKeyword = searchParams.get('search') || '';
  const locationKeyword = searchParams.get('location') || '';
  const filterYn = searchParams.get('filter') || '';

  const navigate = useNavigate();
  const isModify = mode === SCREEN_MODE.MODIFY;

  const [popupCancelVisible, setPopupCancelVisible] = useState(false);
  const [stationSelectPopupVisible, setStationSelectPopupVisible] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<IVendor | null>(null);
  const [nameInputDesc, setNameInputDesc] = useState(t('forms.model.nameDesc'));
  const [forms, setForms] = useState({
    name: '',
  });
  const [validationResult, setValidationResult] = useState<{ name: boolean | 'none' }>({
    name: 'none',
  });
  const [popupFailedVisible, setPopupFailedVisible] = useState(false);
  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [requiredFieldsWarning, setRequiredFieldsWarning] = useState({
    name: false,
    requireDuplicateCheck: false,
    heartbeat_interval: false,
  });

  const queryParamsURL = () => {
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    if (filterYn) {
      queryParams.push(`filter=${encodeURIComponent(filterYn)}`);
    }
    queryParams.push(`page=${page}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const onInit = () => {
    dispatch(actions.initPayload());
    if (isModify || location.pathname.includes('update')) {
      dispatch(actions.setMode(SCREEN_MODE.MODIFY));
      fetchChargerData();
    } else {
      dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    }
  };

  const fetchChargerData = async () => {
    const cpoSeq = (await sessionStorage.getItem(SESSION_STORAGE.CPO_SEQ)) || cpo_seq;
    if (id && cpoSeq) {
      const { isSuccess, data }: IResponse<IChargepoint> = await getCharger(id, cpoSeq);
      const modelResponse: IResponse<IChargePointModelDetail[]> = await getModel(`${data.model.seq}`);
      if (modelResponse.isSuccess && isSuccess) {
        dispatch(actions.setView(data));
        dispatch(actions.setPayload(data));
        dispatch(actions.setSelectStation({ ...data.station }));
        dispatch(actions.setSelectModel(modelResponse.data[0]));
        // setChargerDetailData(chargerResponse.data);
      }
    }
  };

  const handleIsRoamingRequired = () => {
    dispatch(actions.setPayload({ ...payload, is_roaming: !payload.is_roaming }));
  };

  const handleOpenStationSelectPopup = () => {
    setStationSelectPopupVisible(true);
  };

  const handleCancelStationSelectPopup = () => {
    setStationSelectPopupVisible(false);
  };

  const onCancelPopup = () => {
    setPopupCancelVisible(true);
  };
  const handleCloseCancelPopup = () => {
    setPopupCancelVisible(false);
  };

  const onNameChangeHandler = (value: string) => {
    setForms(prevState => ({
      ...prevState,
      name: value,
    }));
    dispatch(
      actions.setPayload({
        ...payload,
        name: value,
      }),
    );
    dispatch(actions.setDuplicatedCheck(false));
  };

  const onHeartbeatChangeHandler = (value: number) => {
    let input = value;

    if (value < 0) {
      input = 1;
    }
    if (value > 30) {
      input = 30;
    }

    dispatch(actions.setPayload({ ...payload, heartbeat_interval: input }));
  };

  const onSelectedModel = async (seq: string, vendor?: IVendor) => {
    if (vendor) {
      setSelectedVendor(vendor);
    }
    const modelResponse: IResponse<IChargePointModelDetail[]> = await getModel(seq);
    if (modelResponse.isSuccess) {
      dispatch(actions.setSelectModel(modelResponse.data[0]));
    }
  };

  const onValidCheckResult = (key: string, value: boolean) => {
    setValidationResult(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const isNameDuplicated = async () => {
    if (!validationStationName(forms.name)) {
      onValidCheckResult('name', false);
      setNameInputDesc(t('forms.charger.nameDesc'));
      return;
    }
    const checkResult = await getCheckChargepointNameDuplicated({ name: forms.name, station_seq: selectedStation.seq });
    if (checkResult.isSuccess) {
      if (!checkResult.data) {
        onValidCheckResult('name', true);
        setNameInputDesc(t('forms.model.nameAvailableDesc'));
        dispatch(
          actions.setPayload({
            ...payload,
            name: forms.name,
          }),
        );
        dispatch(actions.setDuplicatedCheck(true));
      } else {
        onValidCheckResult('name', false);
        setNameInputDesc(t('forms.model.nameUnavailableDesc'));
      }
    }
  };

  const handleDone = () => {
    dispatch(actions.initPayload());
    setPopupSuccessVisible(false);
    if (isModify) {
      // navigate(MENU.INFRA.CHARGER_MANAGEMENT);
      navigate(`${MENU.INFRA.CHARGER_MANAGEMENT}/${id}?${queryParamsURL()}`);
    } else {
      navigate(MENU.INFRA.CHARGER_MANAGEMENT);
    }
  };

  const checkRequiredInputs = () => {
    setRequiredFieldsWarning({
      name: false,
      requireDuplicateCheck: false,
      heartbeat_interval: false,
    });
    let check = true;
    if (!payload.name || payload.name === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        name: true,
      }));
      check = false;
    } else if (!duplicatedCheck) {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        requireDuplicateCheck: true,
      }));
      check = false;
    }
    if (!payload.heartbeat_interval || payload.heartbeat_interval === null) {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        heartbeat_interval: true,
      }));
      check = false;
    }
    return check;
  };
  const onSubmit = async () => {
    if (checkRequiredInputs()) {
      let reqPayload: ReqChargerRegister = {
        ...payload,
        station_seq: selectedStation.seq,
        cpo_seq,
        // 기본값을 false로 넣어줘야 합니다.
        is_roaming: false,
      };
      if (payload.is_roaming) {
        reqPayload = {
          ...reqPayload,
          roaming_station_id: payload.roaming_station_id,
          roaming_chargepoint_id1: payload.roaming_chargepoint_id1,
          roaming_chargepoint_id2: payload.roaming_chargepoint_id2,
          is_roaming: true,
        };
      }

      try {
        const submitResponse = await registerCharger(reqPayload);
        if (submitResponse.isSuccess) {
          dispatch(actions.initPayload());
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
      }
    }
  };

  const onPatchSubmit = async () => {
    if (checkRequiredInputs() && id) {
      let reqPayload: ReqChargerRegister = {
        old_station_seq: selectedStation.seq,
        station_seq: selectedStation.seq,
        model_seq: payload.model.seq,
        heartbeat_interval: payload.heartbeat_interval,
        name: payload.name,
        cpo_seq,
        installed_at: payload.installed_at,
        produced_at: payload.produced_at,
        is_roaming: false,
      };
      if (payload.is_roaming) {
        reqPayload = {
          ...reqPayload,
          roaming_station_id: payload.roaming_station_id,
          roaming_chargepoint_id1: payload.roaming_chargepoint_id1,
          roaming_chargepoint_id2: payload.roaming_chargepoint_id2,
          is_roaming: true,
        };
      }

      try {
        const submitResponse = await patchCharger({ payload: reqPayload, seq: id });
        if (submitResponse.isSuccess) {
          dispatch(actions.initPayload());
          dispatch(actions.initView());
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
      }
    }
  };

  const handleRoamingInput = ({ fieldName, value }: { fieldName: RoamingField; value: string }) => {
    dispatch(
      actions.setPayload({
        ...payload,
        [fieldName]: value,
      }),
    );
  };

  const handleCloseFailedPopup = () => {
    setPopupFailedVisible(false);
  };

  useEffect(() => {
    return () => dispatch(actions.setSelectStation(null));
  }, []);

  useEffect(() => {
    onInit();
  }, []);

  return (
    <ChargerRegisterPresenter
      isModify={isModify}
      handleCloseCancelPopup={handleCloseCancelPopup}
      popupCancelVisible={popupCancelVisible}
      onCancelPopup={onCancelPopup}
      handleOpenStationSelectPopup={handleOpenStationSelectPopup}
      selectedVendor={selectedVendor}
      onSelectedModel={onSelectedModel}
      nameInputDesc={nameInputDesc}
      validationResult={validationResult}
      requiredFieldsWarning={requiredFieldsWarning}
      onNameChangeHandler={onNameChangeHandler}
      forms={forms}
      isNameDuplicated={isNameDuplicated}
      handleIsRoamingRequired={handleIsRoamingRequired}
      onHeartbeatChangeHandler={onHeartbeatChangeHandler}
      handleRoamingInput={handleRoamingInput}
      onSubmit={isModify ? onPatchSubmit : onSubmit}
      handleCancelStationSelectPopup={handleCancelStationSelectPopup}
      stationSelectPopupVisible={stationSelectPopupVisible}
      popupSuccessVisible={popupSuccessVisible}
      handleDone={handleDone}
      popupFailedVisible={popupFailedVisible}
      handleCloseFailedPopup={handleCloseFailedPopup}
    />
  );
}
