import { App, BackButton } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLOR } from '@/styles/index';
import { Button } from '@/lib/index';
import { PopupContainer, PopupCreate, SubmitCancel, TextFiledLabel } from '@/components/form';
import StationRegisterForm from '@/components/organisms/Forms/StationRegisterForm';
import OpensTable from '@/components/organisms/Tables/OpensTable';
import { StationRegisterScreenViewProps } from './StationRegister.types';
import { useAppSelector, RootState } from 'model';
import { SCREEN_MODE } from '@/types/mode';

export default function StationRegisterPresenter({
  onSubmit,
  onCancelPopup,
  popupSuccessVisible,
  popupCancelVisible,
  handleCloseCancelPopup,
  handleDone,
  requiredFieldsWarning,
  popupFailedVisible,
  handleCloseFailedPopup,
}: StationRegisterScreenViewProps) {
  const { mode, view } = useAppSelector((state: RootState) => state.station);
  const { t } = useTranslation('common');
  const isModify = mode === SCREEN_MODE.MODIFY;

  return (
    <App>
      <HeaderWrapper>
        <BackButton
          label={isModify ? `${view.name} / ${view.code}` : t('pages.infra.stationManage.register')}
          onClick={onCancelPopup}
        />
      </HeaderWrapper>
      <Wrapper>
        <MainContents>
          <Content>
            <StationRegisterForm requiredFieldsWarning={requiredFieldsWarning} />
            <OperationTimeWrapper>
              <TextFiledLabel label={t('forms.station.openSetting')} />
              <OpensTable isModify />
            </OperationTimeWrapper>
            <SubmitButtonsWrapper>
              <ButtonWrapper>
                <Button
                  full
                  label={t('common.buttons.cancel')}
                  color={COLOR.GRAY2}
                  borderColor={COLOR.GRAY2}
                  bg="transparent"
                  onClick={onCancelPopup}
                />
                <Button full label={t('common.buttons.registerAction')} onClick={onSubmit} />
              </ButtonWrapper>
            </SubmitButtonsWrapper>
          </Content>
        </MainContents>
      </Wrapper>
      <PopupContainer visible={popupSuccessVisible} onClose={handleDone}>
        <PopupCreate
          title={isModify ? t('common.toast.successToModifyStation') : t('common.toast.successToRegisterStation')}
          message={isModify ? t('common.toast.successToModifyStation2') : t('common.toast.successToRegisterStation2')}
          onConfirm={handleDone}
        />
      </PopupContainer>
      <PopupContainer visible={popupCancelVisible}>
        <PopupCreate
          warning
          title={isModify ? t('common.toast.cancelUpdateStation') : t('common.toast.cancelRegisterStation')}
          message={isModify ? t('common.toast.askCancel') : t('common.toast.askCancelWithoutSave')}
          footer={
            <SubmitCancel
              confirmLabel={
                isModify ? t('common.buttons.cancelUpdateAction') : t('common.buttons.cancelRegisterAction')
              }
              onCancel={handleCloseCancelPopup}
              onConfirm={handleDone}
            />
          }
        />
      </PopupContainer>
      <PopupContainer visible={popupFailedVisible} onClose={handleCloseFailedPopup}>
        <PopupCreate
          warning
          title={isModify ? t('common.toast.failedUpdateStationTitle') : t('common.toast.failedRegisterStationTitle')}
          message={
            isModify ? t('common.toast.failedUpdateStationMessage') : t('common.toast.failedRegisterStationMessage')
          }
          onConfirm={handleCloseFailedPopup}
        />
      </PopupContainer>
    </App>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  header {
    width: 674px;
    margin: 24px auto;
  }
`;

const MainContents = styled.div`
  width: 900px;
  margin: 24px auto;
`;

const Content = styled.div`
  padding-bottom: 30px;
`;
const OperationTimeWrapper = styled.div`
  width: 900px;
  margin: 0 auto 32px;
`;
const SubmitButtonsWrapper = styled.div`
  width: 400px;
  margin: 32px auto 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
`;
