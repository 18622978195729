/**
 * @name Guide
 * @description
 */
import styled from 'styled-components';
import { COLOR } from 'styles';
import { auth_mypage } from 'assets/images';
import { Button, Div, H2, H5, Image } from 'lib';
import { useTranslation } from 'react-i18next';
import {
  PopupContainer,
  PopupCreate,
  SubmitButton,
  TextFiledGroup,
  TextInput,
  TextSearchAddress,
} from '@/components/form';
import { App } from 'components';
import { MENU } from '@/pages/router';
import { adminsProps } from './container';
import { useEffect, useState } from 'react';
import { IAddress } from '@/types/infra';

// 포맷팅 함수
const formatPhoneNumber = (phone: string) => {
  // 한국 휴대폰 번호 형태로 포맷팅: 3-4-4
  return phone.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
};

const formatBusinessNumber = (number: string) => {
  // 사업자등록번호 포맷팅: 3-2-5
  return number.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
};

export default function Presenter(props: adminsProps) {
  const { admin, cpo, onSubmit, popupVisible, setPopupVisible } = props || {}; // 구조 분해 할당은 여기서 사용
  const { t } = useTranslation('common');
  // 상태 관리
  const [companyPhone, setCompanyPhone] = useState(cpo?.phone || '');
  const [csPhone, setCsPhone] = useState(cpo?.cs_phone || '');
  const [address, setAddress] = useState(cpo?.address || '');
  // const [detailAddress, setdetailAddress] = useState(cpo?.address || '');
  const [adminPhone, setAdminPhone] = useState(admin?.phone || ''); // 관리자 전화번호

  const handleSubmit = () => {
    const updatedData = {
      admin_phone: adminPhone, // 상태로 관리된 관리자 전화번호
      address: address, // 상태로 관리된 회사 주소
      companyPhone: companyPhone, // 상태로 관리된 회사 전화번호
      cs_phone: csPhone, // 상태로 관리된 회사 고객센터 전화번호
    };

    !!onSubmit && onSubmit(updatedData); // 변경된 데이터를 onSubmit으로 전달
  };

  const closePopup = () => {
    setPopupVisible && setPopupVisible(false);
  };

  //*--------------------------------------------------*
  return (
    <App>
      <Content>
        <div className="img">
          <Image src={auth_mypage} style={{ maxWidth: 540 }} />
        </div>
        <div className="wrap">
          <div className="in_wrap">
            {/* <div>{JSON.stringify(info, null, 1)}</div> */}
            <HeaderWrapper>
              <header>
                <HeaderTitle>
                  <H2 color={COLOR.GRAY1}>회사정보 설정</H2>
                </HeaderTitle>
              </header>
            </HeaderWrapper>
            {/* 이메일 */}
            <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1} label="이메일" />
            <TextInput
              disabled
              value={admin?.email}
              style={{ marginBottom: 16 }}
              placeholder={t('pages.auth.emailPlaceholder')}
            />
            {/* 사용자이름 */}
            <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1} label="사용자 이름" />
            <TextInput
              disabled
              value={admin?.name}
              style={{ marginBottom: 16 }}
              placeholder={'이름을 입력해 주세요.'}
            />
            {/* 사용자 휴대폰 번호 */}
            <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1} label="사용자 휴대폰 번호" />
            <TextInput
              value={adminPhone} // 포맷팅된 휴대폰 번호 표시
              style={{ marginBottom: 16 }}
              placeholder={'‘-’ 없이 번호만 입력하세요.'}
              onValue={value => {
                setAdminPhone(value.replace(/[^0-9]/g, ''));
              }}
              maxLength={11}
            />
            {/* 회사 전화번호 */}
            <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1} label="회사 전화번호" />
            <TextInput
              value={companyPhone} // 포맷팅된 회사 전화번호 표시
              style={{ marginBottom: 16 }}
              placeholder={'‘-’ 없이 번호만 입력하세요.'}
              onValue={value => {
                setCompanyPhone(value.replace(/[^0-9]/g, ''));
              }}
              maxLength={11}
            />
            {/* 회사 고객센터 전화번호 */}
            <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1} label="회사 고객센터 전화번호" />
            <TextInput
              style={{ marginBottom: 16 }}
              value={csPhone}
              placeholder={'‘-’ 없이 번호만 입력하세요.'}
              onValue={value => {
                setCsPhone(value.replace(/[^0-9]/g, ''));
              }}
              maxLength={11}
            />
            {/* 회사 주소 */}
            <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1} label="회사 주소" />
            <TextSearchAddress
              value={address || '주소찾기'}
              style={{ marginBottom: 16 }}
              onChange={(data: IAddress) => {
                setAddress(data.address);
              }}
            />
            {/* <TextInput
              style={{ marginBottom: 16 }}
              value={detailAddress}
              placeholder={'상세주소를 입력해주세요.'}
              onValue={setdetailAddress}
            /> */}
            {/* 회사명* */}
            <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1} label="회사명" />
            <TextInput disabled value={cpo?.name} style={{ marginBottom: 16 }} />
            {/* 사업자등록번호* */}
            <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1} label="사업자등록번호" />
            <TextInput
              disabled
              value={formatBusinessNumber(cpo?.corporation_number || '')} // 포맷팅된 사업자등록번호 표시
              style={{ marginBottom: 16 }}
            />
            <Button
              full
              // disabled={loginInfoState.email === '' || loginInfoState.password === ''}
              style={{ height: 40, marginBottom: 16 }}
              onClick={() => {
                handleSubmit();
              }}>
              <span className="button3">저장하기</span>
            </Button>
          </div>
        </div>
      </Content>
      <PopupContainer visible={popupVisible} onClose={closePopup}>
        <PopupCreate
          title={'수정이 완료되었습니다'}
          footer={<SubmitButton label={t('common.buttons.submit')} onConfirm={closePopup} />}
        />
      </PopupContainer>
    </App>
  );
}

const Content = styled.div`
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  align-items: center;
  overflow: hidden;
  .img {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    /* max-width: 540px; */
    height: 90vh;
    background-color: #f8f7fa;
    /* height: 100vh; */
  }
  .wrap {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    .in_wrap {
      max-width: 320px;
      margin: 0 auto;
    }
    /* max-width: 320px; */
    /* margin-right: 100px; */
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 320px;
  margin-bottom: 20px;
  flex-direction: column;

  .logo {
    display: block;
    width: 56px !important;
    height: 48px !important;
    margin: 0 auto 20px;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;
