import React from 'react';
import { H3, Row, Text } from 'lib';
import { App } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLOR } from '@/styles/index';
import { TextFiledSearch } from '@/components/form';
import DownloadButton from '@/components/atoms/Buttons/DownloadButton';
import { useAppSelector } from '@/model/index';
import SalesChargingStatusTable from '@/components/organisms/Tables/SalesChargingStatusTable';
import FilterButton from '@/components/atoms/Buttons/FilterButton';

export default function ChargingStatusListPresenter({
  itemsPerPage,
  currentPage,
  pageHandler,
  handleExcelDownload,
  handleSearchKeyword,
  handleSearch,
  searchKeyword,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  handleExcelDownload: () => void;
  handleSearchKeyword: (keyword: string | '') => void;
  handleSearch: () => void;
  searchKeyword: string;
}) {
  const { t } = useTranslation('common');
  const { totalCount } = useAppSelector(state => state.chargingStatus);
  return (
    <App>
      <Header style={{ width: '100%' }}>
        <HeaderFuncWrapper>
          <Row>
            <div style={{ marginRight: 20 }}>
              <H3 color={COLOR.BLACK3}>{t('pages.sales.chargingStatus.title')}</H3>
              <Text color={COLOR.BLACK3} className="body6">
                {`${t('common.unit.total')} ${totalCount}${t('common.unit.cases')}`}
              </Text>
            </div>
            <div className="search" style={{ marginLeft: 20 }}>
              <TextFiledSearch
                defaultValue={searchKeyword}
                maxLength={25}
                placeholder={t('pages.infra.chargerManage.stationSearch.searchStationPlaceholder')}
                onChange={str => {
                  handleSearchKeyword(str);
                }}
                onSubmit={handleSearch}
              />
            </div>
            {/* <FilterButton
              style={{ marginLeft: 8 }}
              // value={search_payload.isOpen}
              onChange={() => {
                // dispatch(actions.setSearchPayload({ isOpen: !search_payload.isOpen }));
                // dispatch(_action.setSearchPayload({ isOpen: bool }));
                // console.log(bool)
              }}
            /> */}
          </Row>
          <ButtonWrapper>
            <DownloadButton onClick={handleExcelDownload} />
          </ButtonWrapper>
        </HeaderFuncWrapper>
      </Header>
      <SalesChargingStatusTable itemsPerPage={itemsPerPage} currentPage={currentPage} pageHandler={pageHandler} />
    </App>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLOR.GRAY5};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderFuncWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 16px 16px 24px;
  align-items: center;
  justify-content: space-between;
`;
const FilterWrapper = styled.div`
  padding: 8px 32px;
`;
