import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResLogin } from '@/pages/common';
import { SESSION_STORAGE } from '@/lib/const';

/**
 * @name Auth,계정관리
 * @description
 */
export const MODE = {
  LIST: 'LIST',
  VIEW: 'VIEW',
  MODIFY: 'MODIFY',
  REGISTER: 'REGISTER', // 등록
  EMPTY: 'EMPTY',
  DEFAULT: 'DEFAULT',
  // 로그인,비밀번호찾기
  SIGNIN: 'SIGNIN', // 로그인
  SETTINGS: 'SETTINGS', // 회사정보설정
  NEW_PASSWORD: 'NEW_PASSWORD', // 새비밀번호설정
  LOST_PASSWORD: 'LOST_PASSWORD', // 비밀번호찾기
};

// 유저정보,팝업타입
export const POPUP = {
  LOGIN_FAIL: 'LOGIN_FAIL', // 로그인실패
  SEND_MAIL: 'SEND_MAIL', // 메일발송
};
export const EVENT = {
  INIT: 'INIT',
  POPUP_SHOW: 'POPUP_SHOW', // 팝업실행
  POPUP_HIDE: 'POPUP_HIDE', // 팝업닫기
  SUBMIT_SIGNIN: 'SUBMIT_SIGNIN', // 로그인실행
  SET_MODE: 'SET_MODE',
  MODE_DEFAULT: 'DEFAULT', // 로그인,패스워드
  MODE_LOST_PASSWORD: 'LOST_PASSWORD', // 비밀번호찾기
  INVITE_USER: 'INVITE_USER', // 사용자초대
  VIEW_USER: 'VIEW_USER', // 사용자상세보기
  // INVITE_USER: 'INVITE_USER', // 사용자초대
};

/** @defineType */
type AuthState = {
  mode: string;
  cpo_seq: number | string | null;
  business_code: string | null;
  user: ResLogin | null;
  account: {
    mode: string;
    permission: string;
  };
  login: {
    email: string | null;
    password: string | null;
  };
  admins: null | {};
  permissions: null | {};
  [key: string]: any;
};

/** @initialState */
const initialState: AuthState = {
  mode: MODE.DEFAULT,
  cpo_seq: sessionStorage.getItem(SESSION_STORAGE.CPO_SEQ) || null,
  business_code: null,
  user: null,
  account: {
    mode: MODE.LIST,
    permission: 'DEFAULT',
  },
  login: {
    email: null,
    password: null,
  },
  admins: null,
  permissions: null,
};

/** @createSlice */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // setInit: () => initialState,
    setRefreshUser: (state: AuthState, action: PayloadAction<{ cpoSeq: string; businessCode: string }>) => {
      state.cpo_seq = action.payload.cpoSeq;
      state.business_code = action.payload.businessCode;
    },
    // 유저정보업데이트
    setUser: (state: AuthState, action: PayloadAction<ResLogin>) => {
      state.user = action.payload;
      state.cpo_seq = action.payload.admin_cpo_list?.[0]?.cpo.seq;
      state.business_code = action.payload.admin_cpo_list?.[0]?.cpo.code;
    },
    // setData
    setLogin: (state: AuthState, action: PayloadAction<any>) => {
      state.login = Object.assign(state.login, action.payload);
    },
    // setMode
    setMode: (state: AuthState, action: PayloadAction<any>) => {
      state.mode = action.payload;
    },
    // setAdmins
    setAdmins: (state: AuthState, action: PayloadAction<any>) => {
      state.admins = action.payload;
    },
    // 계정권한자세히보기
    setPermissions: (state: AuthState, action: PayloadAction<any>) => {
      state.permissions = action.payload;
    },
    // create Payload업데이트
    setPayload: (state: AuthState, action: PayloadAction<any>) => {
      state.payload = Object.assign(state.payload, action.payload);
    },
  },
});

export default authSlice.reducer;
export const { setUser, setLogin, setMode, setAdmins, setPermissions, setPayload } = authSlice.actions;
export const { actions } = authSlice;
