import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'model';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountManagementPresenter from './AccountManagementList.presenter';
import { actions } from '@/model/modules/admin';
import { getAdminAccounts } from '@/lib/apis/admin';
import { AdminAccountsRequest } from '@/lib/apis/admin/type';

/**
 * @name AccountManagementListContainer
 * @description 계정별 권한관리
 */
export default function AccountManagementListContainer() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const searchQueryString = searchParams.get('search') || '';
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString);
  const [appliedSearchKeyword, setAppliedSearchKeyword] = useState(searchQueryString);

  // 초기화
  const onInit = useCallback(() => {
    fetchList({
      size: itemsPerPage,
      page: currentPage,
    });
  }, [dispatch]);

  const fetchList = async (reqParams?: AdminAccountsRequest) => {
    const adminAccountsResponse = await getAdminAccounts(reqParams);
    if (adminAccountsResponse.isSuccess) {
      dispatch(
        actions.setList({
          list: adminAccountsResponse.data.rows,
          cpo_count: adminAccountsResponse.data.cpo_count,
          admin_count: adminAccountsResponse.data.admin_count,
          totalCount: adminAccountsResponse.meta.paging?.total_count ?? 0,
        }),
      );
    }
  };

  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?page=${page}`);
      fetchList({
        size: itemsPerPage,
        email: searchKeyword,
        page,
      });
    }
  };

  const handleSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleSearch = () => {
    setAppliedSearchKeyword(searchKeyword);
    fetchList({
      size: itemsPerPage,
      email: searchKeyword,
      page: currentPage,
    });
  };

  // 사용자초대
  async function onInvite() {
    alert('사용자초대');
  }

  useEffect(() => {
    onInit();
  }, []);

  return (
    <AccountManagementPresenter
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      pageHandler={pageHandler}
      searchKeyword={searchKeyword}
      handleSearchKeyword={handleSearchKeyword}
      handleSearch={handleSearch}
    />
  );
}
