import ChargepointListPresenter from './ChargepointList.presenter';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'model';
import { useEffect, useState } from 'react';
import { actions } from '@/model/modules/chargepoint';
import { ChargePointRequest } from '@/lib/apis/infra/type';
import { getChargers } from '@/lib/apis/infra';

export default function ChargepointListContainer() {
  const { list } = useAppSelector(state => state.chargepoint);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchQueryString = searchParams.get('search') || '';
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString || undefined);
  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(Number(page) || 1);

  const handleSearch = () => {
    navigate(`?${queryParamsURL(1, searchKeyword)}`);
    fetchList({
      page: currentPage,
    });
  };

  const queryParamsURL = (page?: number, search?: string) => {
    const queryParams = [];
    if (search) queryParams.push(`search=${encodeURIComponent(search)}`);
    queryParams.push(`size=${itemsPerPage}`);
    queryParams.push(`page=${page ?? currentPage}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const fetchList = async ({ page, search }: { page: number; search?: string }) => {
    let payload: ChargePointRequest = {
      size: itemsPerPage,
      page: page,
      search: searchKeyword,
    };
    if (search) payload = { ...payload, search };
    const { isSuccess, data, meta } = await getChargers(payload);
    if (isSuccess) {
      dispatch(
        actions.setList({
          list: data,
          totalCount: meta.paging?.total_count ?? 0,
        }),
      );
    }
  };

  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?${queryParamsURL(page, searchKeyword)}`);
      fetchList({ page, search: searchKeyword });
    }
  };

  useEffect(() => {
    fetchList({
      page: currentPage,
    });
  }, []);

  useEffect(() => {
    setCurrentPage(Number(page));
  }, [page]);

  return (
    <ChargepointListPresenter
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      pageHandler={pageHandler}
      handleSearch={handleSearch}
      handleSearchKeyword={setSearchKeyword}
      searchKeyword={searchKeyword}
      queryString={queryParamsURL()}
    />
  );
}
