import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'model';
import { SESSION_STORAGE } from 'lib';
import dayjs from 'dayjs';
import ChargerListPresenter from './ChargerList.presenter';
import { getChargepointsExcelFile, getChargers, removeCharger } from '@/lib/apis/infra';
import { actions } from '@/model/modules/device';
import { ChargePointRequest } from '@/lib/apis/infra/type';
import { MENU } from '@/pages/router';
import { SCREEN_MODE } from '@/types/mode';
import { IChargerFilter } from '@/components/organisms/Filters/ChargerFilter/ChargerFilter.type';
import { ILocation } from '@/types/location';

export default function ChargerListContainer() {
  const { search_payload } = useAppSelector(state => state.device);
  const { cpo_seq } = useAppSelector(state => state.auth);
  const { locations } = useAppSelector(state => state.location);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchQueryString = searchParams.get('search') || '';
  const locationQueryString = searchParams.get('location') || '전체지역';

  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const [appliedSearchKeyword, setAppliedSearchKeyword] = useState(searchQueryString);
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString);

  const [deletePopup, setDeletePopup] = useState(false);
  const [removeTargetId, setRemoveTargetId] = useState<null | string>(null);

  const [detailSearchTerms, setDetailSearchTerms] = useState<IChargerFilter>({
    searchKeyword: '',
    location: locationQueryString,
    chargerType: [],
    isConcurrency: [],
    connectorType: [],
    manufacturerKeyword: [],
    roamingME: [],
  });

  const itemsPerPage = 10;

  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?${queryParamsURL({ page })}`);
    }
  };
  const handleDeletePopup = (value: boolean) => {
    setDeletePopup(value);
  };
  const handleRemoveTargetId = (id: string) => {
    setRemoveTargetId(id);
  };

  const fetchList = async (payload?: ChargePointRequest) => {
    let termsPayload: ChargePointRequest = {
      size: itemsPerPage,
      page: currentPage,
      cpo_seq,
      search: appliedSearchKeyword,
    };
    if (detailSearchTerms.roamingME.length === 1) {
      termsPayload = { ...termsPayload, is_roaming: detailSearchTerms.roamingME[0] };
    }
    if (detailSearchTerms.location !== '전체지역') {
      if (locations.length > 0) {
        const { seq } = locations.find((location: ILocation) => location.name === detailSearchTerms.location);
        termsPayload = { ...termsPayload, kr_location_seq: seq };
      }
    }
    if (detailSearchTerms.chargerType.length === 1) {
      termsPayload = { ...termsPayload, charger_type: detailSearchTerms.chargerType[0] };
    }
    if (detailSearchTerms.isConcurrency.length === 1) {
      termsPayload = { ...termsPayload, is_concurrency: detailSearchTerms.isConcurrency[0] };
    }
    if (detailSearchTerms.connectorType.length > 0 && detailSearchTerms.connectorType.length < 5) {
      termsPayload = { ...termsPayload, connector_type: [...detailSearchTerms.connectorType] };
    }
    if (detailSearchTerms.manufacturerKeyword.length > 0) {
      termsPayload = { ...termsPayload, vendor_search: [...detailSearchTerms.manufacturerKeyword] };
    }
    // if (cpo_seq) {
    const chargersResponse = await getChargers(termsPayload);
    if (chargersResponse.isSuccess) {
      dispatch(
        actions.setChargerList({
          list: chargersResponse.data,
          totalCount: chargersResponse.meta.paging?.total_count ?? 0,
        }),
      );
    }
    // }
  };

  const handleRegister = () => {
    dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    navigate(MENU.INFRA.CHARGER_MANAGEMENT_REGISTER);
  };

  const handleSetDetailSearchTerms = (terms: IChargerFilter) => {
    setDetailSearchTerms(terms);
  };

  const queryParamsURL = ({
    page,
    location,
    search,
    filterVisible,
    chargerType,
    roamingME,
    concurrency,
    connectorType,
    vendorSearch,
  }: {
    page?: number;
    location?: string;
    search?: string;
    filterVisible?: boolean;
    chargerType?: string[];
    roamingME?: boolean;
    concurrency?: boolean[];
    connectorType?: string[];
    vendorSearch?: string[];
  }) => {
    const locationValue = location ?? detailSearchTerms.location;
    const locationKeyword = locationValue === '전체지역' ? '' : locationValue;
    const searchKeyword = search ?? appliedSearchKeyword;
    const isFilterVisible = filterVisible ? 'y' : 'n';
    const isRoamingME = roamingME ? 'y' : 'n';
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    if (chargerType) {
      queryParams.push(`chargerType=${encodeURIComponent(chargerType.toString())}`);
    }
    if (roamingME) {
      queryParams.push(`roamingME=${encodeURIComponent(isRoamingME)}`);
    }
    if (concurrency) {
      queryParams.push(`concurrency=${encodeURIComponent(concurrency.toString())}`);
    }
    if (connectorType) {
      queryParams.push(`connectorType=${encodeURIComponent(connectorType.toString())}`);
    }
    if (vendorSearch) {
      queryParams.push(`vendorSearch=${encodeURIComponent(vendorSearch.toString())}`);
    }
    queryParams.push(`filter=${encodeURIComponent(isFilterVisible)}`);
    queryParams.push(`page=${page ?? currentPage}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const handleSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const handleSearch = () => {
    setDetailSearchTerms(prevState => ({ ...prevState, searchKeyword }));
    const locationKeyword = detailSearchTerms.location === '전체지역' ? '' : detailSearchTerms.location;
    navigate(`?${queryParamsURL({ page: 1, location: locationKeyword, search: searchKeyword })}`);
  };

  const handleExcelDownload = async () => {
    let termsPayload: ChargePointRequest = {
      size: itemsPerPage,
      page: currentPage,
      cpo_seq,
      search: appliedSearchKeyword,
    };
    if (detailSearchTerms.roamingME.length === 1) {
      termsPayload = { ...termsPayload, is_roaming: detailSearchTerms.roamingME[0] };
    }
    if (detailSearchTerms.location !== '전체지역') {
      if (locations.length > 0) {
        const { seq } = locations.find((location: ILocation) => location.name === detailSearchTerms.location);
        termsPayload = { ...termsPayload, kr_location_seq: seq };
      }
    }
    if (detailSearchTerms.chargerType.length === 1) {
      termsPayload = { ...termsPayload, charger_type: detailSearchTerms.chargerType[0] };
    }
    if (detailSearchTerms.isConcurrency.length === 1) {
      termsPayload = { ...termsPayload, is_concurrency: detailSearchTerms.isConcurrency[0] };
    }
    if (detailSearchTerms.connectorType.length > 0 && detailSearchTerms.connectorType.length < 5) {
      termsPayload = { ...termsPayload, connector_type: [...detailSearchTerms.connectorType] };
    }
    if (detailSearchTerms.manufacturerKeyword.length > 0) {
      termsPayload = { ...termsPayload, vendor_search: [...detailSearchTerms.manufacturerKeyword] };
    }
    if (cpo_seq) {
      await getChargepointsExcelFile(termsPayload, `충전기목록_${dayjs().format('YYYY_MM_DD')}.xlsx`);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      if (removeTargetId) {
        const removeResponse = await removeCharger(removeTargetId);
        if (removeResponse.isSuccess) {
          handleDeletePopup(false);
          fetchList();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(actions.setInit());
    dispatch(actions.initPayload());
    dispatch(actions.initView());
    dispatch(actions.setSelectStation(null));
    dispatch(actions.setSelectModel(null));
  }, []);

  useEffect(() => {
    let termsPayload: ChargePointRequest = {
      size: itemsPerPage,
      page: currentPage,
      cpo_seq,
      search: appliedSearchKeyword,
    };
    if (detailSearchTerms.roamingME.length === 1) {
      termsPayload = { ...termsPayload, is_roaming: detailSearchTerms.roamingME[0] };
    }
    if (detailSearchTerms.location !== '전체지역') {
      if (locations.length > 0) {
        const { seq } = locations.find((location: ILocation) => location.name === detailSearchTerms.location);
        termsPayload = { ...termsPayload, kr_location_seq: seq };
      }
    }
    if (detailSearchTerms.chargerType.length === 1) {
      termsPayload = { ...termsPayload, charger_type: detailSearchTerms.chargerType[0] };
    }
    if (detailSearchTerms.isConcurrency.length === 1) {
      termsPayload = { ...termsPayload, is_concurrency: detailSearchTerms.isConcurrency[0] };
    }
    if (detailSearchTerms.connectorType.length > 0 && detailSearchTerms.connectorType.length < 5) {
      termsPayload = { ...termsPayload, connector_type: [...detailSearchTerms.connectorType] };
    }
    if (detailSearchTerms.manufacturerKeyword.length > 0) {
      termsPayload = { ...termsPayload, vendor_search: [...detailSearchTerms.manufacturerKeyword] };
    }

    sessionStorage.setItem(SESSION_STORAGE.FIlTER_TERMS.CHARGER, JSON.stringify(termsPayload));
    fetchList(termsPayload);
  }, [detailSearchTerms, currentPage, itemsPerPage, appliedSearchKeyword, cpo_seq, locations]);

  useEffect(() => {
    setCurrentPage(Number(page));
  }, [page]);

  return (
    <ChargerListPresenter
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      pageHandler={pageHandler}
      queryString={queryParamsURL({})}
      handleDelete={{ popup: handleDeletePopup, setId: handleRemoveTargetId }}
      onRegister={handleRegister}
      handleExcelDownload={handleExcelDownload}
      detailSearchTerms={detailSearchTerms}
      handleSetDetailSearchTerms={handleSetDetailSearchTerms}
      handleSearchKeyword={handleSearchKeyword}
      handleSearch={handleSearch}
      searchKeyword={searchKeyword}
      deletePopupVisible={deletePopup}
      onDeleteConfirm={handleDeleteConfirm}
    />
  );
}
