import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Button, Image } from 'lib';
import {
  InnerCell,
  InnerCellContainer,
  InnerCellTextButton,
  NoResultDescription,
  NoResultText,
  NoResultTextWrapper,
  NoSearchResultTextWrapper,
  NoSearchResultWrapper,
  SearchKeyword,
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
  Wrapper,
} from './VendorsTable.style';
import typography from '@/styles/typography';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { MENU } from '@/pages/router';
import { IVendor } from '@/types/infra';
import { COLOR } from '@/styles/color';
import { formatDate } from '@/lib/util';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { SortElementType, SortType } from '@/lib/apis/infra/type';
import IconButtonsModifyDel from '@/components/molecules/ButtonGroups/ModifyDel';
import { actions } from '@/model/modules/vendor';
import { SCREEN_MODE } from '@/types/mode';
import { icon_plus_white, icons } from '@/assets/images';
import { ICountry } from '@/types/location';

function VendorsTable({
  itemsPerPage,
  currentPage,
  pageHandler,
  sortingHandler,
  handleDelete,
  queryString,
  onRegister,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  sortingHandler?: ({ sort_element, sort_type }: { sort_element: SortElementType; sort_type: SortType }) => void;
  handleDelete: {
    popup: (value: boolean) => void;
    setId: (value: string) => void;
  };
  queryString: string;
  onRegister: () => void;
}) {
  const { t } = useTranslation('common');
  const { list, totalCount } = useAppSelector(state => state.vendor);
  const { countries } = useAppSelector(state => state.location);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchKeyword = searchParams.get('search');
  const searchLocation = searchParams.get('location');

  const handleEdit = (id: string) => {
    dispatch(actions.setMode(SCREEN_MODE.MODIFY));
    navigate(`${MENU.INFRA.VENDOR_MANAGEMENT_UPDATE}/${id}?${queryString}`);
  };

  const handleNavigateToDetail = (seq: string) => {
    dispatch(actions.setMode(SCREEN_MODE.VIEW));
    navigate(`${MENU.INFRA.VENDOR_MANAGEMENT_DETAIL}/${seq}`);
  };

  const findCountryName = (code: string) => {
    if (countries.length > 0) {
      const { name } = countries.find((element: ICountry) => element.code2 === code);
      return name;
    }
    return '-';
  };

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 56 }} />
          <col style={{ minWidth: 312 }} />
          <col style={{ minWidth: 118 }} />
          <col style={{ minWidth: 136 }} />
          <col style={{ minWidth: 60 }} />
          <col style={{ minWidth: 322 }} />
          <col style={{ minWidth: 120 }} />
          <col style={{ minWidth: 76 }} />
          <col style={{ minWidth: 88 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>
              <InnerCell>{t('forms.model.manufacturerName')}</InnerCell>
            </TH>
            <TH>
              <InnerCell>{t('forms.model.manufacturerID')}</InnerCell>
            </TH>
            <TH>
              <InnerCell>{t('forms.model.country')}</InnerCell>
            </TH>
            <TH>{t('pages.accountManage.station.manageStation.address')}</TH>
            <TH>{t('forms.model.registerDate')}</TH>
            <TH>{t('forms.model.modelsCount')}</TH>
            <TH>{t('common.buttons.updateDelete')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list.length > 0 &&
            list.map((item: IVendor, index: number) => (
              <TR key={nanoid()}>
                <TD $customFont={typography.body4}>{totalCount - (currentPage - 1) * itemsPerPage - index}</TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.seq)}>
                      {item?.name}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.seq)}>
                      {item.vendor_id}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4}>{findCountryName(item.country_code_2)}</TD>
                <TD $customFont={typography.caption1} color={COLOR.GRAY2}>
                  <InnerCellContainer>
                    {item.address ? `${item.address ? item.address : ''} ${item.address_detail}` : '-'}
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4}>{formatDate(item.created_at)}</TD>
                <TD $customFont={typography.body4}>{item.model_count}</TD>
                <TD>
                  <IconButtonsModifyDel
                    onModify={() => handleEdit(item?.seq)}
                    onDelete={() => {
                      handleDelete.popup(true);
                      handleDelete.setId(`${item?.seq}`);
                    }}
                  />
                </TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length === 0 && (searchLocation || searchKeyword) && (
        <NoSearchResultWrapper>
          <Image src={icons.noSearchResult.image} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <SearchKeyword>
                {searchLocation} {searchKeyword}
              </SearchKeyword>
              <NoResultText>{t('pages.infra.vendorManage.noSearchResult')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription>{t('pages.infra.vendorManage.noSearchResultDescription')}</NoResultDescription>
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
      {list.length === 0 && !searchLocation && !searchKeyword && (
        <NoSearchResultWrapper>
          <Image src={icons.noVendors.image} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <NoResultText>{t('pages.infra.vendorManage.noVendors')}</NoResultText>
            </NoResultTextWrapper>
            <NoResultDescription>{t('pages.infra.vendorManage.noVendorsDescription')}</NoResultDescription>
          </NoSearchResultTextWrapper>
          <Button
            icon={icon_plus_white}
            label={t('pages.infra.vendorManage.register')}
            style={{ marginRight: 16 }}
            onClick={onRegister}
          />
        </NoSearchResultWrapper>
      )}
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
    </Wrapper>
  );
}

export default VendorsTable;
