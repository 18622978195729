/**
 * @name 급속타입
 * @description
 */
import Badge from '@/components/atoms/Badge';
import { Text, uuid } from 'lib';
import { formatConnector } from 'pages/common';
import styled from 'styled-components';

type Props = {
  info: any[];
  onClick?: () => void;
};
export default function ConnectorsType({ info }: Props) {
  if (!info) return null;
  // hooks
  // if (type.toLocaleUpperCase() === 'AVAILABLE') _type = {mode: 'Success', label: '충전가능'}
  // if (type.toLocaleUpperCase() === 'UNAVAILABLE') _type = {mode: 'Danger', label: '사용불가'}
  // const {mode, label} = _type

  //*--------------------------------------------------*
  return (
    <Element>
      {info.map((item, index) => {
        // hooks
        let _type = { mode: '___NOT', label: '___NOT' };
        if (_type?.mode === 'Available') _type = { mode: 'Success1', label: '충전가능' };
        if (_type?.mode === 'Unavailable') _type = { mode: 'Danger', label: '사용불가' };
        const { mode, label } = _type;
        // console.log(item)
        return (
          <div className="group" key={uuid()}>
            <p>
              <Text className={'body4'}>{formatConnector(item?.types)}</Text>
            </p>
            <Badge mode="Primary" label={item?.device_status?.status} />
          </div>
        );
      })}
    </Element>
  );
}

//*--------------------------------------------------*
const Element = styled.div`
  /* padding-left: 10px; */
  text-align: left;
  .group {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
  }
  /* padding: 24px 0 24px; */
  .body4 {
    /* display: inline-block; */
    width: 80px;
    /* margin-right: 10px; */
  }
`;

/********************************************************
[사용법]

*********************************************************/
