/**
 * @name 보유충전기및적용된충전단가
 * @description
 */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Row, addComma, useAxios, useContext, uuid } from 'lib';
import { COLOR } from 'styles';
import { ListKeyComponent, ListKeyValue, TextFiledLabel } from 'components/form';
import { API_HOST } from 'config';
import { MemberType } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { log } from 'console';
import { MENU } from '@/pages/router';

// TODO Tariff 이후(충전기 등록 이후 다시 구현)
export default function StationStatusTable({ tabSelected }: { tabSelected?: boolean }) {
  const navigate = useNavigate();
  // const
  const [stations, setStations] = useState<any>(undefined);
  const [amount, setAmount] = useState<any>(undefined);
  const { get } = useAxios();
  const { cpo_seq } = useContext();
  const { id } = useParams();

  // 상세보기
  async function fetch_view(seq: string) {
    const res = await get(`${API_HOST}/stations/${id}`);
    if (res?.isSuccess) {
      setStations(res.data.chargepoint_count);
    } else {
    }
  }
  async function fetch_price(seq: string) {
    const res = await get(`${API_HOST}/cpo/tariff/stations/${seq}?cpo_seq=${cpo_seq}`);
    if (res?.isSuccess) {
      setAmount(res.data);
    } else {
    }
  }

  const priceFormatting = (val: number) => {
    return val ? `${addComma(val)}원` : '-';
  };

  useEffect(() => {
    if (id) {
      fetch_view(id);
      fetch_price(id);
    }
  }, []);
  //* --------------------------------------------------*
  return (
    <Content $tabSelected={tabSelected}>
      {/* <div className="top"> */}
      {/* {!!stations &&
          stations?.data?.current_tariff?.map((item: any, index: number) => {
            return (
              <section className="section" key={uuid()}>
                <MemberType type={item?.is_user} />
                <Row style={{ marginTop: 30, justifyContent: 'center' }}>
                  <div style={{ marginRight: 10 }}>
                    <p className="body3">급속단가</p>
                    <p className="price">
                      <b>{addComma(item?.fast_amount)}</b>
                      원/kwh
                    </p>
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <p className="body3">완속단가</p>
                    <p className="price">
                      <b>{addComma(item?.fast_amount)}</b>
                      원/kwh
                    </p>
                  </div>
                </Row>
              </section>
            );
          })}
      </div> */}
      <div style={{ marginBottom: 40 }}>
        <TextFiledLabel label="보유 충전기 및 적용된 충전 단가" />
        <ListKeyValue
          title="보유 충전기 수"
          component={
            <>
              {stations ? (
                <span>{addComma(stations)}대</span>
              ) : (
                <div style={{ display: 'flex', color: COLOR.RED1 }}>
                  등록된 충전기가 없습니다.
                  <br /> 충전기를 등록해 주세요.
                  <Button
                    label="충전기 등록"
                    style={{ marginLeft: 15 }}
                    onClick={() => navigate(MENU.INFRA.CHARGER_MANAGEMENT_REGISTER)}
                  />
                </div>
              )}
            </>
          }
        />
        <ListKeyValue
          title="충전단가 기준"
          component={
            <>
              {amount?.tariff_type ? (
                <span>{amount.tariff_type === 'FIXED' ? '고정단가' : '계시별단가'}</span>
              ) : (
                <div style={{ display: 'flex', color: COLOR.RED1 }}>
                  설정된 충전단가가 없습니다.
                  <br /> 충전단가를 설정해 주세요.
                  <Button label="충전단가 설정" style={{ marginLeft: 15 }} />
                </div>
              )}
            </>
          }
        />
        <ListKeyValue
          title="충전단가"
          component={
            <div>
              <div>회원급속: {priceFormatting(amount?.user_price.fast_amount)} </div>
              <div>회원완속: {priceFormatting(amount?.user_price.slow_amount)} </div>
              <div>비회원급속: {priceFormatting(amount?.none_user_price.fast_amount)} </div>
              <div>비회원완속: {priceFormatting(amount?.none_user_price.slow_amount)} </div>
              {/* <div>로밍회원</div> */}
            </div>
          }
        />
      </div>
    </Content>
  );
}
//* --------------------------------------------------*
const Content = styled.div<{ $tabSelected?: boolean }>`
  ${props =>
    props.$tabSelected
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 920px;
    height: 180px;
    margin: 24px auto;
    /* padding: 30px 30px; */
    border: 1px solid ${COLOR.GRAY5};
    border-radius: 8px;
    /* 구분 */
    section.section {
      text-align: center;
      width: 200px;
      margin: 20px 20px;
      padding: 37px 0;
      .body3 {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.45px;
      }
      p.price {
        font-size: 13px;
        line-height: 24px;
        letter-spacing: -0.45px;
        color: ${COLOR.GRAY2};
        b {
          display: inline-block;
          margin-right: 2px;
          color: ${COLOR.BLUE3};
          font-weight: bold;
        }
      }
    }
  }
`;
