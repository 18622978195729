import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import * as To from 'pages';
import StationManageList from '@/pages/infra/station-manage/StationList';
import StationManageDetail from '@/pages/infra/station-manage/StationDetail';
import StationManageRegister from '@/pages/infra/station-manage/StationRegister';
import StationManageUpdate from './infra/station-manage/StationUpdate';
import ChargerManageList from '@/pages/infra/charger-manage/ChargerList';
import ChargerRegister from '@/pages/infra/charger-manage/ChargerRegister';
import ChargerDetail from '@/pages/infra/charger-manage/ChargerDetail';
import VendorList from '@/pages/infra/vendor-manage/VendorList';
import VendorRegister from '@/pages/infra/vendor-manage/VendorRegister';
import VendorDetail from '@/pages/infra/vendor-manage/VendorDetail';
import ModelList from '@/pages/infra/model-manage/ModelList';
import ModelRegister from '@/pages/infra/model-manage/ModelRegister';
import ErrorCodeList from '@/pages/infra/errorcode-manage/ErrorCodeList';
import ModelDetail from '@/pages/infra/model-manage/ModelDetail';
import ChargingRateList from '@/pages/charging-rate/charging-rate-management/ChargingRateList';
import ChargingRateRegister from '@/pages/charging-rate/charging-rate-management/ChargingRateRegister';
import ChargepointListOperation from '@/pages/operation/chargepoint-operation/ChargepointList';
import CalculateList from '@/pages/sales/calculate-status/CalculateList';
import CalculateDetail from '@/pages/sales/calculate-status/CalculateDetail';
import ChargepointDetailOperation from '@/pages/operation/chargepoint-operation/ChargepointDetail';
import PaymentStatusList from '@/pages/sales/payment-status/PaymentStatusList';
import PaymentStatusDetail from '@/pages/sales/payment-status/PaymentStatusDetail';
import ChargingStatusList from '@/pages/sales/charging-status/ChargingStatusList';
import ChargingStatusDetail from '@/pages/sales/charging-status/ChargingStatusDetail';
import FaultHistoryList from '@/pages/operation/fault-history/FaultHistoryList';
import Dashboard from '@/pages/dashboard';
import AccountManagementList from '@/pages/admin/account-management/AccountManagementList';
import AccountManagementDetail from '@/pages/admin/account-management/AccountManagementDetail';
import AccountManagementRegister from '@/pages/admin/account-management/AccountManagementRegister';
import AccountManagementModify from '@/pages/admin/account-management/AccountManagementModify';
import RemoteHistoryList from '@/pages/operation/remote-history/RemoteHistoryList';
import RemoteHistoryDetail from '@/pages/operation/remote-history/RemoteHistoryDetail';
import SignOutContainer from '@/pages/auth/sign-out';
import ChargingRateRoamingRegister from '@/pages/charging-rate/charging-rate-management/ChargingRateRoamingRegister';
import ChargingRateRoamingDetail from '@/pages/charging-rate/charging-rate-management/ChargingRateRoamingDetail';
import TroubleReportsList from '@/pages/operation/trouble-reports/TroubleReportsList';
import TroubleReportsDetail from '@/pages/operation/trouble-reports/TroubleReportsDetail';
import TroubleReportsEdit from './operation/trouble-reports/TroubleReportsEdit';
import Cookies from 'js-cookie';
import { COOKIE } from '@/lib/const';

export default function Router() {
  const location = useLocation();
  const navigate = useNavigate();
  const Exception = [
    MENU.AUTH.SIGN_IN,
    MENU.AUTH.FORGOT_PASSWORD,
    MENU.AUTH.REGISTER,
    MENU.AUTH.COMPANY_INFO,
    '/policy',
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const isExceptionPath = Exception.some(path => currentPath.startsWith(path));
    const accessToken = Cookies.get(COOKIE.ACCESS_TOKEN);

    if (isExceptionPath && accessToken) {
      if (currentPath === MENU.AUTH.COMPANY_INFO) {
        navigate(MENU.AUTH.COMPANY_INFO);
      } else {
        // 예외 경로이며 쿠키가 있으면 대시보드로 이동
        navigate(MENU.DASHBOARD);
      }
    } else if (!isExceptionPath && !accessToken) {
      // 예외 경로가 아니고 쿠키가 없으면 로그인 페이지로 이동
      navigate(MENU.AUTH.SIGN_IN, { replace: true });
    }
  }, [location.pathname]);

  // initalize
  return (
    <Routes>
      {/* auth */}
      <Route path={MENU.HOME} element={<To.Home />} />
      <Route path={MENU.AUTH.SIGN_IN} element={<To.SignIn />} />
      <Route path={`${MENU.AUTH.REGISTER}/:key`} element={<To.RegisterScreen />} />
      <Route path={MENU.AUTH.FORGOT_PASSWORD} element={<To.ForgotPasswordScreen />} />
      <Route path={`${MENU.AUTH.RESET_PASSWORD}/:key`} element={<To.ResetPasswordScreen />} />
      <Route path={MENU.AUTH.COMPANY_INFO} element={<To.RegisterCpoScreen />} />
      <Route path={MENU.AUTH.SIGN_OUT} element={<SignOutContainer />} />
      <Route path={MENU.AUTH.MYPAGE} element={<To.Mypage />} />
      {/* 인프라관리 */}
      <Route path={MENU.INFRA.STATION_MANAGEMENT} element={<StationManageList key={location.key} />} />
      <Route path={`${MENU.INFRA.STATION_MANAGEMENT}/:id`} element={<StationManageDetail />} />
      <Route path={`${MENU.INFRA.STATION_MANAGEMENT_UPDATE}/:id`} element={<StationManageRegister />} />
      <Route path={MENU.INFRA.STATION_MANAGEMENT_REGISTER} element={<StationManageRegister />} />
      <Route path={MENU.INFRA.CHARGER_MANAGEMENT} element={<ChargerManageList key={location.key} />} />
      <Route path={`${MENU.INFRA.CHARGER_MANAGEMENT}/:id`} element={<ChargerDetail />} />
      <Route path={`${MENU.INFRA.CHARGER_MANAGEMENT_UPDATE}/:id`} element={<ChargerRegister />} />
      <Route path={MENU.INFRA.CHARGER_MANAGEMENT_REGISTER} element={<ChargerRegister />} />
      <Route path={MENU.INFRA.VENDOR_MANAGEMENT} element={<VendorList />} />
      <Route path={MENU.INFRA.VENDOR_MANAGEMENT_REGISTER} element={<VendorRegister />} />
      <Route path={`${MENU.INFRA.VENDOR_MANAGEMENT_DETAIL}/:id`} element={<VendorDetail />} />
      <Route path={`${MENU.INFRA.VENDOR_MANAGEMENT_UPDATE}/:id`} element={<VendorRegister />} />
      <Route path={MENU.INFRA.MODEL_MANAGEMENT} element={<ModelList />} />
      <Route path={MENU.INFRA.MODEL_MANAGEMENT_REGISTER} element={<ModelRegister />} />
      <Route path={`${MENU.INFRA.MODEL_MANAGEMENT_DETAIL}/:id`} element={<ModelDetail />} />
      <Route path={`${MENU.INFRA.MODEL_MANAGEMENT_UPDATE}/:id`} element={<ModelRegister />} />
      <Route path={MENU.INFRA.ERRORCODE_MANAGE} element={<ErrorCodeList />} />
      {/* 충전단가관리 */}
      <Route path={MENU.RATE.CHARGING_RATE_MANAGEMENT} element={<ChargingRateList />} />
      <Route path={MENU.RATE.CHARGING_RATE_UPDATE} element={<ChargingRateRegister />} />
      <Route path={MENU.RATE.CHARGING_RATE_ROAMING_REGISTER} element={<ChargingRateRoamingRegister />} />
      <Route path={`${MENU.RATE.CHARGING_RATE_ROAMING_UPDATE}/:id`} element={<ChargingRateRoamingRegister />} />
      <Route path={`${MENU.RATE.CHARGING_RATE_ROAMING}/:id`} element={<ChargingRateRoamingDetail />} />
      {/* ----------- 충전소별관리 */}
      <Route path={MENU.CHARGING_STATION_PRICE} element={<To.ChargingStationPrice />} />
      <Route path={`${MENU.CHARGING_STATION_PRICE}/:id`} element={<To.ChargingStationPrice />} />
      {/* 매출관리 */}
      <Route path={MENU.CALCULATE_STATUS} element={<CalculateList />} /> {/* ------------------ Calculate List */}
      <Route path={`${MENU.CALCULATE_STATUS}/:year/:month`} element={<CalculateDetail />} /> {/* Calculate List */}
      <Route path={MENU.SALES.PAYMENT_STATUS} element={<PaymentStatusList />} />
      <Route path={`${MENU.SALES.PAYMENT_STATUS}/:id`} element={<PaymentStatusDetail />} />
      <Route path={MENU.SALES.CHARGING_STATUS} element={<ChargingStatusList />} /> {/* ---------------- 충전현황 */}
      <Route path={`${MENU.SALES.CHARGING_STATUS}/:id`} element={<ChargingStatusDetail />} />
      {/* 충전기운영관리 */}
      <Route path={MENU.OPERATION.CHARGEPOINT_OPERATION} element={<ChargepointListOperation />} />
      <Route path={`${MENU.OPERATION.CHARGEPOINT_OPERATION}/:id`} element={<ChargepointDetailOperation />} />
      <Route path={MENU.OPERATION.FAULT_HISTORY} element={<FaultHistoryList />} />
      <Route path={MENU.OPERATION.REMOTE_HISTORY} element={<RemoteHistoryList />} />
      <Route path={`${MENU.OPERATION.REMOTE_HISTORY}/:id`} element={<RemoteHistoryDetail />} />
      <Route path={MENU.OPERATION.TROUBLE_REPORTS} element={<TroubleReportsList />} />
      <Route path={`${MENU.OPERATION.TROUBLE_REPORTS}/:id`} element={<TroubleReportsDetail />} />
      <Route path={`${MENU.OPERATION.TROUBLE_REPORTS}/:id/edit`} element={<TroubleReportsEdit />} />
      {/* ------------------ 충전기현황 */}
      <Route path={`${MENU.DEVICE_STATUS}/:id`} element={<To.DeviceStatus />} />
      <Route path={MENU.DISORDER_HISTORY} element={<To.DisorderHistory />} /> {/* ------------------ 장애이력 */}
      <Route path={`${MENU.DISORDER_HISTORY}/:id`} element={<To.DisorderHistory />} />
      {/* 계정관리 */}
      <Route path={MENU.ADMIN.ACCOUNT_MANAGEMENT} element={<AccountManagementList />} />
      <Route path={`${MENU.ADMIN.ACCOUNT_MANAGEMENT_DETAIL}/:id`} element={<AccountManagementDetail />} />
      <Route path={MENU.ADMIN.ACCOUNT_MANAGEMENT_REGISTER} element={<AccountManagementRegister />} />
      <Route path={`${MENU.ADMIN.ACCOUNT_MANAGEMENT_UPDATE}/:id`} element={<AccountManagementModify />} />
      {/* HOME */}
      <Route path={MENU.DASHBOARD} element={<Dashboard />} />
      <Route path={MENU.MAP} element={<To.Map />} />
      {/* 이용약관,개인정보처리방침,고객센터 */}
      <Route path={MENU.POLICY_CUSTOMER} element={<To.Customer />} />
      <Route path={MENU.POLICY_PRIVACY} element={<To.Privacy />} />
      {/* error */}
      <Route path={MENU.ERROR_401} element={<To.Error401 />} />
      <Route path={MENU.ERROR_404} element={<To.Error404 />} />
      <Route path={MENU.ERROR_500} element={<To.Error500 />} />
      {/* common */}
      <Route path={MENU.TEST} element={<To.Test />} />
      <Route path={MENU.GUIDE} element={<To.Guide />} />
      {/* 가이드 */}
      <Route path={MENU.TEMPLATE} element={<To.Template />} />
      <Route path={`${MENU.TEMPLATE}/:id`} element={<To.Template />} /> {/* 등록,수정,상세 */}
      {/* 다른 페이지로 들어왔을 경우 모두 404 */}
      <Route path="*" element={<To.Error404 />} />
    </Routes>
  );
}
/*= ==================================================================[메뉴] */
export const MENU = {
  HOME: '/',
  AUTH: {
    SIGN_IN: '/sign-in',
    SIGN_OUT: '/sign-out',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    COMPANY_INFO: '/company-info',
    MYPAGE: '/mypage',
  },
  INFRA: {
    INFRA: '/infra',
    STATION_MANAGEMENT: '/infra/station-management',
    STATION_MANAGEMENT_UPDATE: '/infra/station-management/update',
    STATION_MANAGEMENT_REGISTER: '/infra/station-management/register',

    CHARGER_MANAGEMENT: '/infra/charger-management',
    CHARGER_MANAGEMENT_UPDATE: '/infra/charger-management/update',
    CHARGER_MANAGEMENT_REGISTER: '/infra/charger-management/register',

    VENDOR_MANAGEMENT: '/infra/vendor-management',
    VENDOR_MANAGEMENT_REGISTER: '/infra/vendor-management/register',
    VENDOR_MANAGEMENT_DETAIL: '/infra/vendor-management',
    VENDOR_MANAGEMENT_UPDATE: '/infra/vendor-management/update',

    MODEL_MANAGEMENT: '/infra/model-management',
    MODEL_MANAGEMENT_UPDATE: '/infra/model-management/update',
    MODEL_MANAGEMENT_DETAIL: '/infra/model-management',
    MODEL_MANAGEMENT_REGISTER: '/infra/model-management/register',

    // TODO 아래 항목 정리 필요
    DEVICE_MANAGE: '/infra/device-manage',
    ERRORCODE_MANAGE: '/infra/errorcode-manage',
    // DISORDER_CODE: '/infra/disorder-code',
    FIRMWARE_MANAGE: '/infra/firmware-manage',
  },

  OPERATION: {
    OPERATION: '/operation',
    CHARGEPOINT_OPERATION: '/operation/chargepoint',
    CHARGEPOINT_OPERATION_DETAIL: '/operation/chargepoint',
    FAULT_HISTORY: '/operation/fault',
    REMOTE_HISTORY: '/operation/remote-history',
    TROUBLE_REPORTS: '/operation/trouble-reports',
  },
  RATE: {
    RATE: '/rate',
    CHARGING_RATE_MANAGEMENT: '/rate/charging-rate-management',
    CHARGING_RATE_UPDATE: '/rate/charging-rate-management/update',
    CHARGING_RATE_ROAMING_REGISTER: '/rate/charging-rate-management/roaming/register',
    CHARGING_RATE_ROAMING_UPDATE: '/rate/charging-rate-management/roaming/update',
    CHARGING_RATE_ROAMING: '/rate/charging-rate-management/roaming',
  },
  SALES: {
    SALES: '/sales',
    CHARGING_STATUS: '/sales/charging-status',
    PAYMENT_STATUS: '/sales/payment-status',
  },
  /* ---계정관리---------*/
  ADMIN: {
    ADMIN: '/admin',
    ACCOUNT_MANAGEMENT: '/admin/account-management',
    ACCOUNT_MANAGEMENT_DETAIL: '/admin/account-management',
    ACCOUNT_MANAGEMENT_REGISTER: '/admin/account-management/register',
    ACCOUNT_MANAGEMENT_UPDATE: '/admin/account-management/update',
  },
  /* ---공통---------*/
  TEST: '/test',
  GUIDE: '/guide',
  DASHBOARD: '/dashboard',
  MAP: '/map',
  TEMPLATE: '/template',
  /* ---인프라관리---------*/
  DEVICE_MANAGE: '/infra/device-manage',
  DISORDER_CODE: '/infra/disorder-code',
  VENDOR_MANAGE: '/infra/vendor-manage',
  MODEL_MANAGE: '/infra/model-manage',
  FIRMWARE_MANAGE: '/infra/firmware-manage',
  /* ---충전단가관리---------*/
  CHARGING_UNIT_PRICE: '/price/charging-unit-price',
  CHARGING_STATION_PRICE: '/price/charging-station-price',
  PROMOTION_MANAGE: '/price/promotion-manage',
  /* ---매출관리---------*/
  ANALYSIS_REPORT: '/sales/analysis-report',
  CALCULATE_STATUS: '/sales/calculate-status',
  GOAL_MANAGE: '/sales/goal-manage',
  /* ---충전기운영관리---------*/
  DEVICE_STATUS: '/run/device-status',
  DISORDER_STATUS: '/run/disorder-status',
  DISORDER_HISTORY: '/run/disorder-history',
  /* ---이용약관---------*/
  POLICY_CUSTOMER: '/policy/customer',
  POLICY_PRIVACY: '/policy/privacy',
  /* ---AUTH---------*/
  SIGN_IN: '/auth/sign-in',
  /* ---Error---------*/
  ERROR_401: '/401',
  ERROR_404: '/404',
  ERROR_500: '/500',
  /* ---ETC---------*/
  SETTING: '/setting',
  //* --------------------------------------------------*
} as const;
