import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from 'model';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VendorRegisterPresenter from './VendorRegister.presenter';
import { actions } from '@/model/modules/vendor';
import { SCREEN_MODE } from '@/types/mode';
import { editVendor, getVendorSeq, registerVendor } from '@/lib/apis/infra';
import { IVendorDetail, ReqVendor } from '@/types/infra';
import { MENU } from '@/pages/router';
import { IResponse } from '@/lib/apis/api/type';

/**
 * @description 충전소등록관리 상세
 */
export default function VendorRegisterContainer() {
  // TODO 수정 중 새로고침시 정보가 날아가는 것을 방지하기 위해 Storage 이용 방안 고려
  const {
    auth: { cpo_seq },
    vendor: { payload, mode, view, duplicatedCheck },
  } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [popupCancelVisible, setPopupCancelVisible] = useState(false);
  const [popupFailedVisible, setPopupFailedVisible] = useState(false);
  const [requiredFieldsWarning, setRequiredFieldsWarning] = useState({
    vendor_id: false,
    name: false,
    address: false,
    phone: false,
    requireNameDuplicateCheck: false,
    requireIdDuplicateCheck: false,
  });
  const isModify = mode === SCREEN_MODE.MODIFY;

  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchKeyword = searchParams.get('search') || '';
  const locationKeyword = searchParams.get('location') || '';

  const queryParamsURL = () => {
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    queryParams.push(`page=${page}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const onInit = () => {
    if (isModify) {
      fetchVendor();
    } else {
      dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    }
  };

  const fetchVendor = async () => {
    if (id) {
      const { isSuccess, data }: IResponse<IVendorDetail> = await getVendorSeq(id);
      if (isSuccess) {
        dispatch(actions.setView(data));
        dispatch(
          actions.setPayload({
            name: data.name,
            country_code_2: data.country_code2,
            post_code: data.post_code,
            address: data.address,
            address_detail: data.address_detail,
            phone: data.phone,
            fax: data.fax,
            vendor_id: data.vendor_id,
          }),
        );
        dispatch(actions.setDuplicatedCheck({ name: true, vendor_id: true }));
      }
    }
  };

  const onCancelPopup = () => {
    setPopupCancelVisible(true);
  };

  const checkRequiredInputs = () => {
    setRequiredFieldsWarning({
      name: false,
      vendor_id: false,
      address: false,
      phone: false,
      requireNameDuplicateCheck: false,
      requireIdDuplicateCheck: false,
    });
    let check = true;
    if (payload.name === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        name: true,
      }));
      check = false;
    }
    if (payload.vendor_id === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        vendor_id: true,
      }));
      check = false;
    }
    if (payload.country_code_2 === 'KR' && payload.address === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        address: true,
      }));
      check = false;
    }
    if (payload.phone === '') {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        phone: true,
      }));
      check = false;
    }
    if (!duplicatedCheck.name) {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        requireNameDuplicateCheck: true,
      }));
      check = false;
    }
    if (!duplicatedCheck.vendor_id) {
      setRequiredFieldsWarning(prevState => ({
        ...prevState,
        requireIdDuplicateCheck: true,
      }));
      check = false;
    }
    return check;
  };

  const onSubmit = async () => {
    if (checkRequiredInputs()) {
      const { latitude, longitude, locationSeq, ...restPayload } = payload;
      const reqPayload: ReqVendor = {
        ...restPayload,
        cpo_seq,
      };

      try {
        const submitResponse = await registerVendor(reqPayload);
        if (submitResponse.isSuccess) {
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
        console.error(e);
      }
    }
  };

  const onPatchSubmit = async () => {
    if (checkRequiredInputs() && id) {
      const { latitude, longitude, locationSeq, ...restPayload } = payload;
      const reqPayload: ReqVendor = {
        ...restPayload,
        cpo_seq,
      };

      try {
        const submitResponse = await editVendor(id, reqPayload);
        if (submitResponse.isSuccess) {
          setPopupSuccessVisible(true);
        }
      } catch (e) {
        setPopupFailedVisible(true);
        console.error(e);
      }
    }
  };

  const handleCloseCancelPopup = () => {
    setPopupCancelVisible(false);
  };

  const handleDone = () => {
    dispatch(actions.initPayload());
    dispatch(actions.initView());
    setPopupSuccessVisible(false);
    if (isModify) {
      dispatch(actions.setMode(SCREEN_MODE.VIEW));
      navigate(`${MENU.INFRA.VENDOR_MANAGEMENT}/${id}?${queryParamsURL()}`);
    } else {
      dispatch(actions.setMode(SCREEN_MODE.LIST));
      navigate(MENU.INFRA.VENDOR_MANAGEMENT);
    }
  };

  const handleCloseFailedPopup = () => {
    setPopupFailedVisible(false);
  };

  useEffect(() => {
    onInit();
  }, []);

  return (
    <VendorRegisterPresenter
      onSubmit={isModify ? onPatchSubmit : onSubmit}
      onCancelPopup={onCancelPopup}
      popupSuccessVisible={popupSuccessVisible}
      popupCancelVisible={popupCancelVisible}
      handleCloseCancelPopup={handleCloseCancelPopup}
      handleDone={handleDone}
      requiredFieldsWarning={requiredFieldsWarning}
      popupFailedVisible={popupFailedVisible}
      handleCloseFailedPopup={handleCloseFailedPopup}
    />
  );
}
