/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { COOKIE, useRoutes } from 'lib';
import { authAction } from 'model/modules';
import Register from './presenter';
import { FailedReasonType } from '../sign-in/type';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { getEmail, putPassword } from '@/lib/apis/auth';
import { MENU } from '@/pages/router';
import { signInComplete } from '@/pages/common';
import { SESSION_STORAGE } from '@/lib/index';
import { IResponse } from '@/lib/apis/api/type';
import { ICountry, ILocation } from '@/types/location';
import { getLocations, getCountries } from '@/lib/apis/infra';
import { setLocations, setCountries } from '@/model/modules/location';
import Cookies from 'js-cookie';

export default function RegisterContainer() {
  const [popupVisible, setPopupVisible] = useState(false);
  const [failedReason, setFailedReason] = useState<FailedReasonType>('id');
  const dispatch = useAppDispatch();
  const { login } = useAppSelector(state => state.auth);
  const [validEmail, setValidEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { navigate } = useRoutes();
  const [isAgree, setIsAgree] = useState(false);
  const { key } = useParams();
  const onChangeHandler = (k: string, value: string) => {
    switch (k) {
      case 'pw':
        setPassword(value);
        break;
      case 'confirm':
        setconfirmPassword(value);
        break;
      default:
        setPassword(value);
        break;
    }
  };

  const fetchEmail = async (key: string) => {
    const email = await getEmail(key);
    if (!email.data) {
      // 권한없음 페이지로 이동
      navigate(MENU.ERROR_401);
    }
    if (typeof email.data === 'string') {
      const locationResponse: IResponse<ILocation[]> = await getLocations();
      if (locationResponse.isSuccess) {
        const { data } = locationResponse;
        sessionStorage.setItem(SESSION_STORAGE.LOCATION, JSON.stringify(data));
        dispatch(setLocations(data));
      }
      const countriesResponse: IResponse<ICountry[]> = await getCountries();
      if (countriesResponse.isSuccess) {
        const { data } = countriesResponse;
        sessionStorage.setItem(SESSION_STORAGE.COUNTRIES, JSON.stringify(data));
        dispatch(setCountries(data));
      }
      setValidEmail(email.data);
    }
  };

  const isValidPassword = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[~!@#$%^&*(),._+?|=]/.test(password);

    return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  };

  const onSubmit = async () => {
    if (typeof key !== 'string') return;

    if (!isAgree) {
      setMessage('이용약관 & 개인정보 처리방침에 동의해주세요.');
      setPopupVisible(true);
      return;
    }

    if (!password || !confirmPassword) {
      setMessage('비밀번호를 입력해주세요');
      setPopupVisible(true);
      return;
    }

    if (password !== confirmPassword) {
      setMessage('비밀번호가 일치하지 않습니다.');
      setPopupVisible(true);
      return;
    }

    if (password.length < 8) {
      setMessage('패스워드를 8자 이상으로 생성해주세요.');
      setPopupVisible(true);
      return;
    }

    if (!isValidPassword(password)) {
      setMessage('영문 대소문자/숫자/특수문자를 포함해주세요.');
      setPopupVisible(true);
      return;
    }
    const registerResponse = await putPassword(key, {
      password,
      password_confirm: confirmPassword,
    });
    if (!registerResponse.isSuccess) {
      setMessage((registerResponse.error.errors?.[0]?.code as string) ?? 'none');
      setPopupVisible(true);
      return { status: 'fail', message: '로그인실패', code: registerResponse.error.errors?.[0]?.code ?? 'none' };
    }
    const { data } = registerResponse;
    if (data) dispatch(authAction.setUser(data));

    // cpo가 아직 생성되지 않은 상태

    // 회원가입 완료시 session에 담음
    const sessions = await signInComplete({
      token: data?.tokens?.access_token,
      cpo_seq: '',
      business_code: '',
      cpo_code: '',
      email: data.email,
    });

    if (!sessions) {
      setMessage('세션스토리지에 저장실패입니다.');
      setPopupVisible(true);
      return { status: 'fail', message: '세션스토리지에 저장실패입니다.' };
    }

    const accessToken = Cookies.get(COOKIE.ACCESS_TOKEN);
    if (!accessToken) {
      setMessage('세션스토리지에 토큰정보가 없습니다.');
      setPopupVisible(true);
      return { status: 'fail', message: '세션스토리지에 토큰정보가 없습니다.' };
    }
    // cpo등록하는 화면으로 보냄
    navigate(MENU.AUTH.COMPANY_INFO);
  };

  useEffect(() => {
    ///#####임시_TEST
    if (key) {
      fetchEmail(key);
    }
  }, [key]);
  return (
    <Register
      setPopupVisible={setPopupVisible}
      message={message}
      navigate={navigate}
      onChangeHandler={onChangeHandler}
      popupVisible={popupVisible}
      title=""
      validEmail={validEmail}
      onSubmit={onSubmit}
      isAgree={isAgree}
      setIsAgree={setIsAgree}
    />
  );
}
