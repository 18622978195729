/**
 * @name 개인정보처리방침
 * @description
 */
import styled from 'styled-components';
import { H1 } from 'lib';
import { COLOR } from 'styles';

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="content">
        <div className="prose max-w-none">
          <h2>1. 개인정보 처리방침이란?</h2>
          <p>
            주식회사 크로커스(이하 "회사")은 회사가 운영하는 전기차 충전 서비스 플랫폼인 아셀로EV 서비스(이하 "본
            서비스"라 한다)를 이용하는 고객의 편의를 위해 이용자의 동의를 기반으로 개인정보를 수집, 이용, 제공하고
            있습니다. 회사는 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신사업법 등 관련
            법령을 준수하고 있으며, 개인정보 처리 방침을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로
            이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다. 회사는 개인정보 처리
            방침을 개정하는 경우 플랫폼 내 공지사항(또는 개별 공지)을 통하여 공지할 것입니다.
          </p>
          <h2>2. 개인정보 수집</h2>
          <p>
            <strong>서비스 제공을 위한 필요 최소한의 개인정보를 수집합니다.</strong>
          </p>
          <p>
            회원 가입 시 서비스 이용 과정에서 홈페이지 또는 충전기 등을 통해 아래와 같은 서비스 제공을 위해 필요한
            개인정보를 수집하고 있습니다.
          </p>
          <h3>회원가입 시 수집하는 개인정보</h3>
          <h4>일반 회원</h4>
          <blockquote>
            <p>*는 필수 항목을 말함</p>
          </blockquote>
          <table>
            <thead>
              <tr>
                <th>항목</th>
                <th>목적</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>이름, CI, DI*</td>
                <td>회원 식별</td>
              </tr>
              <tr>
                <td>이메일*</td>
                <td>회원 식별 및 "본 서비스" 이용에 필요한 고객 응대 서비스 및 정보 제공</td>
              </tr>
              <tr>
                <td>휴대폰 번호*</td>
                <td>회원 식별 및 "본 서비스" 이용에 필요한 고객 응대 서비스 및 정보 제공</td>
              </tr>
              <tr>
                <td>생년월일*</td>
                <td>더 나은 회원 서비스 등</td>
              </tr>
              <tr>
                <td>주소</td>
                <td>회원 식별 및 더 나은 회원 서비스 등</td>
              </tr>
            </tbody>
          </table>
          <h3>멤버십 및 충전서비스 이용 시 수집하는 개인정보</h3>
          <blockquote>
            <p>*는 필수 항목을 말함</p>
          </blockquote>
          <table>
            <thead>
              <tr>
                <th>항목</th>
                <th>목적</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>신용카드*</td>
                <td>이용요금 결제를 위해 일부 마스킹 처리된 신용카드번호, 암호화된 빌키(BILLKEY) 및 발급일시 수집</td>
              </tr>
              <tr>
                <td>배송지 주소*</td>
                <td>멤버십 카드 배송</td>
              </tr>
              <tr>
                <td>충전기 이용 기록*</td>
                <td>
                  데이터는 충전기 정상 운영 확인, 더 나은 회원 서비스, 충전량과 과금 관련, 사업자 자산관리 및 보호 등
                </td>
              </tr>
              <tr>
                <td>결제 관련 정보*</td>
                <td>결제 기록 저장</td>
              </tr>
            </tbody>
          </table>
          <h3>비회원 또는 로밍 회원</h3>
          <p>
            회사의 회원 이외의 고객이 본 서비스를 이용할 경우 충전 편의 정보 및 거래 내역 확인 등을 위해 인증코드로
            검증한 "휴대폰 번호와 부가서비스 이용 시 선택적으로 차량번호"를 선택적으로 수집하고 있습니다.
          </p>
          <h3>그 외</h3>
          <p>
            <strong>환불</strong>
          </p>
          <p>
            일반적으로 결제된 카드로 환불이 진행되나 해당 수단으로 환불이 불가한 경우 선택적으로 계좌은행, 계좌번호,
            예금주명, 이메일을 수집합니다.
          </p>
          <p>
            <strong>현금영수증 발행 시</strong>
          </p>
          <p>휴대폰 번호, 현금영수증 카드번호를 수집합니다.</p>
          <p>
            <strong>14세 미만인 아동의 개인정보는 일절 수집하지 않습니다.</strong>
          </p>
          <p>본 서비스는 식별이 불가능한 경우를 제외하여 14세 미만 아동에게 서비스를 제공하지 않습니다.</p>
          <p>
            <strong>서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.</strong>
          </p>
          <p>
            PC 웹,모바일 웹/앱 이용 과정에서 단말기 정보(OS,화면 사이즈,디바이스아이디,기종,단말기 모델명), IP주소,
            방문일시, 부정 이용기록, 서비스 이용 기록 등의 정보가 자동으로 생성되어 수집될 수 있습니다.
          </p>
          <h2>3. 개인정보 파기</h2>
          <p>
            <strong>개인정보는 수집 및 이용목적이 달성되면 바로 파기하며, 절차 및 방법은 아래와 같습니다.</strong>
          </p>
          <p>
            회사는 회원님이 회원으로서 제공되는 서비스를 받는 동안 회원님의 개인정보를 보유 및 이용하게 됩니다. 단, 수집
            및 이용 목적의 달성 또는 회원 탈퇴 등 파기 사유가 발생한 개인정보는 개인정보의 형태를 고려하여 파기 방법을
            정합니다. 전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면
            등의 경우 분쇄하거나 소각하여 파기합니다. 다만, 타 법령 및 내부 방침에 따라 일정 기간 보관 후 파기하는
            정보는 아래와 같습니다.
          </p>
          <ol>
            <li>
              <p>
                타 법령의 규정에 의하여 회원님의 개인정보를 더 보존할 필요할 필요성이 있는 경우에는 법령에서 규정한
                보존기간 또는 다음 각호의 기간 거래 명세와 최소한의 기본정보를 보유할 수 있습니다.
              </p>
              <ul>
                <li>
                  대금 결제 및 재화 등의 공급에 관한 기록
                  <ul>
                    <li>보존 항목: 이메일, 본인인증 정보(CI), 거래(제공) 일시, 재화의 내용 및 종류, 재화 가격</li>
                    <li>보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>보존 기간 : 회원 탈퇴 또는 서비스 종료 시부터 5년</li>
                  </ul>
                </li>
                <li>
                  계약 또는 청약 철회 등에 관한 기록
                  <ul>
                    <li>보존 항목 : 이메일, 본인인증 정보(CI), 탈회 일시</li>
                    <li>보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>보존 기간 : 계약 또는 청약 철회 시부터 5년</li>
                  </ul>
                </li>
                <li>
                  회원의 불만 또는 분쟁 처리에 관한 기록
                  <ul>
                    <li>보존 항목 : 이름, 이메일, 휴대폰 번호, 분쟁 내용</li>
                    <li>보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>보존 기간 : 회원 탈퇴 또는 서비스 종료 시부터 3년</li>
                  </ul>
                </li>
                <li>
                  기타
                  <ul>
                    <li>
                      표시/광고에 관한 기록
                      <ul>
                        <li>보존 항목 : 이메일, 본인인증 정보(CI), 표시/광고 일시, 표시/광고 내용</li>
                        <li>보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                        <li>보존 기간 : 회원 탈퇴 또는 서비스 종료 시부터 6개월</li>
                      </ul>
                    </li>
                    <li>회원의 동의를 독한 경우 : 당해 동의를 득한 기간</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>
                회사는 회원에게 수집된 개인정보를 회원탈퇴 및 파기요청시까지
                <strong>준영구</strong>로 보유 및 이용 후 폐기합니다.
              </p>
            </li>
            <li>
              <p>아래 정보는 탈퇴일로부터 최대 1년간 보관 후 파기합니다.</p>
              <ul>
                <li>서비스 이용 기록</li>
                <li>접속 로그</li>
                <li>쿠키</li>
                <li>접속 IP 정보</li>
              </ul>
            </li>
            <li>
              <p>아래 정보는 탈퇴일로부터 최대 3년간 보관 후 파기합니다.</p>
              <ul>
                <li>부정·불량 이용기록</li>
              </ul>
            </li>
          </ol>
          <h2>4. 개인위치정보의 처리</h2>
          <p>회사는 아직 회원님의 개인위치정보를 수집 및 이용하지 않고 있습니다.</p>
          <h2>5. 개인정보 제 3자 제공</h2>
          <p>
            회사는 회원의 동의 없이 회원의 개인정보를 외부 업체에 위탁하지 않습니다. 회사가 수집한 개인정보를 아래와
            같이 제3자에게 제공 또는 위탁합니다.
          </p>
          <table>
            <thead>
              <tr>
                <th>위탁업체</th>
                <th>제공 목적</th>
                <th>제공 항목</th>
                <th>보유 기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>㈜파킹스루</td>
                <td>멤버십카드 발급 및 발송</td>
                <td>사업자명, 연락처, 배송 주소</td>
                <td>서비스 해지 시 또는 위탁계약 종료 시까지</td>
              </tr>
              <tr>
                <td>한국정보통신㈜</td>
                <td>신용카드 결제</td>
                <td>카드사명, 카드번호, 생년월일, 사업자번호, 이메일 주소, 휴대전화 번호 등</td>
                <td>서비스 해지 시 또는 위탁계약 종료 시까지</td>
              </tr>
              <tr>
                <td>세종텔레콤㈜</td>
                <td>SMS/MMS/LMS 발송, 카카오 알림톡/친구톡 발송</td>
                <td>이름, 멤버십 카드 정보, 회사 정보 등</td>
                <td>서비스 해지 시 또는 위탁계약 종료 시까지</td>
              </tr>
              <tr>
                <td>환경부, 한국환경공단, 한국자동차환경협회</td>
                <td>충전기 이용 내역 전달</td>
                <td>멤버십 카드 번호, 이용 충전기 식별자, 충전량, 충전금액 등</td>
                <td>서비스 해지 시 또는 위탁계약 종료 시까지</td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>단 법령에서 따로 정하는 경우에는 해당 기간까지 보유합니다.</li>
          </ul>
          <h2>6. 기타</h2>
          <p>
            <strong>크로커스은 여러분의 권리를 보호합니다.</strong>
          </p>
          <p>
            회원은 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 수집·이용, 제공에 대한 동의 철회 또는 가입
            해지를 요청할 수 있습니다.
          </p>
          <p>회원의 개인정보 조회, 수정을 위해서는 마이페이지를 이용하시기 바랍니다.</p>
          <p>또는 고객센터를 통해 서면, 전화 또는 이메일로 요청하시면 지체 없이 조치하겠습니다.</p>
          <p>
            <strong>크로커스은 이용자의 소중한 개인정보 보호를 위해 다음의 노력을 하고 있습니다.</strong>
          </p>
          <p>회원님의 개인정보를 가장 소중한 가치로 여기고 개인정보를 처리하면서 다음과 같은 노력을 다하고 있습니다.</p>
          <ul>
            <li>
              <p>
                회원님의 개인정보를 암호화하고 있습니다. 회원님의 개인정보를 암호화된 통신 구간을 이용하여 전송하고,
                비밀번호 등 중요정보는 암호화하여 보관하고 있습니다.
              </p>
            </li>
            <li>
              <p>
                해킹이나 컴퓨터 바이러스로부터 보호하기 위하여 노력하고 있습니다. 해킹이나 컴퓨터 바이러스 등에 의해
                이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고
                있습니다. 해커 등의 침입을 탐지하고 차단할 수 있는 시스템을 설치하여 감시하고 있으며, 백신 프로그램을
                설치하여 시스템이 최신 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다. 또한 새로운 해킹/보안
                기술에 대해 지속해서 서비스에 적용하려고 노력하고 있습니다.
              </p>
            </li>
            <li>
              <p>
                회원님의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 임직원이 업무를 함에 있어 회원님의
                개인정보를 보호하기 위해 지켜야할 사내 규정을 마련하여 준수하고 있습니다.
              </p>
            </li>
            <li>
              <p>
                그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우
                즉각 회원님께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.
              </p>
            </li>
          </ul>
          <p>
            <strong>개인정보보호와 관련해서 궁금하신 사항은?</strong>
          </p>
          <p>
            서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 개인정보 보호 책임자 및
            담당 부서로 연락해 주시기 바랍니다. 회사는 여러분의 목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수
            있도록 최선을 다하겠습니다.
          </p>
          <p>
            <strong>개인정보 보호 책임자 및 담당 부서</strong>
          </p>
          <ul>
            <li>책임자 : 김태경</li>
            <li>담당부서 : 경영관리본부</li>
            <li>전화번호 : 02-6951-24938</li>
            <li>
              이메일 :<a href="mailto:cs@crocusenergy.com">cs@crocusenergy.com</a>
            </li>
          </ul>
          <p>
            또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수
            있습니다.
          </p>
          <ul>
            <li>
              개인정보 침해 신고센터 : (국번없이)118
              <a href="https://privacy.kisa.or.kr">https://privacy.kisa.or.kr</a>
            </li>
            <li>
              대검찰청 사이버수사과(<a href="mailto:cid@spo.go.kr">cid@spo.go.kr</a>) : (국번없이)1301
              <a href="https://privacy.kisa.or.kr">https://privacy.kisa.or.kr</a>
            </li>
            <li>
              경찰청 사이버안전국 : (국번없이)182
              <a href="https://ecrm.cyber.go.kr">https://ecrm.cyber.go.kr</a>
            </li>
          </ul>
          <p>
            <strong>개인정보처리방침이 변경되는 경우</strong>
          </p>
          <p>
            법률이나 서비스의 변경 사항을 반영하기 위한 목적 등으로 개인정보 처리 방침을 수정할 수 있습니다. 개인정보
            처리 방침이 변경되는 경우 회사는 변경 사항을 게시하며, 변경된 개인정보 처리 방침은 게시한 날로부터 7일
            후부터 효력이 발생합니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한
            변경이 발생할 때는 최소 30일 전에 미리 알려드리겠습니다.
          </p>
          <ul>
            <li>공고 일자: 2024년 04월 03일</li>
            <li>시행 일자: 2024년 04월 17일</li>
          </ul>
        </div>
      </div>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  .content {
    font-size: 14px;
    background-color: #fff;
    padding: 20px;
  }
  .tabs {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #999;
  }
  .tabs a {
    width: 50%;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 10px 0;
    border-bottom: 2px solid transparent;
  }
  .tabs a.active {
    background-color: #0056b3;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    color: #555;
  }
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
  }
  ul {
    list-style: none;
  }
  ul li {
    margin-bottom: 10px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  table,
  th,
  td {
    border: 1px solid #ddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
  }
  th {
    background-color: #f2f2f2;
  }
  blockquote {
    margin: 10px 0;
    padding: 10px;
    background-color: #f9f9f9;
    border-left: 5px solid #ccc;
  }
  a {
    color: #007bff;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;
