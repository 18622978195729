import React, { useEffect } from 'react';
import { icon_location_arrow, icon_location_home } from 'assets/images';
import styled, { css } from 'styled-components';
import { COLOR } from 'styles';
import { Image, ImageButton, Row, useRoutes } from 'lib';
import { MENU } from '@/pages/router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import typography from '@/styles/typography';
import { useAppSelector } from '@/model/index';
import { ISitemap } from '@/types/menu';

/**
 * @name Location
 */
export default function Location() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { sitemap } = useAppSelector(state => state.gnb);
  const { navigate } = useRoutes();

  const renderPaths = () => {
    const pathSplit = pathname.split('/');
    if (pathSplit.length > 2) {
      const firstDepthItem = sitemap.find((element: ISitemap) => element.path.split('/')[1] === pathSplit[1]);
      const secondDepthItem = sitemap.find((element: ISitemap) => {
        const pathSegments = element.path.split('/');
        const pathnameSegments = pathname.split('/');
        return pathSegments[1] === pathnameSegments[1] && pathSegments[2] === pathnameSegments[2];
      });
      if (firstDepthItem && secondDepthItem) {
        return (
          <>
            <NavigatePathButton
              onClick={() => {
                navigate(MENU.DASHBOARD);
              }}>
              {t('pages.home')}
            </NavigatePathButton>
            <Image src={icon_location_arrow} style={{ width: 16, height: 16 }} />
            <NavigatePathButton>{firstDepthItem?.name}</NavigatePathButton>
            <Image src={icon_location_arrow} style={{ width: 16, height: 16 }} />
            <NavigatePathButton
              isCurrentLocation
              onClick={() => {
                navigate(secondDepthItem?.path);
              }}>
              {secondDepthItem?.name}
            </NavigatePathButton>
          </>
        );
      }
    }

    const firstDepthItem = sitemap.find((element: ISitemap) => element.path.split('/')[1] === pathSplit[1]);
    return (
      <>
        <NavigatePathButton
          onClick={() => {
            navigate(MENU.DASHBOARD);
          }}>
          {t('pages.home')}
        </NavigatePathButton>
        <Image src={icon_location_arrow} style={{ width: 16, height: 16 }} />
        <NavigatePathButton
          isCurrentLocation
          onClick={() => {
            navigate(firstDepthItem.path);
          }}>
          {firstDepthItem?.name}
        </NavigatePathButton>
      </>
    );
  };

  useEffect(() => {
    renderPaths();
  });

  return (
    <Content>
      {/* {debug(location)} */}
      {/* 로고 */}
      <Row>
        <ImageButton
          style={{ width: 24, height: 24 }}
          source={icon_location_home}
          onClick={() => {
            navigate(MENU.DASHBOARD);
          }}
        />
        <NavigateNames>
          <NavigatePathWrapper>{renderPaths()}</NavigatePathWrapper>
        </NavigateNames>
      </Row>
    </Content>
  );
}

const NavigateNames = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;

const Content = styled.header`
  position: relative;
  display: flex;
`;

const NavigatePathWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NavigatePathButton = styled.button<{ isCurrentLocation?: boolean }>`
  ${typography.caption1};
  margin-left: 2px;
  margin-right: 2px;
  color: ${COLOR.GRAY3};
  &:hover {
    color: ${COLOR.BLUE3};
  }
  ${({ isCurrentLocation }) =>
    isCurrentLocation &&
    css`
      color: ${COLOR.BLUE3};
    `}
`;
