import React, { useEffect } from 'react';
import { App, BackButton } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'model';
import { Button } from 'lib';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';
import { PopupContainer, PopupCreate, SubmitCancel, TextFiledGroup, TextInput } from '@/components/form';
import { displayPrice } from '@/lib/util/displayData';
import Badge from '@/components/atoms/Badge';
import { SCREEN_MODE } from '@/types/mode';
import { actions } from '@/model/modules/price';

export default function ChargingRateRegisterPresenter({
  onSubmit,
  handleBackToList,
  onCancelPopup,
  popupCancelVisible,
  popupSuccessVisible,
  popupFailedVisible,
  handleCloseFailedPopup,
  handleCloseCancelPopup,
  handleDone,
}: {
  onSubmit: () => void;
  handleBackToList: () => void;
  onCancelPopup: () => void;
  popupCancelVisible: boolean;
  popupSuccessVisible: boolean;
  popupFailedVisible: boolean;
  handleCloseFailedPopup: () => void;
  handleCloseCancelPopup: () => void;
  handleDone: () => void;
}) {
  const { t } = useTranslation('common');
  const { view, mode, payload } = useAppSelector(state => state.price);
  const isModify = mode === SCREEN_MODE.MODIFY;
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   console.log(view);
  // }, []);

  const getNumber = (val: string) => {
    return val.replace(/[^0-9]/g, '');
  };

  return (
    <App>
      <HeaderWrapper>
        <BackButton label={t('forms.price.setFixedRate')} onClick={onCancelPopup} />
      </HeaderWrapper>
      <ContentWrapper>
        <Content>
          <TextFiledGroup
            label={t('forms.price.setFixedRate')}
            required
            component={
              <>
                <Row>
                  <TableHeader>{t('forms.price.customerType')}</TableHeader>
                  <TableHeader>{t('forms.price.applyMoment')}</TableHeader>
                  <TableHeader>
                    {t('forms.price.rapid')}
                    {t('common.unit.brackets.priceWonBykWh')}
                  </TableHeader>
                  {/* <TableHeader>
                    {t('forms.price.standard')}
                    {t('common.unit.brackets.priceWonBykWh')}
                  </TableHeader> */}
                </Row>
                <Row>
                  <Cell>
                    <Badge mode="TariffUser" label={t('forms.price.member.user')} />
                  </Cell>
                  <Cell>00:00 ~ 24:00</Cell>
                  <Cell>
                    <TextInput
                      style={{ maxWidth: 170, minWidth: 'unset' }}
                      defaultValue={displayPrice(view?.members?.fast_amount)}
                      name="fast_amount"
                      onChange={val =>
                        dispatch(
                          actions.setPayload({
                            ...payload,
                            members: { ...payload.members, fast_amount: getNumber(val), type: 1 },
                          }),
                        )
                      }
                      value={displayPrice(payload?.members?.fast_amount)}
                      maxLength={5}
                    />
                  </Cell>
                  {/* <Cell>
                    <TextInput
                      style={{ maxWidth: 170, minWidth: 'unset' }}
                      defaultValue={displayPrice(view?.members?.slow_amount)}
                      name="slow_amount"
                      onChange={val =>
                        dispatch(
                          actions.setPayload({
                            ...payload,
                            members: { ...payload.members, slow_amount: getNumber(val), type: 1 },
                          }),
                        )
                      }
                      value={displayPrice(payload?.members?.slow_amount)}
                      maxLength={5}
                    />
                  </Cell> */}
                </Row>
                <Row>
                  <Cell>
                    <Badge mode="TariffNone" label={t('forms.price.member.none')} />
                  </Cell>
                  <Cell>00:00 ~ 24:00</Cell>
                  <Cell>
                    <TextInput
                      style={{ maxWidth: 170, minWidth: 'unset' }}
                      defaultValue={displayPrice(view?.nonMembers?.fast_amount)}
                      name="non_members_fast_amount"
                      onChange={val =>
                        dispatch(
                          actions.setPayload({
                            ...payload,
                            nonMembers: { ...payload.nonMembers, fast_amount: getNumber(val), type: 0 },
                          }),
                        )
                      }
                      value={displayPrice(payload?.nonMembers?.fast_amount)}
                      maxLength={5}
                    />
                  </Cell>
                  {/* <Cell>
                    <TextInput
                      style={{ maxWidth: 170, minWidth: 'unset' }}
                      defaultValue={displayPrice(view?.nonMembers?.slow_amount)}
                      name="non_members_slow_amount"
                      onChange={val =>
                        dispatch(
                          actions.setPayload({
                            ...payload,
                            nonMembers: { ...payload.nonMembers, slow_amount: getNumber(val), type: 0 },
                          }),
                        )
                      }
                      value={displayPrice(payload?.nonMembers?.slow_amount)}
                      maxLength={5}
                    />
                  </Cell> */}
                </Row>
              </>
            }
          />
          <SubmitButtonsWrapper>
            <ButtonWrapper>
              <Button
                full
                label={t('common.buttons.cancel')}
                color={COLOR.GRAY2}
                borderColor={COLOR.GRAY2}
                bg="transparent"
                onClick={onCancelPopup}
              />
              <Button
                full
                label={isModify ? t('common.buttons.updateAction') : t('common.buttons.registerAction')}
                onClick={onSubmit}
              />
            </ButtonWrapper>
          </SubmitButtonsWrapper>
        </Content>
      </ContentWrapper>
      <PopupContainer visible={popupSuccessVisible} onClose={handleDone}>
        <PopupCreate
          title={isModify ? t('common.toast.successToModifyFixedPrice') : t('common.toast.successToRegisterFixedPrice')}
          message={
            isModify ? t('common.toast.successToModifyFixedPrice2') : t('common.toast.successToRegisterFixedPrice2')
          }
          onConfirm={handleDone}
        />
      </PopupContainer>
      <PopupContainer visible={popupCancelVisible}>
        <PopupCreate
          warning
          title={isModify ? t('common.toast.cancelUpdateFixedPrice') : t('common.toast.cancelRegisterFixedPrice')}
          message={isModify ? t('common.toast.askCancel') : t('common.toast.askCancelWithoutSave')}
          footer={
            <SubmitCancel
              confirmLabel={
                isModify ? t('common.buttons.cancelUpdateAction') : t('common.buttons.cancelRegisterAction')
              }
              onCancel={handleCloseCancelPopup}
              onConfirm={handleDone}
            />
          }
        />
      </PopupContainer>
      <PopupContainer visible={popupFailedVisible} onClose={handleCloseFailedPopup}>
        <PopupCreate
          warning
          title={
            isModify ? t('common.toast.failedUpdateFixedPriceTitle') : t('common.toast.failedRegisterFixedPriceTitle')
          }
          message={
            isModify
              ? t('common.toast.failedUpdateFixedPriceMessage')
              : t('common.toast.failedRegisterFixedPriceMessage')
          }
          onConfirm={handleCloseFailedPopup}
        />
      </PopupContainer>
    </App>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 680px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableHeader = styled.div`
  flex: 1;
  padding: 14px 8px;
  background-color: ${COLOR.BLUE8};
  border-bottom: 1px solid ${COLOR.BLUE_GRAY9};
  text-align: center;
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;
export const Cell = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  flex: 1;
  text-align: center;
  ${typography.body4};
  color: ${COLOR.BLACK3};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmitButtonsWrapper = styled.div`
  width: 400px;
  margin: 32px auto 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
`;
