/**
 * @name 고객센터
 * @description
 */
import { useState } from 'react';
import { App } from 'components';
import styled from 'styled-components';
// contents
import { Header } from './common';
import { Service, Privacy } from './contents';
import { Tab } from 'lib';
import { useLocation } from 'react-router-dom';

export default function Presenter() {
  // const
  const { state } = useLocation();
  const tabMenu = [{ label: '크로커스 EV 이용약관' }, { label: '개인정보 처리방침' }];
  const [selected, setSelected] = useState(state?.active);
  //*--------------------------------------------------*
  // setContents
  const setContents = () => {
    switch (selected) {
      default:
      case 0:
        return <Service />;
      case 1:
        return <Privacy />;
    }
  };
  //*--------------------------------------------------*

  return state.mode === 'register' ? (
    <div>
      {/* 컨텐츠 */}
      <Header />
      <Content>
        {/* 헤더 */}
        <header className="auto">
          <Tab
            selected={selected}
            style={{ marginBottom: 32 }}
            info={tabMenu}
            onChange={({ index }) => {
              setSelected(index);
            }}
          />
        </header>
        <main>{setContents()}</main>
      </Content>
    </div>
  ) : (
    <App>
      {/* 컨텐츠 */}
      <Header />
      <Content>
        {/* 헤더 */}
        <header className="auto">
          <Tab
            selected={selected}
            style={{ marginBottom: 32 }}
            info={tabMenu}
            onChange={({ index }) => {
              setSelected(index);
            }}
          />
        </header>
        <main>{setContents()}</main>
      </Content>
    </App>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  header {
    width: 674px;
    margin: 24px auto;
  }
  main {
    width: 674px;
    margin: 24px auto;
  }
`;
