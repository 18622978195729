/**
 * @name Container
 * @description
 */
// contents
import Presenter from './presenter';

export default function Container() {
  // const
  //*--------------------------------------------------*
  return <Presenter />;
}
