/**
 * @name Header
 * @description
 */
import { BackButton } from 'components';
import styled from 'styled-components';

export default function Contents() {
  // hooks
  const setContents = () => {
    return <BackButton label={'이용약관 / 개인정보 처리방침'} />;
  };
  return <Content>{setContents()}</Content>;
}

//*--------------------------------------------------*
const Content = styled.div`
  padding: 16px 24px;
`;
