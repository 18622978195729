import AuthScreenLayout from '@/components/layout/auth';
import { Button, Events, H2, Image, Text } from 'lib';
import styled from 'styled-components';
import {
  FormCheckbox,
  PopupContainer,
  PopupCreate,
  SubmitButton,
  TextFiledGroup,
  TextFiledLabel,
  TextInput,
  TextInputPassword,
  TextSearchAddress,
} from 'components/form';
import { auth_logo, auth_screen_sign_up } from '@/assets/images';
import { COLOR } from '@/styles/index';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch, useAppSelector } from '@/model/index';
import { IAddress } from '@/types/infra';
import { SCREEN_MODE } from '@/types/mode';
import { useEffect, useState } from 'react';
import { ReqPutCompany } from '@/lib/apis/auth/type';

export default function RegisterCpoPresenter({
  email,
  setInfo,
  info,
  onSubmit,
  popupVisible,
  setPopupVisible,
  message,
  requiredFieldsWarning,
}: {
  email: string;
  setInfo: (value: any) => void;
  info: ReqPutCompany;
  onSubmit: () => void;
  popupVisible: boolean;
  setPopupVisible: (flag: boolean) => void;
  message: string;
  requiredFieldsWarning: {
    name: boolean;
    address: boolean;
    businessCode: boolean;
    companyName?: boolean;
    businessNumber: boolean;
  };
}) {
  const {
    auth: { cpo_seq, user },
    station,
    station: { mode, payload, view },
  } = useAppSelector((state: RootState) => state);
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const closePopup = () => {
    setPopupVisible(false);
  };

  const [validationResult, setValidationResult] = useState<{
    name: boolean | 'none';
    address: boolean | 'none';
    businessCode: boolean | 'none';
    companyName: boolean | 'none';
    businessNumber: boolean | 'none';
  }>({
    name: 'none',
    address: 'none',
    businessCode: 'none',
    companyName: 'none',
    businessNumber: 'none',
  });

  const onValidCheckResult = (key: string, value: boolean) => {
    setValidationResult(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <AuthScreenLayout visual={<Image src={auth_screen_sign_up} />}>
      <HeaderWrapper>
        <header>
          <Image className="logo" src={auth_logo} />
          <HeaderTitle>
            <H2 color={COLOR.GRAY1}>ACELO EV</H2>
            <Text color={COLOR.GRAY2} className="body4">
              {t('pages.auth.aceloEvDescription')} 🚀
            </Text>
          </HeaderTitle>
        </header>
      </HeaderWrapper>
      <Content>
        <div className="wrap">
          <TextFiledLabel label={t('pages.auth.email')} />
          <TextInput
            style={{ marginBottom: 16, color: COLOR.GRAY2 }}
            placeholder={t('pages.auth.emailPlaceholder')}
            disabled={true}
            defaultValue={email}
          />
          <TextFiledGroup
            required
            label={t('pages.auth.userName')}
            validCheckResult={validationResult.name}
            displayWarning2={requiredFieldsWarning.name}
            warningText2={`${t('pages.auth.userName')}은(는) 필수값입니다.`}
            component={
              <TextInput
                style={{ marginBottom: 16, color: COLOR.GRAY2 }}
                placeholder={t('pages.auth.userNamePlaceholder')}
                onValue={str => {
                  setInfo({ ...info, admin: { ...info.admin, name: str } });
                }}
              />
            }
          />
          <TextFiledLabel label={t('pages.auth.userNumber')} />
          <TextInput
            type="number"
            maxLength={11}
            style={{ marginBottom: 16, color: COLOR.GRAY2 }}
            placeholder={t('forms.charger.numberPlaceholder')}
            onValue={str => {
              setInfo({ ...info, admin: { ...info.admin, phone: str } });
            }}
          />
          {user?.role?.code !== 'ADMIN' ? (
            <>
              <TextFiledLabel label={t('pages.auth.companyNumber')} />
              <TextInput
                type="number"
                maxLength={11}
                style={{ marginBottom: 16, color: COLOR.GRAY2 }}
                placeholder={t('forms.charger.numberPlaceholder')}
                onValue={str => {
                  setInfo({ ...info, company: { ...info.company, phone: str } });
                }}
              />
              <TextFiledLabel label={t('pages.auth.companyCustomerCenter')} />
              <TextInput
                type="number"
                maxLength={11}
                style={{ marginBottom: 16, color: COLOR.GRAY2 }}
                placeholder={t('forms.charger.numberPlaceholder')}
                onValue={str => {
                  setInfo({ ...info, company: { ...info.company, cs_phone: str } });
                }}
              />
              <TextFiledGroup
                required
                label={t('pages.auth.companyAddress')}
                validCheckResult={validationResult.address}
                displayWarning2={requiredFieldsWarning.address}
                warningText2={`${t('pages.auth.companyAddress')}은(는) 필수값입니다.`}
                component={
                  <TextSearchAddress
                    value={view?.address}
                    style={{ marginBottom: 10 }}
                    onChange={(data: IAddress) => {
                      setInfo({ ...info, company: { ...info.company, address: data.address } });
                    }}
                  />
                }
              />
              <TextFiledGroup
                required
                label={t('pages.auth.companyBusinessCode')}
                validCheckResult={validationResult.businessCode}
                displayWarning2={requiredFieldsWarning.businessCode}
                warningText2={`${t('pages.auth.companyBusinessCode')}은(는) 필수값입니다.`}
                component={
                  <TextInput
                    maxLength={3}
                    style={{ marginBottom: 16, color: COLOR.GRAY2 }}
                    placeholder={t('pages.auth.companyBusinessCodePlaceHolder')}
                    onValue={str => {
                      setInfo({ ...info, company: { ...info.company, code: str } });
                    }}
                  />
                }
              />
              <TextFiledGroup
                required
                label={t('pages.auth.companyName')}
                validCheckResult={validationResult.companyName}
                displayWarning2={requiredFieldsWarning.companyName}
                warningText2={`${t('pages.auth.companyName')}은(는) 필수값입니다.`}
                component={
                  <TextInput
                    style={{ marginBottom: 16, color: COLOR.GRAY2 }}
                    placeholder={t('pages.auth.companyNamePlaceholder')}
                    onValue={str => {
                      setInfo({ ...info, company: { ...info.company, ['name']: str } });
                      // setInfo({ ...info, admin: { ...info.admin, name: str } });
                      // setInfo({ ...info, company: { ...info.company, name: str } });
                    }}
                  />
                }
              />
              <TextFiledGroup
                required
                label={t('pages.auth.businessNumber')}
                validCheckResult={validationResult.businessNumber}
                displayWarning2={requiredFieldsWarning.businessNumber}
                warningText2={`${t('pages.auth.businessNumber')}은(는) 필수값입니다.`}
                component={
                  <TextInput
                    type="number"
                    inputMode="numeric"
                    style={{ marginBottom: 16, color: COLOR.GRAY2 }}
                    maxLength={10}
                    placeholder={t('pages.auth.businessNumberPlaceHolder')}
                    onValue={str => {
                      setInfo({ ...info, company: { ...info.company, corporation_number: str } });
                    }}
                  />
                }
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div>
          <Button full style={{ height: 40, marginBottom: 16 }} onClick={() => onSubmit()}>
            <span className="button3">{t('common.buttons.saveAndStart')}</span>
          </Button>
        </div>
      </Content>
      <PopupContainer visible={popupVisible} onClose={closePopup}>
        <PopupCreate
          warning
          message={message}
          footer={<SubmitButton warning label={t('common.buttons.submit')} onConfirm={closePopup} />}
        />
      </PopupContainer>
    </AuthScreenLayout>
  );
}

const Content = styled.div`
  width: 320px;
  /* agree */
  .agree {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .extra {
      color: ${COLOR.BLUE4};
    }
  }
  /* footer */
  footer {
    p {
      display: flex;
      margin-bottom: 16px;
      justify-content: center;
      color: ${COLOR.GRAY1};
    }
    button {
      padding-left: 10px;
      color: ${COLOR.BLUE4};
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 320px;
  margin-bottom: 20px;
  flex-direction: column;

  .logo {
    display: block;
    width: 56px !important;
    height: 48px !important;
    margin: 0 auto 20px;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
