import { useState } from 'react';
import { Button, addhyphen, validationStationName, validationName } from 'lib';
import { RootState, useAppDispatch, useAppSelector } from 'model';
import { TextFiledGroup, TextInput, TextSearchAddress } from 'components/form';
import { useTranslation } from 'react-i18next';
import { getCheckVendorIdDuplicated, getCheckVendorNameDuplicated } from '@/lib/apis/infra';
import { IAddress } from '@/types/infra';
import { actions } from '@/model/modules/vendor';
import { SCREEN_MODE } from '@/types/mode';
import CountriesSearch from '@/components/form/dropdown-countries';
import { DropdownOptions } from '@/components/molecules/Dropdown/Dropdown.types';
import Dropdown from '@/components/molecules/Dropdown';
import { NumbersWrapper, NameInputContainer } from './VendorRegisterForm.style';
import { ICountry } from '@/types/location';

// 충전소 등록/수정 기본정보 & 운영시간 입력
export default function VendorRegisterForm({
  requiredFieldsWarning,
}: {
  requiredFieldsWarning?: {
    vendor_id: boolean;
    name: boolean;
    requireNameDuplicateCheck: boolean;
    requireIdDuplicateCheck: boolean;
    address: boolean;
    phone: boolean;
  };
}) {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const {
    auth: { cpo_seq },
    location: { countries },
    vendor: { mode, payload, view },
  } = useAppSelector((state: RootState) => state);
  const isModify = mode === SCREEN_MODE.MODIFY || mode === SCREEN_MODE.REGISTER;
  const isView = mode === SCREEN_MODE.VIEW;

  const [nameInputDesc, setNameInputDesc] = useState(t('forms.model.manufacturerNameDesc'));
  const [idInputDesc, setIdInputDesc] = useState(t('forms.model.manufacturerIDDesc'));
  const [forms, setForms] = useState({
    name: '',
    vendor_id: '',
  });
  const [validationResult, setValidationResult] = useState<{
    name: boolean | 'none';
    vendor_id: boolean | 'none';
    address: boolean | 'none';
    phone: boolean | 'none';
  }>({
    name: 'none',
    vendor_id: 'none',
    address: 'none',
    phone: 'none',
  });
  const [countryNumberList, setCountryNumberList] = useState<DropdownOptions[]>([]);

  //addhyphens 함수
  function addhyphens(value: string): string {
    // 숫자만 남기고 하이픈을 추가할 위치를 설정합니다.
    value = value.replace(/[^0-9]/g, '');

    if (value.length < 4) {
      return value;
    } else if (value.length < 7) {
      return `${value.slice(0, 3)}-${value.slice(3)}`;
    } else if (value.length < 11) {
      return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
    } else {
      return `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}`;
    }
  }
  const onNameChangeHandler = (value: string) => {
    setForms(prevState => ({
      ...prevState,
      name: value,
    }));
    dispatch(
      actions.setPayload({
        ...payload,
        name: value,
      }),
    );
    dispatch(actions.setDuplicatedCheck({ name: false }));
  };

  const onIdChangeHandler = (value: string) => {
    setForms(prevState => ({
      ...prevState,
      vendor_id: value,
    }));
    dispatch(
      actions.setPayload({
        ...payload,
        vendor_id: value,
      }),
    );
    dispatch(actions.setDuplicatedCheck({ vendor_id: false }));
  };

  const onAddressDetailChangeHandler = (value: string) => {
    dispatch(
      actions.setPayload({
        ...payload,
        address_detail: value,
      }),
    );
  };

  const onValidCheckResult = (key: string, value: boolean) => {
    setValidationResult(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const isNameDuplicated = async () => {
    if (!validationStationName(forms.name)) {
      onValidCheckResult('name', false);
      setNameInputDesc(t('forms.model.manufacturerNameDesc'));
      return;
    }
    const checkResult = await getCheckVendorNameDuplicated({ name: forms.name, cpo_seq });
    if (checkResult.isSuccess) {
      if (!checkResult.data.is_name_duplicated) {
        onValidCheckResult('name', true);
        setNameInputDesc(t('forms.model.manufacturerNameAvailableDesc'));
        dispatch(
          actions.setPayload({
            ...payload,
            name: forms.name,
          }),
        );
        dispatch(actions.setDuplicatedCheck({ name: true }));
      } else {
        onValidCheckResult('name', false);
        setNameInputDesc(t('forms.model.manufacturerNameUnavailableDesc'));
      }
    }
  };

  const isIdDuplicated = async () => {
    if (!validationName(forms.vendor_id)) {
      onValidCheckResult('vendor_id', false);
      setIdInputDesc(t('forms.model.manufacturerIDDesc'));
      return;
    }
    const checkResult = await getCheckVendorIdDuplicated({ vendor_id: forms.vendor_id, cpo_seq });
    if (checkResult.isSuccess) {
      if (!checkResult.data.is_name_duplicated) {
        onValidCheckResult('vendor_id', true);
        setIdInputDesc(t('forms.model.manufacturerIDAvailableDesc'));
        dispatch(
          actions.setPayload({
            ...payload,
            vendor_id: forms.vendor_id,
          }),
        );
        dispatch(actions.setDuplicatedCheck({ vendor_id: true }));
      } else {
        onValidCheckResult('vendor_id', false);
        setIdInputDesc(t('forms.model.manufacturerIDUnavailableDesc'));
      }
    }
  };

  const findCountryNumberCode = (): DropdownOptions | undefined => {
    const element = view.country_code2 ? countries.find((el: ICountry) => el.code2 === view.country_code2) : null;
    return element
      ? ({
          id: element.seq,
          name: `${element.name_origin} / ${element.tel_code.replace(/\+/g, '')}`,
          value: element.tel_code.replace(/\+/g, ''),
        } as DropdownOptions)
      : undefined;
  };

  const generateCountryNumberDropdownOptions = () => {
    return countries.map((item: ICountry) => ({
      id: item.seq,
      name: `${item.name_origin} / ${item.tel_code.replace(/\+/g, '')}`,
      value: item.tel_code.replace(/\+/g, ''),
    }));
  };

  const renderTextSearchAddress = () => {
    const shouldRender = isView
      ? view.country_code2 === 'KR'
      : payload.country_code_2 === 'KR' || payload.country_code_2 === '';

    if (!shouldRender) return null;

    return (
      <TextSearchAddress
        disabled={!isModify}
        value={view?.address}
        style={{ marginBottom: 10 }}
        onChange={(data: IAddress) => {
          dispatch(
            actions.setPayload({
              ...payload,
              locationSeq: data.locationSeq,
              latitude: data?.latitude,
              longitude: data?.longitude,
              post_code: data?.post_code,
              address: data?.address,
            }),
          );
        }}
      />
    );
  };

  const renderTextInput = () => {
    const isKorea = isView
      ? view.country_code2 === 'KR'
      : payload.country_code_2 === 'KR' || payload.country_code_2 === '';

    const placeholderText = isKorea
      ? t('forms.charger.installationAddressDesc')
      : t('forms.charger.installationGlobalAddressDesc');

    const maxLength = isKorea ? 50 : 200;

    return (
      <TextInput
        disabled={!isModify}
        name="address_detail"
        defaultValue={view?.address_detail}
        className="gap"
        onChange={onAddressDetailChangeHandler}
        placeholder={placeholderText}
        maxLength={maxLength}
      />
    );
  };

  return (
    <main className="inc_form" style={{ width: 400 }}>
      <TextFiledGroup
        required
        label={t('forms.model.manufacturerName')}
        desc={nameInputDesc}
        validCheckResult={validationResult.name}
        displayWarning={requiredFieldsWarning?.name}
        warningText={t('forms.model.manufacturerNamePlaceholder')}
        displayWarning2={requiredFieldsWarning?.requireNameDuplicateCheck}
        warningText2={t('forms.charger.nameDuplicateCheckWarning')}
        component={
          <NameInputContainer>
            <TextInput
              disabled={!isModify}
              style={{ maxWidth: 270 }}
              placeholder={t('forms.model.manufacturerNamePlaceholder')}
              defaultValue={view.name}
              name="name"
              onChange={onNameChangeHandler}
              // validFunc={validationStationName}
              maxLength={25}
              // parentValidResult={result => onValidCheckResult('name', result)}
            />
            <Button
              label={t('common.buttons.duplicateCheck')}
              disabled={!isModify || forms.name === '' || view.name === payload.name}
              onClick={isNameDuplicated}
              style={{ flex: 1 }}
            />
          </NameInputContainer>
        }
      />
      <TextFiledGroup
        required
        label={t('forms.model.manufacturerID')}
        desc={idInputDesc}
        validCheckResult={validationResult.vendor_id}
        displayWarning={requiredFieldsWarning?.vendor_id}
        warningText={t('forms.model.manufacturerIDPlaceholder')}
        displayWarning2={requiredFieldsWarning?.requireIdDuplicateCheck}
        warningText2={t('forms.charger.nameDuplicateCheckWarning')}
        component={
          <NameInputContainer>
            <TextInput
              disabled={!isModify}
              style={{ maxWidth: 270 }}
              placeholder={t('forms.model.manufacturerIDPlaceholder')}
              defaultValue={view.vendor_id}
              name="vendor_id"
              onChange={onIdChangeHandler}
              // validFunc={validationStationName}
              maxLength={25}
              // parentValidResult={result => onValidCheckResult('name', result)}
            />
            <Button
              label={t('common.buttons.duplicateCheck')}
              disabled={!isModify || forms.vendor_id === '' || view.vendor_id === payload.vendor_id}
              onClick={isIdDuplicated}
              style={{ flex: 1 }}
            />
          </NameInputContainer>
        }
      />
      <TextFiledGroup
        label={t('forms.model.countryOfManufacturer')}
        component={
          <CountriesSearch
            popupStyle={{ height: 200 }}
            value={view.country_code2}
            disabled={!isModify}
            onChange={(item: ICountry | string) => {
              if (typeof item !== 'string') {
                if (item?.code2 !== 'KR') {
                  dispatch(
                    actions.setPayload({
                      ...payload,
                      locationSeq: '',
                      latitude: '',
                      longitude: '',
                      post_code: '',
                      address: '',
                    }),
                  );
                }
                dispatch(actions.setPayload({ country_code_2: item?.code2 }));
              }
            }}
          />
        }
      />
      <TextFiledGroup
        label={t('forms.charger.installationAddress')}
        required
        validCheckResult={validationResult.address}
        displayWarning={requiredFieldsWarning?.address}
        warningText={`${t('forms.charger.installationAddress')}를 입력해주세요.`}
        // displayWarning={requiredFieldsWarning?.address}
        // warningText={t('forms.charger.installationAddressEmptyWarning')}
        component={
          <>
            {renderTextSearchAddress()}
            {renderTextInput()}
          </>
        }
      />
      <TextFiledGroup
        required
        label={t('forms.charger.number')}
        validCheckResult={validationResult.phone}
        displayWarning={requiredFieldsWarning?.phone}
        warningText={`${t('forms.charger.number')}를 입력해주세요.`}
        component={
          <NumbersWrapper>
            <Dropdown
              disabled={!isModify}
              optionContainerWidth={250}
              wrapperStyle={{ minWidth: 134 }}
              selectBoxName="국가번호전화"
              defaultValue={findCountryNumberCode()}
              dropdownOptions={generateCountryNumberDropdownOptions()}
              placeholder={t('common.buttons.countryNumber')}
              selectField="country-number-phone"
              onChangeSelectOption={({ value }) => actions.setPayload({ ...payload, phoneCountryNumber: value })}
            />
            <TextInput
              type="number"
              disabled={!isModify}
              name="phone"
              onChange={value => {
                // GS인증 전화번호 하이픈
                // GS인증 전화번호 하이픈
                const phoneNumber = value.replace(/[^0-9]/g, ''); // 숫자만 남김
                const countryCode = payload.phoneCountryNumber || ''; // 국가 코드 가져오기

                // 국가 코드를 제외한 나머지 부분에 하이픈 추가
                // const hyphenatedNumber = addhyphens(phoneNumber.replace(countryCode, ''));

                // 최종 전화번호 (국가 코드 + 하이픈이 추가된 번호)
                const finalPhoneNumber = `${countryCode}${phoneNumber}`;
                // const hyphen = addhyphen(value);
                dispatch(
                  actions.setPayload({
                    ...payload,
                    phone: finalPhoneNumber,
                  }),
                );
              }}
              defaultValue={view?.phone}
              placeholder={t('forms.charger.numberPlaceholder')}
              maxLength={11}
            />
          </NumbersWrapper>
        }
      />
      <TextFiledGroup
        label={t('forms.model.fax')}
        component={
          <NumbersWrapper>
            <Dropdown
              disabled={!isModify}
              optionContainerWidth={250}
              wrapperStyle={{ minWidth: 134 }}
              selectBoxName="국가번호팩스"
              defaultValue={findCountryNumberCode()}
              dropdownOptions={generateCountryNumberDropdownOptions()}
              placeholder={t('common.buttons.countryNumber')}
              selectField="country-number-fax"
              onChangeSelectOption={({ value }) => actions.setPayload({ ...payload, faxCountryNumber: value })}
            />
            <TextInput
              type="number"
              disabled={!isModify}
              name="fax"
              onChange={value => {
                // const hyphen = addhyphen(value);
                dispatch(
                  actions.setPayload({
                    ...payload,
                    fax: value,
                  }),
                );
              }}
              defaultValue={view?.fax}
              placeholder={t('forms.charger.numberPlaceholder')}
              maxLength={11}
            />
          </NumbersWrapper>
        }
      />
    </main>
  );
}
