import React, { useEffect, useState } from 'react';
import { ImageButton, useContext, useRoutes, uuid } from 'lib';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { crocusFullLogo, crocusSymbol } from 'assets/images';
import { MENU } from '@/pages/router';
import { useAppDispatch, useAppSelector } from 'model';
import { useLocation } from 'react-router-dom';
import MainMenu from './menu-main';
import SubMenu from './menu-sub';
import FooterMenu from './menu-footer';
import { gnbAction } from '@/model/modules';
import { loadMenuFromStorage } from '@/lib/util/storage';
import { IMenu, IMenuItems } from '@/types/menu';

type Props = {
  mode?: string;
};

/**
 * @name GNB
 */
export default function Gnb({ mode }: Props) {
  const { pathname } = useLocation();
  const { footer, menu, navi } = useAppSelector(state => state.gnb);
  const dispatch = useAppDispatch();
  const { navigate } = useRoutes();
  const isPage = mode === 'page';
  const defaultWidth: number = isPage ? 260 : 71; /** 71 , 260 */
  const [isOpen, setIsOpen] = useState(isPage);

  // 서브메뉴생성
  const createSubMenu = (item: IMenu, depth1: number): any => {
    const { menu_items } = item;
    const isWork = !!(navi.depth1 === depth1 && isOpen);
    if (!menu_items) return null;
    if (!isWork) return null;
    // element
    const element = menu_items.map((item: IMenuItems, depth2: number) => {
      if (item?.name === '펌웨어 등록') return null; // 펌웨어 등록 제외 (TODO 추후 개발 범위에 포함될 수 있다.)
      if (item?.name === '충전소별 단가 관리') return null; // 충전소별 단가관리 제외 (TODO 추후 개발 범위에 포함될 수 있다.)
      /// 메뉴별권한권한관리때문에, 예외처리하면서 메뉴생성되지 않게 필요함.
      // if (item.name === '장애 이력') return null;
      if (item?.name === '원격제어 이력') return null;
      if (item?.name === '장애코드 등록') return null;
      if (item?.name === '정산 현황') return null;
      return (
        <ListSubItem key={uuid()}>
          <SubMenu item={item} depth1={depth1} depth2={depth2} />
        </ListSubItem>
      );
    });
    return <SubWrapper>{element}</SubWrapper>;
  };

  useEffect(() => {
    const menuData = loadMenuFromStorage();
    if (menuData) {
      dispatch(gnbAction.setMenu(menuData));
    }
  }, []);

  return (
    <Wrapper
      style={{ width: defaultWidth }}
      transition={{ duration: 0.1, ease: 'easeInOut' }}
      whileHover={{ width: 260 }}
      onHoverStart={e => {
        setIsOpen(true);
      }}
      onHoverEnd={e => {
        !isPage && setIsOpen(false);
      }}>
      <Content>
        {/* 로고 */}
        <ListLogo>
          <ImageButton
            onClick={() => {
              navigate(MENU.DASHBOARD);
            }}
            source={isOpen ? crocusFullLogo : crocusSymbol}
            style={{ width: isOpen ? 120 : 28, height: 24, padding: '10px 8px', boxSizing: 'content-box' }}
          />
        </ListLogo>
        <ListItem key={uuid()} $isOpen={isOpen}>
          <MainMenu item={{ name: '대시보드', seq: 1, menu_items: [], classnames: '' }} depth1={10} isOpen={isOpen} />
        </ListItem>
        {menu.map((item: IMenu, depth1: number) => {
          return (
            <ListItem key={uuid()} $isOpen={isOpen}>
              {/* 메인메뉴생성 */}
              <MainMenu item={item} depth1={depth1} isOpen={isOpen} />
              {/* 서브메뉴생성 */}
              {createSubMenu(item, depth1)}
            </ListItem>
          );
        })}
        {/* 푸터메뉴 */}
      </Content>
      <FooterWrapper>
        {footer.map((item: any, depth1: number) => {
          return (
            <ListFooterItem key={uuid()}>
              <FooterMenu depth1={depth1} item={item} isOpen={isOpen} />
            </ListFooterItem>
          );
        })}
      </FooterWrapper>
    </Wrapper>
  );
}

const Content = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SubWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;
const FooterWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: 24px;
`;

const ListLogo = styled.li`
  margin-bottom: 22px;
`;
const ListItem = styled.li<{ $isOpen: boolean }>`
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      min-width: 232px;
    `}
`;
const ListSubItem = styled.li``;

const ListFooterItem = styled.li`
  display: flex;
`;

const Wrapper = styled(motion.div)`
  padding: 10px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  will-change: width;
`;
