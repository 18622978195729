import React, { useEffect, useState } from 'react';
import { Button, Image, addhyphen, validationStationName } from 'lib';
import { RootState, useAppDispatch, useAppSelector } from 'model';
import { DropDownStationsTypes, FormFileUploader, TextFiledGroup, TextInput, TextSearchAddress } from 'components/form';
import { useTranslation } from 'react-i18next';
import { getCheckNameDuplicated } from '@/lib/apis/infra';
import { IAddress, StationType } from '@/types/infra';
import { actions } from '@/model/modules/station';
import { SCREEN_MODE } from '@/types/mode';
import {
  ImageIsNotExist,
  ImageIsNotExistDescription,
  ImageIsNotExistTitle,
  NameInputContainer,
} from '@/components/organisms/Forms/StationRegisterForm/StationRegisterForm.style';

// 충전소 등록/수정 기본정보 & 운영시간 입력
export default function StationRegisterForm({
  requiredFieldsWarning,
}: {
  requiredFieldsWarning?: {
    name: boolean;
    requireDuplicateCheck: boolean;
    stationTypeGroup: boolean;
    address: boolean;
  };
}) {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const {
    auth: { cpo_seq },
    station,
    station: { mode, payload, view },
  } = useAppSelector((state: RootState) => state);
  const isModify = mode === SCREEN_MODE.MODIFY || mode === SCREEN_MODE.REGISTER;

  const [nameInputDesc, setNameInputDesc] = useState(t('forms.charger.nameDesc'));
  const [forms, setForms] = useState({
    name: '',
  });
  const [validationResult, setValidationResult] = useState<{ name: boolean | 'none' }>({
    name: 'none',
  });

  const onNameChangeHandler = (value: string) => {
    setForms(prevState => ({
      ...prevState,
      name: value,
    }));
    dispatch(
      actions.setStationPayload({
        ...station.payload,
        name: value,
      }),
    );
    dispatch(actions.setDuplicatedCheck(false));
  };

  const onAddressDetailChangeHandler = (value: string) => {
    dispatch(
      actions.setStationPayload({
        ...station.payload,
        address_detail: value,
      }),
    );
  };

  const onValidCheckResult = (key: string, value: boolean) => {
    setValidationResult(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const isNameDuplicated = async () => {
    if (!validationStationName(forms.name)) {
      onValidCheckResult('name', false);
      setNameInputDesc(t('forms.charger.nameDesc'));
      return;
    }
    const checkResult = await getCheckNameDuplicated({ name: forms.name, cpo_seq });
    if (checkResult.isSuccess) {
      if (!checkResult.data.is_name_duplicated) {
        onValidCheckResult('name', true);
        setNameInputDesc(t('forms.charger.nameAvailableDesc'));
        dispatch(
          actions.setStationPayload({
            ...station.payload,
            name: forms.name,
          }),
        );
        dispatch(actions.setDuplicatedCheck(true));
      } else {
        onValidCheckResult('name', false);
        setNameInputDesc(t('forms.charger.nameUnavailableDesc'));
      }
    }
  };

  return (
    <main className="inc_form" style={{ width: 400 }}>
      <TextFiledGroup
        required
        label={t('forms.charger.name')}
        desc={nameInputDesc}
        validCheckResult={validationResult.name}
        displayWarning={requiredFieldsWarning?.name}
        warningText={t('forms.charger.nameEmptyWarning')}
        displayWarning2={requiredFieldsWarning?.requireDuplicateCheck}
        warningText2={t('forms.charger.nameDuplicateCheckWarning')}
        component={
          <NameInputContainer>
            <TextInput
              disabled={!isModify}
              style={{ maxWidth: 270 }}
              placeholder={t('forms.charger.namePlaceholder')}
              defaultValue={view.name}
              name="name"
              onChange={onNameChangeHandler}
              // validFunc={validationStationName}
              maxLength={25}
              // parentValidResult={result => onValidCheckResult('name', result)}
            />
            <Button
              label={t('common.buttons.duplicateCheck')}
              disabled={!isModify || forms.name === '' || view.name === payload.name}
              onClick={isNameDuplicated}
              style={{ flex: 1 }}
            />
          </NameInputContainer>
        }
      />
      <TextFiledGroup
        label={t('forms.charger.stationImage')}
        disabled={!isModify}
        component={
          <>
            {!isModify &&
              (view.pic_large ? (
                <Image
                  src={`${view.pic_large}?timestamp=${new Date().getTime()}`}
                  style={{ width: '100%', height: 'auto', borderRadius: 16 }}
                />
              ) : (
                <ImageIsNotExist>
                  <ImageIsNotExistTitle>{t('common.fileUploader.noImage')}</ImageIsNotExistTitle>
                  <ImageIsNotExistDescription>
                    {t('common.fileUploader.uploadImageDescription')}
                  </ImageIsNotExistDescription>
                </ImageIsNotExist>
              ))}
            {isModify && (
              <FormFileUploader
                types={['JPG', 'PNG', 'GIF', 'JPEG']}
                title={t('common.fileUploader.image')}
                message={t('common.fileUploader.imageDescription')}
                onChange={(file: File | null) => {
                  /**
                   * jjh
                   * NOTE : FormFileUploader를 다른 곳에서도 사용하고 있어서 건드리지 못했습니다.
                   * '' = 기존 이미지 | File = 새로운 이미지 | null = 삭제
                   * 현재 서버에서 위와 같이 동작하게 되어 있습니다
                   */
                  dispatch(actions.setStationPayload({ ...station.payload, image: file ?? '' }));
                }}
              />
            )}
          </>
        }
      />
      <TextFiledGroup
        label={t('forms.charger.installationType')}
        required
        displayWarning={requiredFieldsWarning?.stationTypeGroup}
        warningText={t('forms.charger.installationTypeEmptyWarning')}
        component={
          <>
            {!isModify && (
              <DropDownStationsTypes
                editMode={isModify}
                station_type_group={view.station_type?.type_group}
                station_type_detail={view.station_type?.type_detail}
              />
            )}
            {isModify && (
              <DropDownStationsTypes
                editMode={isModify}
                station_type_group={view.station_type?.type_group ?? payload?.station_type_group}
                station_type_detail={view.station_type?.type_detail ?? payload?.station_type_detail}
                onChange={(item: StationType) => {
                  dispatch(
                    actions.setStationPayload({
                      ...station.payload,
                      station_type_group: item?.type_group,
                      station_type_detail: item?.type_detail,
                    }),
                  );
                }}
              />
            )}
          </>
        }
      />
      <TextFiledGroup
        label={t('forms.charger.installationAddress')}
        required
        displayWarning={requiredFieldsWarning?.address}
        warningText={t('forms.charger.installationAddressEmptyWarning')}
        component={
          <>
            <TextSearchAddress
              disabled={!isModify}
              value={payload.address}
              style={{ marginBottom: 10 }}
              onChange={(data: IAddress) => {
                dispatch(
                  actions.setStationPayload({
                    ...payload,
                    locationSeq: data.locationSeq,
                    latitude: data?.latitude,
                    longitude: data?.longitude,
                    post_code: data?.post_code,
                    address: data?.address,
                  }),
                );
              }}
            />
            <TextInput
              disabled={!isModify}
              name="address_detail"
              defaultValue={view?.address_detail}
              className="gap"
              onChange={onAddressDetailChangeHandler}
              placeholder={t('forms.charger.installationAddressDesc')}
              maxLength={50}
            />
          </>
        }
      />
      <TextFiledGroup
        label={t('forms.charger.number')}
        component={
          <TextInput
            type="number"
            disabled={!isModify}
            name="phone"
            onChange={value => {
              // const hyphen = addhyphen(value);
              dispatch(
                actions.setStationPayload({
                  ...station.payload,
                  phone: value,
                }),
              );
            }}
            defaultValue={view?.phone}
            placeholder={t('forms.charger.numberPlaceholder')}
            maxLength={13}
          />
        }
      />
    </main>
  );
}
