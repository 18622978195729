import React, { FormEventHandler, useEffect, useState } from 'react';
import { Button, validationName, Div } from 'lib';
import { RootState, useAppDispatch, useAppSelector } from 'model';
import { DropDownConnectors, SelectButton, TextFiledGroup, TextInput } from 'components/form';
import { useTranslation } from 'react-i18next';
import { getModelCpsName, getVendors } from '@/lib/apis/infra';
import { IVendor } from '@/types/infra';
import { actions } from '@/model/modules/model';
import { SCREEN_MODE } from '@/types/mode';
import { DropdownOptions } from '@/components/molecules/Dropdown/Dropdown.types';
import Dropdown from '@/components/molecules/Dropdown';
import { ConnectorTypesWrapper, NameInputContainer } from './ModelRegisterForm.style';
import { IResponse } from '@/lib/apis/api/type';
import { setPayload } from '@/model/modules/auth';
import { COLOR } from 'styles';
import styled from 'styled-components';

// 충전소 등록/수정 기본정보 & 운영시간 입력
export default function ModelRegisterForm({
  requiredFieldsWarning,
}: {
  requiredFieldsWarning?: {
    vendorSeq: boolean;
    name: boolean;
    requireNameDuplicateCheck: boolean;
    maxPowerKw: boolean;
    connectorCount: boolean;
    connectorOutput: boolean;
    maxVoltage: boolean;
    maxAmpare: boolean;
    efficiencies: boolean;
    ipxLevel: boolean;
  };
}) {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const {
    auth: { cpo_seq },
    model: { mode, payload, view },
  } = useAppSelector((state: RootState) => state);
  const isModify = mode === SCREEN_MODE.MODIFY || mode === SCREEN_MODE.REGISTER;
  const [register, setRegister] = useState<boolean>(false);
  const [nameInputDesc, setNameInputDesc] = useState(t('forms.model.modelDesc'));
  const [forms, setForms] = useState({
    name: '',
  });
  const [validationResult, setValidationResult] = useState<{ name: boolean | 'none' }>({
    name: 'none',
  });

  const [vendorOptionList, setVendorOptionList] = useState<DropdownOptions[]>([]);
  const [vendorList, setVendorList] = useState<IVendor[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<{ id: string; name: string }>({
    name: view.vendor.name,
    id: view.vendor.seq,
  });

  const fetchVendorList = async () => {
    const vendorsResponse: IResponse<IVendor[]> = await getVendors();
    if (vendorsResponse.isSuccess) {
      setVendorList(vendorsResponse.data);
      const temp = vendorsResponse.data.map((item: IVendor) => {
        return {
          name: item.name,
          id: `${item.seq}`,
        };
      });
      setVendorOptionList(temp);
    }
  };

  const handleSelectVendor = (name: string, seq: string) => {
    const vendor = vendorList.find(vendor => `${vendor.seq}` === `${seq}`);
    if (vendor) {
      setSelectedVendor({ id: vendor?.seq, name: vendor?.name });
    }
    dispatch(actions.setPayload({ vendorSeq: seq }));
  };

  const onNameChangeHandler = (value: string) => {
    setForms(prevState => ({
      ...prevState,
      name: value,
    }));
    dispatch(
      actions.setPayload({
        ...payload,
        name: value,
      }),
    );
    dispatch(actions.setDuplicatedCheck({ name: false }));
  };

  const onValidCheckResult = (key: string, value: boolean) => {
    setValidationResult(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const isNameDuplicated = async () => {
    if (!validationName(forms.name)) {
      onValidCheckResult('name', false);
      setNameInputDesc(t('forms.model.modelNameDesc'));
      return;
    }
    const checkResult = await getModelCpsName(forms.name);
    if (checkResult.isSuccess) {
      if (!checkResult.data.is_name_duplicated) {
        onValidCheckResult('name', true);
        setNameInputDesc(t('forms.model.modelNameAvailableDesc'));
        dispatch(
          actions.setPayload({
            ...payload,
            name: forms.name,
          }),
        );
        dispatch(actions.setDuplicatedCheck({ name: true }));
      } else {
        onValidCheckResult('name', false);
        setNameInputDesc(t('forms.model.modelNameUnavailableDesc'));
      }
    }
  };
  const checkConcurrency = (bool: boolean) => {
    if (bool === true) return 1;
    if (bool === false) return 2;
    return -1;
  };
  const checkChargeType = (type: string) => {
    if (type === 'FAST') return 1;
    if (type === 'SLOW') return 2;
    return -1;
  };
  const checkConnectorCount = (num: number | string) => {
    if (num === 1 || num === '1') return 1;
    if (num === 2 || num === '2') return 2;
    return -1;
  };

  const onChangeHandler = (name: string, value: string | number) => {
    // if (Number(value) > 100) return;
    //####
    // if (name === 'efficiencies' && Number(value) >= 100) {
    //   dispatch(actions.setPayload({ [name]: '100' }));
    //   return;
    // }
    dispatch(actions.setPayload({ [name]: value }));
  };
  useEffect(() => {
    fetchVendorList();
  }, []);

  useEffect(() => {
    setSelectedVendor({ id: view.vendor.seq, name: view.vendor.name });
    // payload.connectorCount로 화면을 따로 그림
    if (view?.connector_count && view?.model_connectors) {
      dispatch(
        actions.setPayload({
          ...payload,
          connectorCount: view?.connector_count,
          connectors: view?.model_connectors,
        }),
      );
    }
    // 수정시 기존에 등록된 커넥터가 없는경우 set을 해줍니다.
    if (view?.connector_count && view?.model_connectors?.length === 0) {
      const ary = Array.from({ length: view?.connector_count }, () => {
        return { types: null, max_kw: null, seq: null };
      });
      dispatch(
        actions.setPayload({
          ...payload,
          connectorCount: view?.connector_count,
          connectors: ary,
        }),
      );
    }
  }, [view]);

  // 등록시에만 수정가능
  useEffect(() => {
    const currentPath = location.pathname;
    currentPath.includes('register') ? setRegister(true) : setRegister(false);
  }, [location.pathname]);

  return (
    <main className="inc_form" style={{ width: 400 }}>
      <TextFiledGroup
        required
        displayWarning={requiredFieldsWarning?.vendorSeq}
        warningText={t('forms.model.selectVendor')}
        label={t('forms.model.manufacturer')}
        component={
          vendorOptionList.length > 0 && (
            <Dropdown
              disabled={!isModify}
              defaultValue={selectedVendor}
              optionContainerWidth={400}
              selectBoxName="제조사선택"
              dropdownOptions={vendorOptionList}
              placeholder={t('common.buttons.selectVendor')}
              selectField="vendor"
              onChangeSelectOption={({ name, value }) => handleSelectVendor(name ?? '', value)}
            />
          )
        }
      />
      <TextFiledGroup
        required
        label={t('forms.model.model')}
        desc={nameInputDesc}
        validCheckResult={validationResult.name}
        displayWarning={requiredFieldsWarning?.name}
        warningText={t('forms.model.modelNamePlaceholder')}
        displayWarning2={requiredFieldsWarning?.requireNameDuplicateCheck}
        warningText2={t('forms.charger.nameDuplicateCheckWarning')}
        component={
          <NameInputContainer>
            <TextInput
              disabled={!isModify}
              style={{ maxWidth: 270 }}
              placeholder={t('forms.model.modelNamePlaceholder')}
              defaultValue={view.name}
              name="name"
              onChange={onNameChangeHandler}
              // validFunc={validationStationName}
              maxLength={25}
              // parentValidResult={result => onValidCheckResult('name', result)}
            />
            <Button
              label={t('common.buttons.duplicateCheck')}
              disabled={!isModify || forms.name === '' || view.name === payload.name}
              onClick={isNameDuplicated}
              style={{ flex: 1 }}
            />
          </NameInputContainer>
        }
      />
      <TextFiledGroup
        label={t('forms.model.chargerType')}
        required
        component={
          <Content $disabled={!isModify}>
            <button
              disabled={!isModify}
              type="button"
              className={'active'}
              onChange={() => {
                dispatch(actions.setPayload({ chargerType: 'FAST' }));
              }}>
              <span className="flex button4">{'급속'}</span>
            </button>
          </Content>

          // <SelectButton
          //   disabled={!isModify}
          //   value={checkChargeType(view.charger_type)}
          //   leftLabel={t('common.rapid')}
          //   rightLabel={t('common.standard')}
          //   onChange={val => {
          //     dispatch(actions.setPayload({ chargerType: val === 1 ? 'FAST' : 'SLOW' }));
          //   }}
          // />
        }
      />
      <TextFiledGroup
        label={t('forms.model.simultaneousChargingCapability')}
        required
        component={
          <SelectButton
            disabled={!isModify}
            value={checkConcurrency(view.is_concurrency)}
            leftLabel={t('common.available')}
            rightLabel={t('common.unavailable')}
            onChange={val => {
              dispatch(actions.setPayload({ isConcurrency: val === 1 }));
            }}
          />
        }
      />
      <TextFiledGroup
        required
        label={t('forms.model.maximumOutput')}
        desc={t('forms.numberOnly')}
        displayWarning={requiredFieldsWarning?.maxPowerKw}
        warningText={t('forms.model.inputMaxKw')}
        component={
          <TextInput
            disabled={!isModify}
            style={{ marginBottom: 4 }}
            maxLength={5}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            placeholder=""
            defaultValue={view.max_power_kw}
            name="max_power_kw"
            onChange={(value: string | number) => onChangeHandler('maxPowerKw', value)}
          />
        }
      />
      <TextFiledGroup
        required
        label={t('forms.model.numberOfConnectors')}
        displayWarning={requiredFieldsWarning?.connectorCount}
        warningText={t('forms.model.selectConnectorCount')}
        component={
          <SelectButton
            disabled={!register}
            value={checkConnectorCount(view.connector_count)}
            leftLabel={`1${t('common.unit.numberOf')}`}
            rightLabel={`2${t('common.unit.numberOf')}`}
            onChange={val => {
              const ary = Array.from({ length: val }, () => {
                return { types: null, max_kw: null, seq: null };
              });
              // 수정시 커넥터를 선택한 만큼 리스트를 만들어줌
              if (view?.model_connectors) {
                dispatch(
                  actions.setPayload({
                    connectorCount: Number(val),
                    connectors: ary.map((conn: any, idx: number) => {
                      return {
                        types: view?.model_connectors[idx]?.types ?? null,
                        max_kw: view?.model_connectors[idx]?.max_kw ?? null,
                        seq: view?.model_connectors[idx]?.seq ?? null,
                      };
                    }),
                  }),
                );
              } else {
                dispatch(actions.setPayload({ connectorCount: Number(val), connectors: ary }));
              }
            }}
          />
        }
      />
      <TextFiledGroup
        required
        label={t('forms.model.connectorTypeMaximumOutput')}
        displayWarning={requiredFieldsWarning?.connectorOutput}
        warningText={t('forms.model.inputConnectorInfo')}
        component={
          <ConnectorTypesWrapper>
            <Div className="gap">
              <DropDownConnectors
                disabled={!register || payload?.connectorCount === '' || view?.connector_count}
                defaultValue={view.model_connectors?.[0]?.types}
                onChange={(val: any) => {
                  dispatch(
                    actions.setPayload({
                      connectors: payload?.connectors?.map((conn: any, idx: number) => {
                        if (idx === 0) {
                          return {
                            ...conn,
                            types: val?.value,
                          };
                        }
                        return conn;
                      }),
                    }),
                  );
                }}
              />
              <TextInput
                defaultValue={view.model_connectors?.[0]?.max_kw}
                disabled={!register || payload?.connectorCount === '' || view?.connector_count}
                style={{ width: '50%', marginLeft: 8 }}
                maxLength={5}
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder={`${t('common.maximumOutput')}${t('common.unit.brackets.kW')}`}
                onChange={value => {
                  dispatch(
                    actions.setPayload({
                      connectors: payload?.connectors?.map((conn: any, idx: number) => {
                        if (idx === 0) {
                          return {
                            ...conn,
                            max_kw: value,
                          };
                        }
                        return conn;
                      }),
                    }),
                  );
                }}
              />
            </Div>
            <Div style={{ marginBottom: 4 }}>
              <DropDownConnectors
                disabled={!register || Number(payload?.connectorCount) !== 2}
                defaultValue={view.model_connectors?.[1]?.types}
                onChange={(val: any) => {
                  dispatch(
                    actions.setPayload({
                      connectors: payload?.connectors?.map((conn: any, idx: number) => {
                        if (idx === 1) {
                          return {
                            ...conn,
                            types: val?.value,
                          };
                        }
                        return conn;
                      }),
                    }),
                  );
                }}
              />
              <TextInput
                defaultValue={view.model_connectors?.[1]?.max_kw}
                disabled={!register || Number(payload?.connectorCount) !== 2}
                style={{ width: '50%', marginLeft: 8 }}
                placeholder={`${t('common.maximumOutput')}${t('common.unit.brackets.kW')}`}
                name="max_kw"
                maxLength={5}
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                onChange={value => {
                  dispatch(
                    actions.setPayload({
                      connectors: payload?.connectors?.map((conn: any, idx: number) => {
                        if (idx === 1) {
                          return {
                            ...conn,
                            max_kw: value,
                          };
                        }
                        return conn;
                      }),
                    }),
                  );
                }}
              />
            </Div>
          </ConnectorTypesWrapper>
        }
      />
      <TextFiledGroup
        required
        label={`${t('common.maximumVoltage')}${t('common.unit.brackets.V')}`}
        desc={t('forms.numberOnly')}
        displayWarning={requiredFieldsWarning?.maxVoltage}
        warningText={t('forms.model.inputMaxVoltage')}
        component={
          <TextInput
            disabled={!isModify}
            style={{ marginBottom: 4 }}
            defaultValue={view.max_voltage}
            name="max_voltage"
            maxLength={5}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={(value: string | number) => onChangeHandler('maxVoltage', value)}
          />
        }
      />
      <TextFiledGroup
        required
        label={`${t('common.maximumAmpere')}${t('common.unit.brackets.A')}`}
        desc={t('forms.numberOnly')}
        displayWarning={requiredFieldsWarning?.maxAmpare}
        warningText={t('forms.model.inputMaxAmpere')}
        component={
          <TextInput
            disabled={!isModify}
            style={{ marginBottom: 4 }}
            defaultValue={view.max_ampare}
            name="max_ampare"
            maxLength={5}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={(value: string | number) => onChangeHandler('maxAmpare', value)}
          />
        }
      />
      <TextFiledGroup
        required
        label={`${t('common.efficiency')}${t('common.unit.brackets.percent')}`}
        desc={t('forms.numberOnly')}
        displayWarning={requiredFieldsWarning?.efficiencies}
        warningText={t('forms.model.inputEfficiency')}
        component={
          <TextInput
            disabled={!isModify}
            style={{ marginBottom: 4 }}
            defaultValue={view.efficiencies}
            maxLength={3}
            name="efficiencies"
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={(value: string | number) => {
              let newValue = Number(value);
              if (newValue > 100) {
                newValue = 100; // 값을 100으로 제한
                const inputElement = document.querySelector(`input[name="efficiencies"]`) as HTMLInputElement;
                if (inputElement) {
                  inputElement.value = newValue.toString();
                }
              }
              onChangeHandler('efficiencies', newValue);
            }}
          />
        }
      />
      <TextFiledGroup
        required
        label={t('common.IPLevel')}
        displayWarning={requiredFieldsWarning?.ipxLevel}
        warningText={t('forms.model.inputIpxLevel')}
        component={
          <TextInput
            disabled={!isModify}
            style={{ marginBottom: 4 }}
            defaultValue={view.ipx_level}
            name="ipx_level"
            maxLength={2}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={(value: string | number) => onChangeHandler('ipxLevel', value)}
          />
        }
      />
    </main>
  );
}

const Content = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  /* 버튼 */
  > button {
    cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
    width: 100%;
    border: 1px solid ${COLOR.GRAY2};
    border-radius: 8px;
    height: 40px;
    color: ${COLOR.GRAY1};
    &.active {
      color: ${({ $disabled }) => ($disabled ? COLOR.BLACK3 : COLOR.WHITE)};
      background-color: ${({ $disabled }) => ($disabled ? COLOR.GRAY5 : COLOR.BLACK3)};
      border: ${({ $disabled }) => ($disabled ? `1px solid ${COLOR.GRAY5}` : `1px solid ${COLOR.GRAY2}`)};
    }
    span {
      cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
    }
  }
`;
