/**
 * @name 이용약관
 * @description
 */
import styled from 'styled-components';
import { H1 } from 'lib';
import { COLOR } from 'styles';

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="content">
        <div className="prose max-w-none">
          <h2>제1장 총칙</h2>
          <h3>제1조(목적)</h3>
          <p>
            본 약관은 아셀로EV회원에게 주식회사 크로커스(이하 "회사")이 제공하는 서비스의 이용조건 및 운영에 관한 제반
            사항 규정을 목적으로 합니다.
          </p>
          <h3>제2조(정의)</h3>
          <p>본 약관에서 사용되는 주요한 용어의 정의는 다음과 같습니다.</p>
          <ol>
            <li>
              회원 : 사이트의 약관에 동의하고 개인정보를 제공하여 회원등록을 한 자로서, 사이트와의 이용 계약을 체결하고
              사이트를 이용하는 이용자를 말합니다.
            </li>
            <li>이용계약 : 사이트 이용과 관련하여 사이트와 회원 간에 체결하는 계약을 말합니다.</li>
            <li>
              회원 이메일(이하 "ID" 또는 "EMAIL") : 회원의 식별과 회원의 서비스 이용을 위하여 회원별로 부여하는 고유한
              문자와 숫자의 조합을 말합니다.
            </li>
            <li>
              비밀번호 : 회원이 부여받은 ID와 일치된 회원임을 확인하고 회원의 권익 보호를 위하여 회원 자신이 정한 문자와
              숫자의 조합을 말합니다.
            </li>
            <li>운영자 : 서비스에 홈페이지를 개설하여 운영하는 운영자를 말합니다.</li>
            <li>
              충전소 : 회사에서 구축을 진행한 전기차 충전소이거나 충전사업자 혹은 충전기 설치기관으로부터 위탁받아
              운영하는 충전소를 말합니다.
            </li>
            <li>
              멤버십 카드 : 회원이 충전 서비스 이용 편의를 위해 지급되는 카드를 의미하고 실물 카드와 가상 카드로
              구분됩니다.
            </li>
            <li>
              브이포인트(이하 "V 포인트") : 서비스 이용 실적에 따라 적립 받거나 유상으로 구매하여 회사의 서비스를 이용할
              때 사용할 수 있는 선불형 전자적 지급수단을 말합니다.
            </li>
            <li>
              본 약관에 사용되는 용어 중 본 조에서 정하지 아니한 부분은 본 약관의 다른 조항, 관계 법령 미 일반관례에
              따릅니다.
            </li>
          </ol>
          <h3>제3조(약관의 효력 및 변경)</h3>
          <ol>
            <li>본 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생시킵니다.</li>
            <li>
              회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 회원이 등록이 E-Mail, 서비스 또는 플랫폼 화면 등 다양한
              방법으로 회원에게 개별 통지 또는 게시하여 회원에게 통지할 수 있습니다.
            </li>
            <li>
              회사는 필요하다고 인정되는 경우 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호에 관한
              법률, 약관의 규제에 관한 법률 등 관련 법을 위배하지 않는 범위에서 본 약관을 변경할 수 있으며, 회사가
              약관을 변경할 경우에는 적용 일자와 변경 사유를 명시하여 제2항과 같은 방법을 이용하여 회원에게 정보를
              제공할 수 있습니다.
            </li>
            <li>
              회사가 제3항에 따라 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 현행약관과 함께 그 적용
              일자가 7일 이전부터 적용일 후 상당 기간동안 공지합니다.
            </li>
            <li>회원은 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.</li>
          </ol>
          <h3>제4조(약관의 해석)</h3>
          <ol>
            <li>
              회사는 유료 및 부가 서비스에 대해서는 별도의 이용약관 및 정책을 둘 수 있으며 그 내용이 약관과 상충할
              경우에는 해당 약관이 우선하여 적용됩니다.
            </li>
            <li>
              본 약관에 명시되지 않은 사항과 해석에 관하여는 정보통신망 이용 촉진 및 정보보호 등에 관한 법률(이하
              "정보통신망법"), 위치정보의 보호 및 이용 등에 관한 법률(이하 "위치정보법"), 전기통신 기본법,
              전기통신사업법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 온라인
              디지털콘텐츠산업 발전법, 문화체육관광부 장관이 정하는 디지털콘텐츠 이용자 보호지침 그리고 기타 관계 법령
              또는 상 관례에 따릅니다.
            </li>
          </ol>
          <h2>제2장 서비스의 이용</h2>
          <h3>제5조(이용 계약의 성립)</h3>
          <p>이용계약은 서비스를 이용하고자 하는 자의 이용 신청에 대한 회사의 이용 승낙으로 성립합니다.</p>
          <h3>제6조(이용 신청)</h3>
          <ol>
            <li>
              서비스의 이용 계약은 서비스에 가입하여 이용하고자 하는 자가 본 약관에 동의하여 회사가 정한 가입절차를
              완료함으로서 체결됩니다.
            </li>
            <li>
              회사는 서비스에 가입하여 이용하고자 하는 자에게 본인확인을 위하여 서비스를 이용하고자 하는 자의 휴대 단말
              식별 정보 및 본인확인 인증 절차(이하 "본인인증 절차")를 요구할 수 있습니다. 이 경우 서비스에 가입하여
              이용하고자 하는 자는 해당 인증을 수행하는 등 가입 절차를 완료하여야 합니다.
            </li>
            <li>
              회원은 서비스 이용을 위해 회원 정보 등록을 할 경우 현재의 사실과 일치하는 완전한 정보(이하 "등록정보")를
              제공하여야 합니다. 단, 서비스 이용에 필수 사항 이외의 선택 정보는 그러지 아니합니다.
            </li>
            <li>
              회원의 관리 소홀로 타인의 회원 본인 명의의 인증 절차를 수행하거나, 회원이 타인의 명의를 도용하여 인증을
              수행하는 경우, 그로 인해 발생하는 불이익 및 기타 손해에 대하여 회사는 책임지지 않습니다.
            </li>
          </ol>
          <h3>제7조(이용 신청의 승낙 및 불 승낙)</h3>
          <ol>
            <li>
              회사는 가입 신청자의 서비스 이용 신청에 대하여 원칙적으로 승낙합니다. 단, 회사는 다음 각호에 해당하는
              신청에 대해서는 그 사유가 해소될 때까지 승낙을 유보하거나 거부할 수 있습니다.
              <ol>
                <li>가명, 차명, 허무인 명의 기타 가입 신청자 본인의 실제 이름과 다른 이름에 의한 신청</li>
                <li>이용 신청 시 허위 내용을 등록한 경우</li>
                <li>이용 신청자가 회사의 채무자로서 채무를 완제하지 않고 있는 경우</li>
                <li>본 약관에 의하여 이전에 회원 자격을 상실한 적이 있는 경우</li>
                <li>만 14세 미만인 아동이 서비스 이용 신청을 한 경우</li>
                <li>
                  회사로부터 서비스 이용정지 등의 제재를 받은 회원이 그 제재 기간에 이용계약을 임의로 해지하고 재이용
                  신청을 하는 경우
                </li>
                <li>기타 회사가 정한 이용 신청 요건이 충족하지 않았거나 위법 또는 부당한 이용 신청임이 확인된 경우</li>
              </ol>
            </li>
            <li>
              회사는 제1항으로 인해 정상적인 서비스 이용이 불가능해진 경우 그 사유를 제3조 2항의 방법으로 통지하거나
              플랫폼 화면 등에 게시할 수 있습니다.
            </li>
            <li>
              회사는 다음 각호에 해당하는 경우 그 사유가 해소될 때까지 이용 승낙을 보류할 수 있으며, 그 사유를
              웹사이트에 공지합니다.
              <ol>
                <li>설비의 여유가 없는 경우</li>
                <li>기술상 또는 업무 수행상 지장이 있는 경우</li>
              </ol>
            </li>
          </ol>
          <h3>제8조(이용 계약 사항의 변경)</h3>
          <ol>
            <li>회원은 이용 신청 시 기재한 사항이 변경되었을 경우에 온라인으로 수정합니다.</li>
            <li>
              회원의 이용 신청 시 기재 사항의 미변경 또는 허위 변경으로 인하여 발생하는 문제에 대한 책임은 회원에게
              있습니다.
            </li>
            <li>
              회사는 다음 각호에 해당하는 경우 직권에 의하여 ID의 사용을 제한하거나 회원의 신청에 따라 ID를 변경하거나
              서비스 이용을 제한할 수 있습니다.
              <ol>
                <li>아이디가 반사회적 또는 미풍양속에 어긋나는 경우</li>
                <li>아이디가 회원의 사생활을 침해할 우려가 있는 경우</li>
                <li>개인정보 유출 우려가 있는 경우</li>
                <li>회사 및 그 유관 회사의 운영자로 오인될 우려가 있는 경우</li>
                <li>기타 합리적인 사유가 있는 경우</li>
              </ol>
            </li>
          </ol>
          <h3>제9조(서비스 내용)</h3>
          <ol>
            <li>
              회사가 제공하는 서비스는 각호와 같습니다.
              <ol>
                <li>멤버십 카드 발급 신청 및 등록 서비스</li>
                <li>충전소 운영 관리 및 충전 서비스에 부수하는 결제 서비스</li>
                <li>회원의 충전소 이용 명세</li>
                <li>기타 전기차 이용 고객의 충전소 이용 편의 서비스 일체</li>
              </ol>
            </li>
            <li>회사는 회원의 서비스 이용 편의를 위해 다양한 유료 서비스를 구성하여 제공할 수 있습니다.</li>
          </ol>
          <h3>제10조(서비스 이용 및 변경 등)</h3>
          <ol>
            <li>회사는 서비스 내용 및 이용 정책을 각 서비스 이용 화면을 통하여 안내합니다.</li>
            <li>
              서비스는 회사가 서비스 이용을 승인한 시점부터 이용할 수 있으며, 일부 서비스는 별도로 지정된 일자부터
              이용할 수 있습니다. 단, 회사의 업무상 또는 기술상 장애로 인하여 서비스를 개시하지 못하는 경우에는 서비스
              페이지에 공지하거나 회원에게 개별적으로 이를 통지합니다.
            </li>
            <li>
              회사는 운영상, 기술상의 필요에 따라 제공하는 서비스의 일부 혹은 전부를 추가하거나 변경할 수 있습니다.
              그리고 그 변경 내용이 호원에게 불리할 경우 제3조에서 정한 방법으로 통지합니다.
            </li>
            <li>
              회사는 제1항에 따라 변경된 서비스 이용에 동의하지 않는 고객에게는 변경 전 서비스를 제공합니다. 그러나
              정상적인 서비스 제공이 불가능할 경우 해당 서비스 제공을 중지하거나 이용 계약을 해지할 수 있습니다.
            </li>
            <li>
              회사는 다음 각호의 경우에는 서비스의 일부 또는 전부를 중단할 수 있습니다.
              <ol>
                <li>
                  통신, 전력 등의 공급이 중단되거나 정보통신설비의 보수점검, 증설, 교체, 이전, 고장, 장애 또는 운영상
                  타당한 이유가 있는 경우
                </li>
                <li>경영상의 판단 등 회사의 제반 사정으로 서비스를 유지할 수 없는 경우</li>
                <li>파산 또는 회생절차 개시 등 정상적인 영업활동이 곤란한 경우</li>
              </ol>
            </li>
          </ol>
          <h3>제11조(서비스 이용 계약 해지 및 이용 제한)</h3>
          <ol>
            <li>
              회원은 언제든지 회사에 이용계약 해지를 통지할 수 있습니다. 다만, 회원이 이용 계약 해지를 위해서는 회원이
              회사의 채무를 완제하여야 합니다.
            </li>
            <li>
              회사는 제1항에 따라 이용계약이 해지되는 경우 회원에게 발생할 수 있는 불이익 등에 대해서는 귀책 사유가
              없으며 회사가 회원에게 무상으로 제공한 브이포인트와 같은 혜택은 계약 해지와 함께 소멸합니다.
            </li>
            <li>
              회원이 제공한 등록 정보 및 갱신한 등록 정보가 부정확하여 회원 혹은 회사의 일방적인 피해가 우려되는 경우
              회사는 서비스 이용을 제한 또는 중단시킬 수 있습니다. 단, 이와 같은 사유가 발생했을 경우 제3조에서 정한
              방법으로 회원에게 통보합니다.
            </li>
            <li>
              회사는 비정상적인 서비스 이용 등 회원의 귀책 사유가 발생하면 그 사유를 제3조에서 정한 방법으로 안내하고 그
              계약을 해지할 수 있습니다.
            </li>
            <li>
              회원은 제3항, 4항에 따라 서비스를 제한하거나 이용 계약 해지 통보를 받은 경우, 통지일로부터 10일 이내에
              항변 및 소명할 수 있고 해당 항변 및 소명이 정당한 경우 회사는 서비스 이용 제한 혹은 이용계약 해지를 취소할
              수 있습니다. 따라서 회원이 기한 내 항변 또는 소명을 하지 않는 경우는 통보의 사실에 동의한 것으로
              간주합니다.
            </li>
          </ol>
          <h3>제12조(ID 및 비밀번호 관리에 대한 회원의 의무)</h3>
          <ol>
            <li>서비스를 이용하고자 하는 고객은 회사가 정하는 바에 따라 ID 및 비밀번호를 등록해야 합니다.</li>
            <li>
              ID와 비밀번호에 관한 관리 책임은 회원 본인에게 있으므로, 회원은 자신의 ID 및 비밀번호를 제 3자에게
              알려주거나 이용하게 해서는 안 됩니다.
            </li>
            <li>
              회원이 자신의 ID 또는 비밀번호를 도난당하거나 제 3자가 사용하고 있음을 인지한 경우에는 즉시 회사에
              통보하고 회사의 안내에 따라 제 3자의 이용 제한 조치에 따라야 합니다.
            </li>
            <li>
              제3항의 상황에 해당 회원이 회사에 그 사실을 통보하지 않거나, 통지한 경우에는 회사의 안내에 따르지 않아
              발생한 불이익에 대하여 회사는 어떠한 책임도 지지 않습니다.
            </li>
          </ol>
          <h3>제13조(서비스의 제공)</h3>
          <ol>
            <li>
              회사는 정보통신망 또는 정보통신설비의 장애가 발생하거나 정기 점검 등 서비스의 원활한 운영을 위하여 필요한
              경우 관련 서비스를 일시 중지할 수 있습니다. 이러한 경우 회사는 E-Mail, SMS 등의 방법으로 회원에게
              알려드리며, 사전에 알려드릴 수 없는 부득이한 사유가 있는 경우 사후에 알려드릴 수 있습니다.
            </li>
          </ol>
          <h3>제14조(멤버십 카드의 이용 및 관리)</h3>
          <ol>
            <li>회원은 회사가 제공하는 플랫폼 내 멤버십 카드 신청을 최초에만 카드를 발급받을 수 있습니다.</li>
            <li>
              멤버십 카드는 실물 카드로 제공되며 발급과 동시에 멤버십 번호와 비밀번호로 실물 카드 없이 사용이
              가능합니다.
            </li>
            <li>
              회원이 충전 서비스를 이용하고자 할 경우에는 실물 카드를 충전기에 접촉하거나 회원 번호 및 비밀번호 입력 등
              회사가 제공하는 인증 방법의 하나를 선택해야 합니다. 단, 회원이 아닌 경우 신용카드를 충전기의 결제 단말기에
              접촉하거나 회사가 제공하는 간편결제 서비스를 선택적으로 이용할 수 있습니다.
            </li>
            <li>
              회원은 멤버십 카드 및 비밀번호를 스스로 책임하에 관리하여야 하며 회원의 고의 또는 과실로 멤버십 카드가
              훼손, 분실, 도난당하거나 비밀번호가 유출되는 등의 사고가 발생할 경우 당해 회원은 그 사실을 즉시 회사에
              통지하여야 합니다.
            </li>
            <li>
              회사는 플랫폼 내에서 멤버십 카드를 즉시 정지하거나 분실 신고를 간편하게 할 수 있는 기능을 제공하고
              있습니다.
            </li>
            <li>
              회사는 회원으로부터 제4항에 따른 회원의 통고가 있기 전에 회원에게 발생한 손해에 대하여 어떠한 책임도 지지
              않습니다.
            </li>
          </ol>
          <h3>제15조(충전기 이용 시 준수 사항)</h3>
          <ol>
            <li>
              회원은 충전기 사용 시 반드시 회사의 충전기 사용 안내 가이드를 준수해야 하며, 충전기 사용 중 문제 발생 시
              본인 및 다른 회원의 안전을 위해 즉시 회사로 알려야 합니다.
            </li>
            <li>
              회사가 운영하는 충전소의 급속충전기기를 이용할 때 즉시 충전 혹은 예약 충전의 방법을 선택할 수 있으며 이에
              대한 이용 가능 시간은 회사의 정책에 따라 제한할 수 있으며 고객에게 제3조 2항의 방법을 활용하여 안내합니다.
            </li>
            <li>
              충전기 이용 시간의 제한 정책에 따라 회사는 이를 위반하는 고객을 대상으로 충전요금 이외의 별도 부과금을
              청구할 수 있습니다.
            </li>
            <li>
              회원은 충전기 사용 시간 내에 발생하는 회원의 모든 법률 위반으로 인한 벌금, 과태료 등의 불이익을 부담해야
              할 의무가 있습니다. 그리고 회사는 해당 기관이 법률 위반 집행에 필요한 자료 요구에 협조해야 합니다.
            </li>
          </ol>
          <h3>제16조(충전 서비스 등의 사용 기록 및 이용 명세 정보 제공)</h3>
          <ol>
            <li>
              회원이 충전소에서 사용한 충전량, 충전 금액 등의 정보 기록은 자동으로 충전기에서 회사의 서버로 전송되며
              최대 5년간 보관됩니다.
            </li>
            <li>
              회원의 충전 및 기타 서비스 이용 명세는 회사가 운영하는 서비스의 회원 계정을 통해 상시로 그 명세를 확인할
              수 있도록 정보를 제공합니다.
            </li>
            <li>
              충전기의 충전량 및 통계 데이터 등의 사용 현황 데이터는 충전기 정상 운영 확인, 더 나은 회원 서비스,
              충전량과 과금 관련, 사업자 자산관리 및 보호 등의 목적을 위해 활용될 수 있습니다.
            </li>
          </ol>
          <h3>제17조(브이포인트의 이용)</h3>
          <ol>
            <li>
              회원은 회사가 지정한 지불수단을 통해 브이포인트를 구매할 수 있으며 회사는 회원에게 사용 실적, 이벤트 참여
              등 회사가 정한 정책에 따라 무상으로 제공할 수 있습니다.
            </li>
            <li>
              회원은 서비스 이용 시 간편결제수단과 함께 브이포인트를 사용할 수 있습니다. 단, 무상으로 지급된
              브이포인트는 회사가 정한 유효기한 내에 회사가 정한 방법으로만 사용할 수 있습니다.
            </li>
            <li>
              회사가 제공하는 브이포인트와 쿠폰 등은 중복으로 사용하지 못할 수 있으며 그 사용 순서를 고객이 정하거나
              회사가 고객이 선택할 방법을 제공합니다.
            </li>
            <li>
              회원은 전자금융거래법 등이 정한 바에 따라 유상으로 구매한 브이포인트는 구매 의사 철회 혹은 환급을 받을 수
              있습니다.
            </li>
            <li>
              다음 각호에 해당하는 경우 브이포인트나 쿠폰 등 혜택은 자동 소멸합니다.
              <ol>
                <li>무상으로 지급받은 각종 혜택의 유효 기간이 만료된 경우</li>
                <li>유상으로 구매한 브이포인트의 지급 가능 시효가 소멸한 경우</li>
              </ol>
            </li>
          </ol>
          <h3>제18조(브이포인트와 쿠폰 유효기간 및 이용 내역 기록)</h3>
          <ol>
            <li>
              브이포인트
              <ol>
                <li>회원이 직접 구매한 경우 : 구매일로부터 3년</li>
                <li>제휴사가 구매한 경우 : 공급 계약을 통해 상호합의로 정하되 2년을 초과할 수 없음</li>
                <li>당사가 무상으로 지급한 경우 : 지급일로부터 1년</li>
              </ol>
            </li>
            <li>쿠폰 : 쿠폰에 명시된 유효기간</li>
            <li>
              브이포인트와 쿠폰의 지급, 구매, 소멸과 같은 이용 명세는 회사가 제공하는 플랫폼 서비스의 회원 계정을 통해
              수시로 확인할 수 있다.
            </li>
          </ol>
          <h3>제19조(결제의 이용)</h3>
          <ol>
            <li>
              회원은 본인인증 방식 등 회사에서 정한 방법을 거쳐 결제 카드를 등록하고 회사가 정한 각 서비스 별 이용정책에
              따라 간편결제의 방식으로 서비스를 이용할 수 있습니다. 간편결제는 즉시 결제 방식만을 제공합니다.
            </li>
            <li>
              회원이 간편결제를 이용하는 경우 다음, 다음 각호에 동의한 것으로 간주합니다.
              <ol>
                <li>
                  간편결제 이용에 동의한 회원은 서비스 이용요금에 대해 별도의 인증 과정 없이 결제하겠다는 의사표시를 한
                  것으로 봅니다.
                </li>
                <li>
                  회원의 신용카드 또는 다른 유형의 결제 카드가 개인 신용등급 및 연체 등의 문제로 인해 결제가 거절되어
                  서비스 이용에 대한 비용이 정산되지 않을 경우에 회사는 사전통지 없이 서비스 이용을 중지할 수 있으며,
                  지속적인 문제가 발생할 경우 회원자격을 박탈할 수 있습니다.
                </li>
                <li>
                  제2호에 따라 미수금이 발생한 경우, 회사는 회사가 정한 정책에 따라 회원에 대한 별도 고지 없이 회원이
                  등록한 결제수단으로 재결제 시도를 진행할 수 있습니다.
                </li>
                <li>
                  결제 시점에서 시스템 장애로 인하여 결제 실패가 된 경우에는, 시스템 정상화가 된 시점에 회원에 대한 별도
                  고지 없이 다시 결제를 진행합니다.
                </li>
                <li>
                  제2호에 따라 미수금이 발생하여 서비스 이용이 중지될 경우, 플랫폼 내 재청구 요청을 통해 결제가
                  정상적으로 이루어질 경우 회원의 서비스를 재개시키도록 합니다.
                </li>
              </ol>
            </li>
            <li>
              회사는 회원의 결제 정보를 확인할 수 있으며, 회원의 제반 결제 실적에 오류가 있으면 이를 정정할 수 있습니다.
            </li>
          </ol>
          <h3>제20조(환불 및 연체)</h3>
          <ol>
            <li>
              회원의 귀책 사유가 아닌 통신장애, 시스템 오류 등 정상적인 서비스를 이용하지 못하거나 초과 결제, 중복결제
              등의 문제가 발생한 경우 회사는 해당 서비스의 정상적인 이용을 전제로 부과된 이용요금 또는 잘못 결제된
              이용요금, 취소 수수료의 전부 또는 일부를 해당 회원에게 환불합니다.
            </li>
            <li>
              회원이 이용요금, 수수료 등에 대한 이의를 제기하거나 환불 요청을 하려면 회사에 직접 요구해야 합니다. 단,
              회사가 제공하는 플랫폼을 통해 구매한 서비스 중 제휴 협약을 통해 제공되고 제휴사의 이용약관에 따라 제공되는
              형태의 부가서비스는 직접 거래당사자에게 하여야 합니다. 관련된 정보는 제6조에 따라 제공됩니다.
            </li>
            <li>
              회원의 이의신청 다음 각호의 절차에 따라 진행합니다.
              <ol>
                <li>
                  회사는 이의신청 접수 후 10일 이내에 이의 타당성 여부를 조사하고 그 결과를 회원 또는 그 대리인에게
                  통지합니다.
                </li>
                <li>
                  부득이한 사유로 인하여 제1항에서 정한 기간 내에 이의신청 결과를 통지할 수 없는 경우에는 그 사유 및
                  재지정된 처리 기한을 명시하여 이를 회원 또는 그 대리인에게 통지합니다.
                </li>
              </ol>
            </li>
            <li>
              회사는 회원이 지불한 결제 수단과 동일한 수단을 통해 환불합니다. 단, 동일한 수단으로 환불이 불가능한 경우
              현금 등 다른 수단으로 환불할 수 있습니다.
            </li>
            <li>
              회사는 요금 등을 반환 혹은 환불하여야 할 회원에게 미납요금 등이 있으면 반환하여야 할 요금 등에서 우선
              변제하고 반환할 수 있습니다.
            </li>
            <li>
              회사는 회원이 서비스 요금을 지정한 기일까지 납입하지 아니한 때에는 그 요금의 100분의 2에 상당하는 가산금이
              월별로 가산하며, 1개월 미만 연체 일수에 대해서는 일할 계산하여 재청구합니다.
            </li>
          </ol>
          <h2>제3장 이용계약 당사자의 의무</h2>
          <h3>제21조(회사의 의무)</h3>
          <ol>
            <li>
              회사는 서비스 제공과 관련하여 취득한 회원의 개인정보 또는 위치정보(이하 통칭할 경우 "개인정보 등")를
              본인의 승낙 없이 제 3자에게 누설·배포하지 않습니다. 단, 관계 법령에 의한 수사상의 목적으로
              관계기관으로부터 요구받은 경우 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않습니다.
            </li>
            <li>
              회사는 업무와 관련하여 회원의 사전 동의 없이 회원 전체 또는 일부의 개인정보 등을 특정 회원의 정보를 구별할
              수 없는 통계자료로 작성하여 이를 사용할 수 있습니다.
            </li>
            <li>
              회사는 서비스와 관련된 회원의 불만 사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가
              곤란한 경우 그 사유와 처리 일정을 서비스 화면에 게재하거나 제3조에서 정한 방법으로 회원에게 통지합니다.
            </li>
            <li>
              회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 과실을 근거로 해
              발생한 때에만 회사에서 책임을 부담하여, 그 책임의 범위는 통상손해에 한합니다.
            </li>
            <li>
              회사는 정보통신망법, 위치정보보호법, 통신비밀보호법, 전기통신사업법, 전자금융거래법 등 서비스의 운영 및
              유지와 관련 있는 법규를 준수합니다.
            </li>
          </ol>
          <h3>제22조(회원의 의무)</h3>
          <ol>
            <li>
              회원은 서비스 이용과 관련하여 다음 각호의 행위를 하여서는 안 됩니다.
              <ol>
                <li>서비스 이용 관련 제반 신청행위 또는 변경행위 시 허위 내용을 기재하는 행위</li>
                <li>서비스에 게시된 각종 정보의 무단 변경, 삭제 등 훼손 행위</li>
                <li>회사가 허용한 정보 이외의 다른 정보(컴퓨터 프로그램 및 고아고 등)를 송신하거나 게시하는 행위</li>
                <li>회사 및 제 3자의 저작권 등 지식재산권에 대한 침해 행위</li>
                <li>회사 및 제 3자의 명예를 손상하거나 업무를 방해하는 행위</li>
                <li>회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</li>
                <li>기타 서비스 이용에 있어서 불법적이거나 부당한 행위</li>
              </ol>
            </li>
            <li>
              회원이 서비스 페이지에 게시판 댓글 등의 게시물이 다음에 규정하는 부적격 자료에 해당하여 변경·삭제하는 등의
              적정한 조처를 하도록 회사의 요구나 지침이 있는 경우, 회원은 즉시 해당 게시물을 변경·삭제하는 등의 적정한
              조처를 하여야 하며, 기한 내에 적정한 조처를 하지 않는 경우 회사가 임의로 변경·삭제 등의 적정한 조치를 하는
              데 동의한 것으로 봅니다.
              <ol>
                <li>
                  저작권 침해자료
                  <ol>
                    <li>회사의 저작권, 제 3자의 저작권 등 기타 권리를 침해하는 자료</li>
                    <li>저작권자가 배포를 원하지 않는 자료</li>
                  </ol>
                </li>
                <li>
                  음란자료
                  <ol>
                    <li>사회상규 및 일반 통념상 성적 수치심을 일으킬 수 있는 표현이 포함된 자료</li>
                    <li>기타 관련 법령이 허용하는 수준을 넘는 성적 표현이 포함된 자료</li>
                  </ol>
                </li>
                <li>
                  폭력물 및 명예훼손에 관한 자료
                  <ol>
                    <li>과도하게 폭력적인 내용을 가지고 있는 자료</li>
                    <li>
                      다른 회원, 제 3자 또는 회사를 비방하거나 명예를 훼손하거나 불이익을 끼치는 것으로 판단되는 자료
                    </li>
                  </ol>
                </li>
                <li>
                  기타 부적격 자료
                  <ol>
                    <li>공공질서 및 공서양속에 위반되는 내용을 유포하는 경우</li>
                    <li>범죄적 행위에 결부된다고 인정되는 내용인 경우</li>
                    <li>첨예한 정치적·종교적 분쟁을 야기할 수 있는 경우</li>
                    <li>불필요하거나 승인되지 않은 광고·판촉물을 게재하는 경우</li>
                    <li>
                      타인의 개인정보를 도용하여 작성한 내용이거나, 타인의 입력한 정보를 무단으로 위·변조한 내용인 경우
                    </li>
                    <li>동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 경우</li>
                    <li>전기통신사업법 등 관계 법령, 회사의 약관 및 이용지침 등에 위반된다고 판단되는 경우</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              회원은 관련 법령, 본 약관의 규정, 이용 안내 및 서비스와 관련하여 공지한 주의사항 등 다음 각호에 해당하는
              사항을 준수하여야 합니다.
              <ol>
                <li>
                  회원은 서비스 이용 중 고의 또는 과실로 인해 발생하는 회사의 모든 재산상의 손실이나 제 3자에게
                  낓니·물적 손실에 대하여 배상할 책임이 있습니다.
                </li>
                <li>회원은 충전 완료 후 다른 사용자를 위해 즉시 차량을 이동하여야 할 의무가 있습니다.</li>
              </ol>
            </li>
          </ol>
          <h3>제23조(게시물의 저작권 등)</h3>
          <ol>
            <li>
              회원의 게시물에 대한 저작권은 저작권법에 의한 보호를 받습니다. 회사가 작성한 저작물에 대한 저작권 기타
              지식재산권은 회사에 귀속합니다.
            </li>
            <li>
              회사는 원활한 서비스의 제공을 위해 필요한 경우 게시물에 관련된 세부 이용 지침을 별도로 정하여 시행할 수
              있으며, 서비스 페이지에 등록된 게시물 등을 모니터링할 수 있습니다.
            </li>
            <li>
              회원은 자신이 게시한 게시물을 회사가 국내외에서 다음 각호의 목적으로 사용하는 것을 허락합니다.
              <ol>
                <li>
                  서비스 페이지 내에서 회원 게시물의 복제, 전송, 전시, 배포 및 우수 게시물을 서비스 화면에 노출하기
                  위하여 회원 게시물의 크기를 변화하거나 단순화하는 등의 방식으로 수정
                </li>
                <li>
                  회사가 운영하는 관련 사이트의 서비스 내에서 회원 게시물을 전시·배포. 단 회원이 전시·배포에 대하여
                  명백히 반대의 의사를 표명하는 경우에는 예외로 합니다.
                </li>
                <li>
                  회사를 홍보하기 위한 목적으로 해당 회원의 동의를 득하여 미디어, 통신사 등에게 이용자의 게시물 내용을
                  보도·방영
                </li>
              </ol>
            </li>
            <li>
              회사가 회원의 게시물을 전항 각호에 기재된 목적 이외에 상업적 목적으로 사용하고자 하는 경우에는 사전에 해당
              회원으로부터 동의를 얻습니다.
            </li>
            <li>
              회원이 이용계약을 해지하거나 본 약관에 의하여 회원 자격을 상실한 경우에 본인 계정에 기록된 게시물은
              삭제됩니다. 다만 제 3자에게 의하여 스크랩, 펌, 담기 등으로 다시 게시된 게시물, 제 3자에 의하여 댓글이
              첨부된 게시물 등 다른 회원의 정상적인 서비스 이용에 필요한 게시물은 삭제되지 않을 수 있습니다.
            </li>
            <li>
              회사가 합병, 영업양도, 운영하는 사이트 간의 통합 등의 사유가 발생하는 경우, 필요한 범위 및 방법 내에서
              게시물의 위치를 변경할 수 있습니다.
            </li>
          </ol>
          <h3>제24조(기재 사항의 변경)</h3>
          <ol>
            <li>
              회원은 이용계약과 관련하여 기재한 사항이 변경되었을 경우 회사가 안내하는 별도의 절차에 의해 지체 없이
              수정하여야 합니다.
            </li>
            <li>제1항의 미준수로 인하여 발생하는 모든 손실은 회원에게 책임이 있습니다.</li>
          </ol>
          <h3>제25조(서비스 이용 제한)</h3>
          <p>
            회사는 회원에 대하여 "영화 및 비디오물의 진흥에 관한 법률" 및 "청소년 보호법" 등 관련 법령에 따른 등급 및
            연령 준수를 위해 이용 제한이나 등급별 제한을 할 수 있습니다.
          </p>
          <h3>제26조(이용계약 해지 및 회원 자격상실·정지)</h3>
          <ol>
            <li>
              회원은 언제든지 서면, E-mail, 전화 등의 방법으로 이용계약 해지를 요청할 수 있으며, 회사는 회원의 요청에
              따라 조속히 이용계약 해지에 필요한 제반 절차를 수행합니다.
            </li>
            <li>
              회원이 다음 각호의 사유에 해당하는 경우, 회사는 당해 회원에 대한 통보로서 회원의 자격 상실 또는 1년 동안
              정지시킬 수 있습니다. 단, 아래 중 제3호의 경우에는 별도의 통보 없이 자격이 상실됩니다.
              <ol>
                <li>타인의 명의를 이용 또는 도용한 경우</li>
                <li>이용계약 신청 시에 허위의 내용을 기재한 경우</li>
                <li>회원이 사망한 경우</li>
                <li>
                  상거래 서비스를 이용하여 구입한 상품/서비스 대금, 기타 서비스 이용과 관련하여 회원이 부담하는 책무를
                  기일에 지급하지 않는 경우
                </li>
                <li>다른 회원의 서비스 이용을 방해하거나 다른 회원의 정당한 서비스 이용의 권리를 침해하는 경우</li>
                <li>서비스를 이용하여 현행 법령이나 동 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                <li>기타 본 약관에 규정된 회원의 의무를 위반한 경우</li>
              </ol>
            </li>
            <li>
              제2항이 정하는 사유로 자격이 상실 또는 정지된 회원은 해당 사유가 자신의 고의 또는 과실에 기한 것이 아님을
              소명할 수 있습니다. 이 경우 회사는 회원의 소명 내용을 심사하여 회원의 주장이 타당하다고 판단하는 경우
              회원으로 하여금 정상적인 서비스를 이용할 수 있도록 합니다.
            </li>
            <li>
              제1항에 의한 이용계약 해지 또는 제3항에 의한 회원자격 상실이 확정되는 시점은 이용계약 해지 요청일 또는
              회사가 통보하는 회원자격 상실 예정일에 이용계약 해지 또는 회원자격 상실이 확정되며, 제2항 제3호의 경우에는
              회원 사망일에 자격상실이 확정됩니다.
            </li>
            <li>
              회원이 멤버십 서비스를 마지막으로 이용한 날로부터 12개월 경과 시 회원의 개인정보 보호를 위해 개인정보를
              파기하거나, 다른 회원의 개인정보와 분리하여 별도로 저장, 관리할 수 있습니다. 자세한 내용은 개인정보 처리
              방침을 참고하시기 바랍니다.
            </li>
          </ol>
          <h3>제27조(개인정보의 보호)</h3>
          <p>
            회원들의 개인정보는 서비스의 원활한 제공을 위하여 회원들이 동의한 목적과 범위 내에서만 이용됩니다. 회사는
            관련 법령을 준수하며, 회원들의 동의하지 아니하는 한 회원들의 개인정보를 제 3자에게 제공하는 일은 절대
            없습니다. 자세한 내용은 개인정보 처리 방침을 참고하여 주십시오.
          </p>
          <h3>제28조(개인정보보호 및 처리 지침의 공지)</h3>
          <ol>
            <li>
              회사는 개인정보 보호에 대한 상세한 내용을 개인정보 처리 방침에 명시하고 있으며, 회원이 상시 확인할 수
              있도록 서비스 플랫폼에 게시하고 있습니다.
            </li>
            <li>
              개인정보 보호의 내용은 추가되거나 변경될 수 있으며, 이 경우 추가 또는 변경 사항을 본 계약 제3조 제2항에
              규제된 통지 방법을 준용하여 회원에게 알려드립니다.
            </li>
          </ol>
          <h2>제4장 기타</h2>
          <h3>제29조(손해 보상)</h3>
          <ol>
            <li>
              회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 회사에 발생하는 손해를
              배상하여야 합니다.
            </li>
            <li>
              회원의 서비스를 이용하면서 행한 불법행위나 본 약관 위반을 행위로 인하여 회사가 당해 회원 이외의 제
              3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로
              회사를 면책시켜야 하며 그로 인해 발생하는 회사의 손해에 대한 배상 책임이 있습니다.
            </li>
            <li>
              회사의 고의나 과실에 의하여 회원에게 손해가 발생한 경우 그 손해에 대하여 회사에서 비용을 부담하여 손해
              배상 책임이 있습니다.
            </li>
          </ol>
          <h3>제30조(면책사유)</h3>
          <ol>
            <li>
              회사는 천재지변 도는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한
              회사의 귀책 사유가 없습니다.
            </li>
            <li>
              회사는 회원의 귀책 사유로 인한 서비스의 이용 장애에 대하여 회사의 귀책 사유가 없는 한 책임을 지지
              않습니다.
            </li>
            <li>
              회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 무관하며 서비스를 통하여 얻은 2차적인
              자료로 인한 손해 등에 대하여도 책임을 지지 않습니다. 회사는 회원이 서비스에 게재한 정보, 자료, 사실의
              신뢰도 및 정확성 등 내용과는 무관합니다.
            </li>
            <li>
              회사는 회원 상호 간 또는 회원과 제 3자 상호 간에 서비스를 매개로 발생한 부분에 대해서는 개입하지 않습니다.
            </li>
            <li>회사는 서비스의 내용 및 서비스에서 제공되는 정보는 정확성을 보증하지 않습니다.</li>
            <li>
              회사는 법령 등의 집행으로 서비스 제공에 제한이 발생한 경우 회사의 귀책 사유가 없는 한 책임을 지지
              않습니다.
            </li>
            <li>기타 공권에 의한 서비스 제공에 제한이 발생하는 경우 회사는 책임을 지지 않습니다.</li>
          </ol>
          <h3>제31조(서비스의 종료)</h3>
          <ol>
            <li>
              회사는 서비스를 종료하고 할 경우 서비스를 종료하고자 하는 날로부터 7일 이전에 제3조 제2항에 규정된 통지
              방법을 통해 회원에게 알려드립니다.
            </li>
            <li>제1항에 따라 회사가 통지한 서비스 종료일 이후 회원은 회사로부터 서비스를 제공받지 못합니다.</li>
          </ol>
          <h3>제32조(준거법 및 합의관할)</h3>
          <ol>
            <li>본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 관계 법령 또는 상 관례에 따릅니다.</li>
            <li>
              서비스 및 본 약관과 관련된 제반 분쟁 및 소송은 회사의 소재지인 청주지방법원을 제1심 관할법원으로 합니다.
            </li>
          </ol>
          <h2>[부칙]</h2>
          <ul>
            <li>공고 일자: 2024년 4월 3일</li>
            <li>시행 일자: 2024년 4월 17일</li>
          </ul>
        </div>
      </div>
    </Content>
  );
}
//*--------------------------------------------------*
const Content = styled.div`
  .content {
    font-size: 14px;
    background-color: #fff;
    padding: 20px;
  }
  .tabs {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #999;
  }
  .tabs a {
    width: 50%;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 10px 0;
    border-bottom: 2px solid transparent;
  }
  .tabs a.active {
    background-color: #0056b3;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    color: #555;
  }
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
  }
  ul {
    list-style: none;
  }
  ul li {
    margin-bottom: 10px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  table,
  th,
  td {
    border: 1px solid #ddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
  }
  th {
    background-color: #f2f2f2;
  }
  blockquote {
    margin: 10px 0;
    padding: 10px;
    background-color: #f9f9f9;
    border-left: 5px solid #ccc;
  }
  a {
    color: #007bff;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;
