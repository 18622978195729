import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'model';
import { useContext } from 'lib';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import StationListPresenter from './StationList.presenter';
import { actions } from '@/model/modules/station';
import { SCREEN_MODE } from '@/types/mode';
import { getExcelFile, getStations, removeStation } from '@/lib/apis/infra';
import { Paging } from '@/lib/apis/infra/type';
import { MENU } from '@/pages/router';

/**
 * @description 충전소등록관리 리스트
 */
export default function StationListContainer() {
  const { cpo_seq } = useContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || '1';
  const searchQueryString = searchParams.get('search') || '';
  const locationQueryString = searchParams.get('location') || '전체지역';

  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const [searchKeyword, setSearchKeyword] = useState(searchQueryString);
  const [appliedSearchKeyword, setAppliedSearchKeyword] = useState(searchQueryString);
  const [selectedLocation, setSelectedLocation] = useState(locationQueryString);

  const [deletePopup, setDeletePopup] = useState(false);
  const [noDeletePopup, setNoDeletePopup] = useState(false);
  const [removeTargetId, setRemoveTargetId] = useState<null | string>(null);

  const itemsPerPage = 10;

  const queryParamsURL = (page?: number, location?: string, search?: string) => {
    const locationValue = location ?? selectedLocation;
    const locationKeyword = locationValue === '전체지역' ? '' : locationValue;
    const searchKeyword = search ?? appliedSearchKeyword;
    const queryParams = [];

    if (searchKeyword) {
      queryParams.push(`search=${encodeURIComponent(searchKeyword)}`);
    }
    if (locationKeyword) {
      queryParams.push(`location=${encodeURIComponent(locationKeyword)}`);
    }
    queryParams.push(`page=${page ?? currentPage}`);
    const queryString = queryParams.join('&');
    return queryString;
  };

  const pageHandler = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      navigate(`?${queryParamsURL(page)}`);
      fetchList({
        size: itemsPerPage,
        page,
        location: selectedLocation === '전체지역' ? '' : selectedLocation,
        search: appliedSearchKeyword,
      });
    }
  };

  const handleSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };
  const handleSearch = () => {
    setAppliedSearchKeyword(searchKeyword);
    const locationKeyword = selectedLocation === '전체지역' ? '' : selectedLocation;
    navigate(`?${queryParamsURL(1, locationKeyword, searchKeyword)}`);
    fetchList({
      size: itemsPerPage,
      page: 1,
      location: locationKeyword,
      search: searchKeyword,
    });
  };

  const handleSelectLocation = ({ name }: { name: string }) => {
    setSelectedLocation(name);
    const locationKeyword = name === '전체지역' ? '' : name;
    navigate(`?${queryParamsURL(1, locationKeyword)}`);
    fetchList({
      size: itemsPerPage,
      page: 1,
      location: locationKeyword,
      search: appliedSearchKeyword,
    });
  };

  const fetchList = async (payload: Paging) => {
    const stationResponse = await getStations({ payload, cpo_seq });
    if (stationResponse?.isSuccess) {
      dispatch(
        actions.setStationList({
          list: stationResponse.data,
          totalCount: stationResponse.meta.paging?.total_count ?? NaN,
        }),
      );
    }
  };

  const handleRegister = () => {
    dispatch(actions.setStationMode(SCREEN_MODE.REGISTER));
    navigate(MENU.INFRA.STATION_MANAGEMENT_REGISTER);
  };

  const handleDeletePopup = (value: boolean) => {
    setDeletePopup(value);
  };

  const handleNoDeletePopup = (value: boolean) => {
    setNoDeletePopup(value);
  };
  const handleRemoveTargetId = (id: string) => {
    setRemoveTargetId(id);
  };
  const handleDeleteConfirm = async () => {
    try {
      if (removeTargetId) {
        const removeResponse = await removeStation(removeTargetId);
        if (removeResponse.isSuccess) {
          handleDeletePopup(false);
          const locationKeyword = selectedLocation === '전체지역' ? '' : selectedLocation;
          fetchList({
            size: itemsPerPage,
            page: currentPage,
            location: locationKeyword,
            search: appliedSearchKeyword,
          });
        }
      }
    } catch (e) {
      handleDeletePopup(false);
      handleNoDeletePopup(true);
    }
  };

  const handleExcelDownload = async () => {
    await getExcelFile({
      payload: {
        size: 10,
        page: 1,
        location: selectedLocation === '전체지역' ? '' : selectedLocation,
        search: appliedSearchKeyword,
      },
      cpo_seq,
      fileName: `충전소목록_${dayjs().format('YYYY_MM_DD')}.xlsx`,
    });
  };

  useEffect(() => {
    dispatch(actions.setInit());
    dispatch(actions.initPayload());
    dispatch(actions.initView());
    fetchList({
      size: itemsPerPage,
      page: currentPage,
      location: selectedLocation === '전체지역' ? '' : selectedLocation,
      search: appliedSearchKeyword,
    });
  }, []);

  useEffect(() => {
    setCurrentPage(Number(page));
  }, [page]);

  // TODO Sorting 기능: 현재 범위 삭제
  // const [sortTerms, setSortTerms] = React.useState({
  //   sort_element: '',
  //   sort_type: '',
  // });
  // const sortingHandler = ({ sort_element, sort_type }: { sort_element: SortElementType; sort_type: SortType }) => {
  //   setSortTerms({ sort_element, sort_type });
  //   fetch_list({
  //     size: itemsPerPage,
  //     page: currentPage,
  //     sort_element,
  //     sort_type,
  //     location: selectedLocation,
  //     search: searchKeyword,
  //   });
  // };

  return (
    <StationListPresenter
      onRegister={handleRegister}
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      pageHandler={pageHandler}
      // sortingHandler={sortingHandler}
      deletePopupVisible={deletePopup}
      noDeletePopupVisible={noDeletePopup}
      handleDelete={{ popup: handleDeletePopup, setId: handleRemoveTargetId, noDeletePopup: handleNoDeletePopup }}
      onDeleteConfirm={handleDeleteConfirm}
      searchKeyword={searchKeyword}
      handleSearchKeyword={handleSearchKeyword}
      handleSearch={handleSearch}
      selectedLocation={selectedLocation}
      handleSelectLocation={handleSelectLocation}
      handleExcelDownload={handleExcelDownload}
      queryString={queryParamsURL()}
    />
  );
}
