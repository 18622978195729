import React, { useEffect, useState } from 'react';
import { App, BackButton } from 'components';
import { useTranslation } from 'react-i18next';
import { addComma, calculateChargeDurationInMinutes, formatDateTime, Tab } from 'lib';
import { useAppSelector } from 'model';
import { ListKeyValue } from '@/components/form';
import {
  Header,
  ListWrapper,
  styleListKeyValue,
  SummaryWrapper,
  TableTitle,
  Wrapper,
} from './ChargingStatusDetail.styles';
import { displayNull } from '@/lib/util/displayData';
import { TariffUserType } from '@/lib/apis/tariff/type';
import { ChargingStatusPurchase } from '@/types/chargingStatus';

export default function ChargingStatusDetailPresenter({ handleBackToList }: { handleBackToList: () => void }) {
  const { view } = useAppSelector(state => state.chargingStatus);
  const { t } = useTranslation('common');
  const tabMenu = [
    { label: t('pages.sales.chargeDetailHistory') },
    { label: t('pages.deviceStatus.device.charger.title') },
  ];
  const [selected, setSelected] = useState(0);
  const [purchaseFilteredValues, setPurchaseFilteredValues] = useState({
    cancelAmountTotal: 0,
    chargingDuration: '-',
    chargingAmountTotal: 0,
    finalApprovedAmount: 0,
    chargingFinishedTime: '-',
    idTag: '-',
    userType: 3,
    pricePerkW: '-',
    preApprovalNumber: '-',
    chargingStartTime: '-',
    chargingEndTime: '-',
  });

  const renderMemberType = (type: number | undefined) => {
    if (type === undefined) {
      return '-';
    }
    if (type === TariffUserType.NONE) {
      return '비회원';
    }
    if (type === TariffUserType.USER) {
      return '회원';
    }
    return '회원';
    // return '로밍회원';
  };

  const getChargingDuration = () => {
    const filteredValue = {
      cancelAmountTotal: 0,
      chargingDuration: '-',
      chargingAmountTotal: 0,
      chargingFinishedTime: '-',
      idTag: '-',
      userType: 3,
      pricePerkW: '-',
      preApprovalNumber: '-',
      chargingStartTime: '-',
      chargingEndTime: '-',
    };
    for (let i = 0; i < view.purchases.length; i += 1) {
      const purchase = view.purchases[i];
      if (purchase.status === 'COMPLETED') {
        filteredValue.chargingStartTime = purchase.created_at;
        filteredValue.chargingEndTime = purchase.last_modified_at;
        filteredValue.chargingDuration = calculateChargeDurationInMinutes(
          purchase.created_at,
          purchase.last_modified_at,
        ); // 충전시간
        filteredValue.chargingAmountTotal = parseFloat(purchase.amount); // 충전요금
        filteredValue.chargingFinishedTime = purchase.last_modified_at;
        filteredValue.idTag = purchase.id_tag;
        filteredValue.userType = purchase.user_type;
        filteredValue.pricePerkW = purchase.price_per_kw;
        filteredValue.preApprovalNumber = purchase.approval_number;
      }
      if (purchase.status === 'CANCEL') {
        filteredValue.cancelAmountTotal += parseFloat(purchase.amount);
      }
    }
    setPurchaseFilteredValues({
      ...filteredValue,
      finalApprovedAmount: filteredValue.chargingAmountTotal - filteredValue.cancelAmountTotal,
    });
  };

  const getChargingAmountkWh = () => {
    return ((view.meter_last - view.meter_start) * 0.001).toFixed(2);
  };

  useEffect(() => {
    getChargingDuration();
  }, [view.purchases]);

  return (
    <App>
      <Header>
        <BackButton label={view ? view.connector.chargepoint.station.name : ''} onClick={handleBackToList} />
      </Header>
      <main className="inc_form" style={{ width: 780 }}>
        <Tab
          selected={selected}
          style={{ marginBottom: 32 }}
          info={tabMenu}
          onChange={({ index }) => {
            setSelected(index);
          }}
        />
      </main>
      <Wrapper>
        {view && selected === 0 && (
          <SummaryWrapper>
            <TableTitle>{t('pages.sales.chargeDetailHistory')}</TableTitle>
            <ListWrapper>
              <ListKeyValue
                title={t('forms.sales.chargingID')}
                value={view.transaction_id}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.sales.customerType')}
                value={renderMemberType(purchaseFilteredValues.userType)}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.sales.IDTag')}
                value={purchaseFilteredValues.idTag}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={`${t('forms.sales.rate')}${t('common.unit.brackets.priceWonBykWh')}`}
                value={purchaseFilteredValues.pricePerkW}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.sales.startSOC')}
                value={displayNull(view.start_soc)}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.sales.preApprovalNumber')}
                value={purchaseFilteredValues.preApprovalNumber}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={`${t('forms.sales.preApprovalPrice')}${t('common.unit.brackets.priceWon')}`}
                value={addComma(purchaseFilteredValues.chargingAmountTotal)}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.sales.dateStartCharging')}
                value={formatDateTime(purchaseFilteredValues.chargingStartTime)}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.sales.endSOC')}
                value={displayNull(view.end_soc)}
                valueStyle={styleListKeyValue}
              />
              {/* <ListKeyValue title={t('forms.sales.endReason')} value={view.code} valueStyle={styleListKeyValue} /> */}
              {/* <ListKeyValue title={t('forms.sales.endReasonDetail')} value={view.code} valueStyle={styleListKeyValue} /> */}
              <ListKeyValue
                title={t('forms.sales.chargingPeriod')}
                value={calculateChargeDurationInMinutes(
                  purchaseFilteredValues.chargingStartTime,
                  purchaseFilteredValues.chargingEndTime,
                )}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={`${t('forms.sales.chargingAmount')}${t('common.unit.brackets.kWh')}`}
                value={getChargingAmountkWh()}
                valueStyle={styleListKeyValue}
              />
              {/* <ListKeyValue */}
              {/*  title={`${t('forms.sales.avgChargingSpeed')}${t('common.unit.brackets.kW')}`} */}
              {/*  value={view.code} */}
              {/*  valueStyle={styleListKeyValue} */}
              {/* /> */}
              <ListKeyValue
                title={`${t('forms.sales.chargingPrice')}${t('common.unit.brackets.priceWon')}`}
                value={addComma(purchaseFilteredValues.chargingAmountTotal)}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={`${t('forms.sales.cancelPrice')}${t('common.unit.brackets.priceWon')}`}
                value={addComma(purchaseFilteredValues.cancelAmountTotal)}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.sales.approvalNumber')}
                value={view.purchases.map((item: ChargingStatusPurchase) => `${item.approval_number}\n`)}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={`${t('forms.sales.approvalPrice')}${t('common.unit.brackets.priceWon')}`}
                value={addComma(purchaseFilteredValues.finalApprovedAmount)}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.sales.dateEndCharging')}
                value={formatDateTime(purchaseFilteredValues.chargingEndTime)}
                valueStyle={styleListKeyValue}
              />
              {/* <ListKeyValue
                title={t('forms.sales.paymentTID')}
                value={view.purchases.map((item: ChargingStatusPurchase) => `${item?.response?.Tid ?? '-'}\n`)}
                valueStyle={styleListKeyValue}
              /> */}
              {/* <ListKeyValue */}
              {/*  title={t('forms.sales.requirementApproval')} */}
              {/*  value={view.code} */}
              {/*  valueStyle={styleListKeyValue} */}
              {/* /> */}
            </ListWrapper>
          </SummaryWrapper>
        )}
        {view && selected === 1 && (
          <SummaryWrapper>
            <TableTitle>{t('pages.deviceStatus.device.charger.title')}</TableTitle>
            <ListWrapper>
              <ListKeyValue
                title={t('forms.charger.name')}
                value={view.connector.chargepoint.station.name}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.charger.stationId')}
                value={view.connector.chargepoint.station.code}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.model.chargerName')}
                value={view.connector.chargepoint.name}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.charger.chargerId')}
                value={view.connector.chargepoint.code}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.charger.connectorId')}
                value={view.connector.code}
                valueStyle={styleListKeyValue}
              />
              <ListKeyValue
                title={t('forms.model.typeOfConnectors')}
                value={`${t(`common.connector.${view.connector.types}`)}`}
                valueStyle={styleListKeyValue}
              />
            </ListWrapper>
          </SummaryWrapper>
        )}
      </Wrapper>
    </App>
  );
}
