import { Button, Events, H2, Image, Text, useRoutes } from 'lib';
import styled from 'styled-components';
import { COLOR, ui } from 'styles';
import {
  FormCheckbox,
  PopupContainer,
  PopupCreate,
  SubmitButton,
  TextFiledLabel,
  TextInput,
  TextInputPassword,
} from 'components/form';
import { useTranslation } from 'react-i18next';
import AuthScreenLayout from '@/components/layout/auth';
import { auth_logo, auth_screen_sign_up } from '@/assets/images';
import { MENU } from '@/pages/router';

/**
 * @name 회원가입
 * @description
 */
export default function Register({
  onChangeHandler,
  popupVisible,
  setPopupVisible,
  title,
  message,
  validEmail,
  onSubmit,
  isAgree,
  setIsAgree,
}: {
  onChangeHandler: (k: string, value: string) => void;
  popupVisible: boolean;
  setPopupVisible: (flag: boolean) => void;
  navigate: (str: string) => void;
  title: string;
  message: string;
  validEmail: string;
  onSubmit: () => void;
  isAgree: boolean;
  setIsAgree: (flag: boolean) => void;
}) {
  const { t } = useTranslation('common');
  const { navigate } = useRoutes();

  // const [isAgree, setIsAgree] = useState(false);

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <AuthScreenLayout visual={<Image src={auth_screen_sign_up} />}>
      <HeaderWrapper>
        <header>
          <Image className="logo" src={auth_logo} />
          <HeaderTitle>
            <H2 color={COLOR.GRAY1}>ACELO EV</H2>
            <Text color={COLOR.GRAY2} className="body4">
              {t('pages.auth.aceloEvDescription')} 🚀
            </Text>
          </HeaderTitle>
        </header>
      </HeaderWrapper>
      <Content>
        <div className="wrap">
          <TextFiledLabel label={t('pages.auth.email')} />
          <TextInput
            style={{ marginBottom: 16, color: COLOR.GRAY2 }}
            placeholder={t('pages.auth.emailPlaceholder')}
            disabled
            defaultValue={validEmail}
          />
          <TextFiledLabel label={t('pages.auth.setPassword')} required />
          <TextInputPassword
            maxLength={20}
            style={{ marginBottom: 4, color: COLOR.GRAY2 }}
            onValue={str => {
              onChangeHandler('pw', str);
            }}
            placeholder={t('pages.auth.passwordPlaceholder')}
            className={ui.input}
          />
          <Text color={COLOR.GRAY3} className="caption1" style={{ display: 'block', marginBottom: 16 }}>
            {t('pages.auth.passwordRule')}
          </Text>
          <TextFiledLabel label={t('pages.auth.setPasswordPlaceholder')} required />
          <TextInputPassword
            style={{ marginBottom: 16, color: COLOR.GRAY2 }}
            onValue={str => {
              onChangeHandler('confirm', str);
            }}
            placeholder={t('pages.auth.password2')}
            className={ui.input}
            type="password"
          />
          <div className="agree">
            <FormCheckbox
              value={isAgree}
              style={{ marginRight: 6 }}
              onChange={(val: boolean) => {
                setIsAgree(val);
              }}
            />
            <div className="body4">
              <button
                type="button"
                className="extra"
                onClick={() => {
                  navigate(MENU.POLICY_PRIVACY, { state: { mode: 'register', path: MENU.POLICY_PRIVACY, active: 0 } });
                }}>
                {t('pages.terms.terms')}
              </button>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>&</span>
              <button
                type="button"
                className="extra"
                onClick={() => {
                  navigate(MENU.POLICY_PRIVACY, { state: { mode: 'register', path: MENU.POLICY_PRIVACY, active: 1 } });
                }}>
                {t('pages.terms.agreePrivacyPolicy.sentence1')}
              </button>
              {t('pages.terms.agreePrivacyPolicy.sentence2')}
            </div>
          </div>
          <Button full style={{ height: 40, marginBottom: 16 }} onClick={() => onSubmit()}>
            <span className="button3">{t('pages.auth.goRegister')}</span>
          </Button>
          <footer>
            <p className="body4">
              <span>
                {t('pages.auth.alreadyRegistered')}
                <button
                  type="button"
                  onClick={() => {
                    navigate(MENU.AUTH.SIGN_IN);
                  }}>
                  {t('pages.auth.goToLogin')}
                </button>
              </span>
            </p>
          </footer>
        </div>
      </Content>
      <PopupContainer visible={popupVisible} onClose={closePopup}>
        <PopupCreate
          warning
          title={title}
          message={message}
          footer={<SubmitButton warning label={t('common.buttons.submit')} onConfirm={closePopup} />}
        />
      </PopupContainer>
    </AuthScreenLayout>
  );
}
const Content = styled.div`
  width: 320px;
  /* agree */
  .agree {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .extra {
      color: ${COLOR.BLUE4};
    }
  }
  /* footer */
  footer {
    p {
      display: flex;
      margin-bottom: 16px;
      justify-content: center;
      color: ${COLOR.GRAY1};
    }
    button {
      padding-left: 10px;
      color: ${COLOR.BLUE4};
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 320px;
  margin-bottom: 20px;
  flex-direction: column;

  .logo {
    display: block;
    width: 56px !important;
    height: 48px !important;
    margin: 0 auto 20px;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
