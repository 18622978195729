import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLOR } from 'styles';
import { H2, H5, H6, Image, Row, addComma } from 'lib';
import { dashboard_widget_charge1, dashboard_widget_charge2 } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { Panel } from 'components';
import TotalCount from '@/components/atoms/Labels/TotalCount';
import { getUses } from '@/lib/apis/dashborad';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { actions } from '@/model/modules/dashboard';

/**
 * @name 충전기이용횟수
 * @description
 */
export default function ChargerUsageCountStats() {
  const { t } = useTranslation('common');
  const { uses } = useAppSelector(state => state.dashboard);
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    const { isSuccess, data } = await getUses({ type: 1 });
    if (isSuccess) {
      dispatch(actions.setUses(data));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Panel mainTitle="충전기 이용횟수">
      <Content>
        {/* 본문내용 */}
        <main>
          <TotalCount comma value={uses.total} suffix="회" />
          <Row>
            <div className="item">
              <Image src={dashboard_widget_charge1} style={{ width: 96, height: 96 }} />
              <H5 color={COLOR.GRAY1}>{t('pages.dashboard.rapid')}</H5>
              <Row className="row" style={{ alignItems: 'flex-end' }}>
                <H2 style={{ marginRight: 3 }}>{addComma(uses.fast)}</H2>
                <H6 color={COLOR.GRAY2}>{t('common.unit.times')}</H6>
              </Row>
            </div>
            {/* <div className="item">
              <Image src={dashboard_widget_charge2} style={{ width: 96, height: 96 }} />
              <H5 color={COLOR.GRAY1}>{t('pages.dashboard.standard')}</H5>
              <Row className="row" style={{ alignItems: 'flex-end' }}>
                <H2 style={{ marginRight: 3 }}>{addComma(uses.slow)}</H2>
                <H6 color={COLOR.GRAY2}>{t('common.unit.times')}</H6>
              </Row>
            </div> */}
          </Row>
        </main>
      </Content>
    </Panel>
  );
}
const Content = styled.div`
  /* 본문 */
  main {
    padding-top: 10px;
    .item {
      display: flex;
      margin-top: 10px;
      flex: 1;
      flex-direction: column;
      align-items: center;
    }
    /* row */
    .row {
      margin-top: 10px;
    }
  }
`;
