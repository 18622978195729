import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { addComma, Button, Tab } from 'lib';
import { useAppDispatch, useAppSelector } from 'model';
import { COLOR } from '@/styles/index';
import typography from '@/styles/typography';
import { GetTariffRes, TariffUserType } from '@/lib/apis/tariff/type';
import Badge from '@/components/atoms/Badge';
import { displayPrice } from '@/lib/util/displayData';
import { actions } from '@/model/modules/price';
import { SCREEN_MODE } from '@/types/mode';
import { MENU } from '@/pages/router';
import Pagination from '../../Paginations/Pagination';

export default function FixedRateTable({
  itemsPerPage,
  currentPage,
  pageHandler,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
}) {
  const { t } = useTranslation('common');
  const { list, roamingList } = useAppSelector(state => state.price);
  const { dataList, totalCount } = roamingList;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tabMenu = [{ label: t('pages.price.chargingUnitPrice.title') }, { label: t('pages.price.roamingPrice.title') }];
  const [selected, setSelected] = useState(0);

  const renderBadgeByMemberType = (type: TariffUserType) => {
    if (type === TariffUserType.NONE) {
      return <Badge mode="TariffNone" label={t('forms.price.member.none')} />;
    }
    if (type === TariffUserType.USER) {
      return <Badge mode="TariffUser" label={t('forms.price.member.user')} />;
    }
    return <Badge mode="TariffRoaming" label={t('forms.price.member.roaming')} />;
  };

  const renderApplicationTimeByDaycode = (dayCode: string) => {
    // day code에 맞게 세분화
    return '00:00 ~ 24:00';
  };

  const navigateEdit = () => {
    dispatch(actions.setMode(SCREEN_MODE.MODIFY));
    navigate(MENU.RATE.CHARGING_RATE_UPDATE);
  };

  const navigateRegister = () => {
    dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    navigate(MENU.RATE.CHARGING_RATE_UPDATE);
  };

  const navigateRoamingRegister = () => {
    dispatch(actions.setMode(SCREEN_MODE.REGISTER));
    navigate(MENU.RATE.CHARGING_RATE_ROAMING_REGISTER);
  };

  const navigateRoamingDetail = (id: number) => {
    navigate(`${MENU.RATE.CHARGING_RATE_ROAMING}/${id}`);
  };

  return (
    <Wrapper>
      {/* <TabWrapper>
        <TabContainer>
          <Tab
            selected={selected}
            info={tabMenu}
            onChange={({ index }) => {
              setSelected(index);
            }}
          />
        </TabContainer>
      </TabWrapper> */}
      {selected === 0 && (
        <>
          <Header>
            <HeaderText>{t('pages.price.chargingUnitPrice.title')}</HeaderText>
            <Button disabled={list.length === 0} label={t('common.buttons.updateAction')} onClick={navigateEdit} />
          </Header>
          <Content>
            {list.length > 0 ? (
              <>
                <Row>
                  <TableHeader>{t('forms.price.customerType')}</TableHeader>
                  <TableHeader>{t('forms.price.applyMoment')}</TableHeader>
                  <TableHeader>
                    {t('forms.price.rapid')}
                    {t('common.unit.brackets.priceWonBykWh')}
                  </TableHeader>
                  {/* <TableHeader>
                    {t('forms.price.standard')}
                    {t('common.unit.brackets.priceWonBykWh')}
                  </TableHeader> */}
                </Row>
                {list.map((tariff: GetTariffRes) => (
                  <Row key={tariff.seq}>
                    <Cell>{renderBadgeByMemberType(tariff.type)}</Cell>
                    <Cell>{renderApplicationTimeByDaycode(tariff.day_code)}</Cell>
                    <Cell>{`${addComma(displayPrice(tariff.fast_amount))}${t(
                      `common.currency.${tariff.currency}`,
                    )}`}</Cell>
                    {/* <Cell>{`${addComma(displayPrice(tariff.slow_amount))}${t(
                      `common.currency.${tariff.currency}`,
                    )}`}</Cell> */}
                  </Row>
                ))}
              </>
            ) : (
              <NoFixedRate>
                <NoFixedRateTitle>{t('pages.price.chargingUnitPrice.noDataFixedRateTitle')}</NoFixedRateTitle>
                <DescriptionToSetRate>{t('pages.price.chargingUnitPrice.pleaseSetYourRate')}</DescriptionToSetRate>
                <Button label={t('pages.price.chargingUnitPrice.setPrice')} onClick={navigateRegister} />
              </NoFixedRate>
            )}
          </Content>
        </>
      )}
      {selected === 1 && (
        <>
          <Header>
            <HeaderText>{t('pages.price.roamingPrice.title')}</HeaderText>
            <Button label={t('common.buttons.registerAction')} onClick={navigateRoamingRegister} />
          </Header>
          <Content>
            {dataList.length > 0 ? (
              <>
                <Row>
                  <TableHeader>{t('pages.price.roamingPrice.roamingCode')}</TableHeader>
                  <TableHeader>{t('pages.price.roamingPrice.roamingCompanyName')}</TableHeader>
                  <TableHeader>{t('pages.price.roamingPrice.startKW')}</TableHeader>
                  <TableHeader>{t('pages.price.roamingPrice.endKW')}</TableHeader>
                  <TableHeader>
                    {t('pages.price.roamingPrice.price')}
                    {t('common.unit.brackets.priceWonBykWh')}
                  </TableHeader>
                </Row>
                {/* TODO Roaming List의 단건 타입 설정 */}
                {dataList.map((tariff: GetTariffRes) => (
                  <Row key={tariff.seq}>
                    <Cell>
                      {/* TODO 상세화면 이동 파라미터 ID로 수정 */}
                      <InnerCellTextButton onClick={() => navigateRoamingDetail(tariff.seq)}>
                        {tariff.roaming_code}
                      </InnerCellTextButton>
                    </Cell>
                    <Cell>{tariff.roaming_name}</Cell>
                    <Cell>{tariff.start_kw}</Cell>
                    <Cell>{tariff.end_kw}</Cell>
                    <Cell>{tariff.price}</Cell>
                  </Row>
                ))}
              </>
            ) : (
              <NoFixedRate>
                <NoFixedRateTitle>{t('pages.price.roamingPrice.noDataFixedRateTitle')}</NoFixedRateTitle>
                <DescriptionToSetRate>{t('pages.price.chargingUnitPrice.pleaseSetYourRate')}</DescriptionToSetRate>
                <Button label={t('pages.price.roamingPrice.setRoamingPrice')} onClick={navigateRoamingRegister} />
              </NoFixedRate>
            )}
            {list.length > 0 && (
              <Pagination
                listLength={totalCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onClick={pageHandler}
              />
            )}
          </Content>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 8px;
  background-color: ${COLOR.WHITE};
  box-shadow: 0px 6px 10px 0px rgba(6, 25, 56, 0.07);
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
`;

const HeaderText = styled.p`
  ${typography.headline5};
  color: ${COLOR.BLACK3};
`;

const Content = styled.div`
  padding: 16px;
`;

const NoFixedRate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin: 48px 0;
`;

const NoFixedRateTitle = styled.p`
  ${typography.headline3};
  color: ${COLOR.GRAY1};
`;

const DescriptionToSetRate = styled.p`
  ${typography.headline5};
  color: ${COLOR.GRAY2};
  margin-bottom: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableHeader = styled.div`
  flex: 1;
  padding: 14px 8px;
  background-color: ${COLOR.BLUE8};
  border-bottom: 1px solid ${COLOR.BLUE_GRAY9};
  text-align: center;
  ${typography.headline6};
  color: ${COLOR.GRAY1};
`;
export const Cell = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  flex: 1;
  text-align: center;
  ${typography.body4};
  color: ${COLOR.BLACK3};
`;
const TabWrapper = styled.div`
  border-radius: 8px;
  padding: 16px;
`;

const TabContainer = styled.div`
  max-width: 600px;
  margin: auto;
`;

export const InnerCellTextButton = styled.span`
  cursor: pointer;
  color: ${COLOR.BLUE4};
  &:hover {
    color: ${COLOR.BLUE1};
  }
  &:active {
    color: ${COLOR.BLUE6};
  }
`;
