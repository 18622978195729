import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { Dayjs, Image } from 'lib';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/model/index';
import {
  Wrapper,
  TableBody,
  Table,
  TableHead,
  TH,
  TD,
  TR,
  InnerCellTextButton,
  InnerCellContainer,
  NoResultText,
  NoResultTextWrapper,
  NoSearchResultTextWrapper,
  NoSearchResultWrapper,
} from './TroubleReports.style';
import type { TroubleReport } from '@/lib/apis/operation/trouble-reports/type';
import typography from '@/styles/typography';
import Pagination from '@/components/organisms/Paginations/Pagination';
import { MENU } from '@/pages/router';
import { COLOR } from '@/styles/index';
import { icons } from '@/assets/images';

export default function TroubleReports({
  itemsPerPage,
  currentPage,
  pageHandler,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
}) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { list, totalCount } = useAppSelector(state => state.troubleReports);

  const handleNavigateToDetail = (seq: number) => {
    navigate(`${MENU.OPERATION.TROUBLE_REPORTS}/${seq}`);
  };

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 44 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 180 }} />
          <col style={{ minWidth: 180 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 80 }} />
          <col style={{ minWidth: 150 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('pages.operation.troubleReports.list.table.chargepoint.model.name')}</TH>
            <TH>{t('pages.operation.troubleReports.list.table.chargepoint.chargepointCode')}</TH>
            <TH>{t('pages.operation.troubleReports.list.table.connectorID')}</TH>
            <TH>{t('pages.operation.troubleReports.list.table.status')}</TH>
            <TH>{t('pages.operation.troubleReports.list.table.title')}</TH>
            <TH>{t('pages.operation.troubleReports.list.table.userMemo')}</TH>
            <TH>{t('pages.operation.troubleReports.list.table.adminMemo')}</TH>
            <TH>{t('pages.operation.troubleReports.list.table.roleCode')}</TH>
            <TH>{t('pages.operation.troubleReports.list.table.date')}</TH>
          </TR>
        </TableHead>
        <TableBody>
          {list?.length > 0 &&
            list.map((item: TroubleReport, index: number) => (
              <TR key={nanoid()}>
                <TD $customFont={typography.body4}>{item.row_num}</TD>
                {/* <TD $customFont={typography.body4}>{totalCount - (currentPage - 1) * itemsPerPage - index}</TD> */}
                <TD $customFont={typography.body5}>{item.chargepoint.model.name}</TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item?.seq)}>
                      {item?.chargepoint.code}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body5}>{item.connector_number}</TD>
                <TD $customFont={typography.body5}>{item.status}</TD>
                <TD $customFont={typography.body5}>{item.title}</TD>
                <TD style={{ maxWidth: 200, overflowWrap: 'break-word' }} $customFont={typography.body5}>
                  {item.user_memo}
                </TD>
                <TD $customFont={typography.body5}>{item.admin_memo}</TD>
                <TD $customFont={typography.body5}>{item.role_code}</TD>
                <TD $customFont={typography.body5}>{Dayjs(item.created_at).format('YYYY.MM.DD HH:mm:ss')}</TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
      {list.length === 0 && (
        <NoSearchResultWrapper>
          <Image src={icons.noSalesData.image.default} style={{ width: 192, height: 192 }} />
          <NoSearchResultTextWrapper>
            <NoResultTextWrapper>
              <NoResultText>{t('pages.operation.troubleReports.list.description.noData')}</NoResultText>
            </NoResultTextWrapper>
          </NoSearchResultTextWrapper>
        </NoSearchResultWrapper>
      )}
    </Wrapper>
  );
}
