import React, { useState } from 'react';
import styled from 'styled-components';
import { COLOR, ui } from 'styles';
import { auth_logo, auth_screen_sign_in } from 'assets/images';
import { Button, H2, H5, Image, Text, useRoutes } from 'lib';
import { useTranslation } from 'react-i18next';
import AuthScreenLayout from '@/components/layout/auth';
import { PopupContainer, PopupCreate, SubmitButton, TextInput, TextInputPassword } from '@/components/form';
import { MENU } from '@/pages/router';
import { ILoginInfo, SignInProps } from './type';

/**
 * @name SignIn
 * @description
 */
export default function Presenter({ onSignIn, popupVisible, setPopupVisible, failedReason }: SignInProps) {
  const { t } = useTranslation('common');
  const [loginInfoState, setLoginInfoState] = useState<ILoginInfo>({
    email: '',
    password: '',
  });
  const { navigate } = useRoutes();

  const popupMessages = {
    id: {
      title: t('common.toast.failedEmail'),
      message: t('common.toast.checkYourEmailAndTryAgain'),
    },
    password: {
      title: t('common.toast.failedLogin'),
      message: t('common.toast.failedPassword'),
    },
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <AuthScreenLayout visual={<Image src={auth_screen_sign_in} />}>
      <HeaderWrapper>
        <header>
          <Image className="logo" src={auth_logo} />
          <HeaderTitle>
            <H2 color={COLOR.GRAY1}>ACELO EV</H2>
            <Text color={COLOR.GRAY2} className="body4">
              {t('pages.auth.aceloEvDescription')} 🚀
            </Text>
          </HeaderTitle>
        </header>
      </HeaderWrapper>
      <Content>
        <div className="wrap">
          {/* {debug(loginInfoState)} */}
          <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1}>
            {t('pages.auth.email')}
          </H5>
          <TextInput
            style={{ marginBottom: 16 }}
            placeholder={t('pages.auth.emailPlaceholder')}
            onValue={str => {
              setLoginInfoState({ ...loginInfoState, email: str });
            }}
            onSubmit={str => {
              setLoginInfoState({ ...loginInfoState, email: str });
            }}
          />
          <H5 style={{ marginBottom: 4 }} color={COLOR.GRAY1}>
            {t('pages.auth.password')}
          </H5>
          <TextInputPassword
            style={{ marginBottom: 16, color: COLOR.GRAY2 }}
            onValue={str => {
              setLoginInfoState({ ...loginInfoState, password: str });
            }}
            onSubmit={str => {
              setLoginInfoState({ ...loginInfoState, password: str });
            }}
            placeholder={t('pages.auth.passwordPlaceholder')}
            className={ui.input}
            type="password"
          />
          <Button
            full
            disabled={loginInfoState.email === '' || loginInfoState.password === ''}
            style={{ height: 40, marginBottom: 16 }}
            onClick={() => {
              onSignIn(loginInfoState);
            }}>
            <span className="button3">{t('pages.auth.start')}</span>
          </Button>
          <footer>
            <p className="body4">
              <span>
                {t('pages.auth.forgotPassword')}
                <button
                  type="button"
                  onClick={() => {
                    navigate(MENU.AUTH.FORGOT_PASSWORD);
                  }}>
                  {t('pages.auth.findPassword')}
                </button>
              </span>
            </p>
            {/* <p className="body4">
              <span>
                {t('pages.auth.noAccount')}
                <button
                  type="button"
                  onClick={() => {
                    navigate(MENU.AUTH.REGISTER);
                  }}>
                  {t('pages.auth.register')}
                </button>
              </span>
            </p> */}
          </footer>
        </div>
      </Content>
      <PopupContainer visible={popupVisible} onClose={closePopup}>
        <PopupCreate
          warning
          title={'로그인에 실패 하였습니다.'}
          message={'사용자 ID 또는 패스워드가 잘못되었습니다.'}
          footer={<SubmitButton warning label={t('common.buttons.submit')} onConfirm={closePopup} />}
        />
      </PopupContainer>
    </AuthScreenLayout>
  );
}

const Content = styled.div`
  width: 320px;
  /* footer */
  footer {
    p {
      display: flex;
      margin-bottom: 16px;
      justify-content: center;
      color: ${COLOR.GRAY1};
    }
    button {
      padding-left: 10px;
      color: ${COLOR.BLUE4};
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 320px;
  margin-bottom: 20px;
  flex-direction: column;

  .logo {
    display: block;
    width: 56px !important;
    height: 48px !important;
    margin: 0 auto 20px;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
