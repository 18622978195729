import { useEffect, useState } from 'react';
import { App, BackButton } from 'components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button, Textarea } from 'lib';
import { COLOR } from '@/styles/index';
import {
  Header,
  ListWrapper,
  SummaryWrapper,
  TableTitle,
  styleListKeyValue,
  Wrapper,
  CustomGap,
  SubmitButtonsWrapper,
  ButtonWrapper,
} from './TroubleReportsEdit.styles';
import { ListKeyValue, PopupContainer, PopupCreate, SubmitCancel } from '@/components/form';

import { TroubleReport } from '@/lib/apis/operation/trouble-reports/type';
import Dropdown from '@/components/molecules/Dropdown';

const statuses = [
  { value: 'REPORTED', name: 'REPORTED', id: '1' },
  { value: 'CHECKING', name: 'CHECKING', id: '2' },
  { value: 'FIXING', name: 'FIXING', id: '3' },
  { value: 'FIXED', name: 'FIXED', id: '4' },
  { value: 'NOISSUE', name: 'NOISSUE', id: '5' },
];
export default function TroubleReportsEditPresenter({
  onBackToList,
  troubleReport,
  onCancelPopup,
  onCloseCancelPopup,
  onConfirmCancelPopup,
  onSubmit,
  onCloseFailedPopup,
  onCloseSuccessPopup,
  popupSuccessVisible,
  popupFailedVisible,
  popupCancelVisible,
}: {
  onBackToList: () => void;
  troubleReport: TroubleReport | null;
  onCancelPopup: () => void;
  popupCancelVisible: boolean;
  onCloseCancelPopup: () => void;
  onConfirmCancelPopup: () => void;
  onSubmit: (forms: { admin_memo: string; status: string }) => void;
  onCloseFailedPopup: () => void;
  onCloseSuccessPopup: () => void;
  popupSuccessVisible: boolean;
  popupFailedVisible: boolean;
}) {
  const [selectedStatus, setSelectedStatus] = useState(statuses.find(i => i.value === troubleReport?.status));
  const [forms, setForms] = useState({
    admin_memo: '',
    status: '',
  });

  const onAdminMemoChangeHandler = (value: string) => {
    setForms(prevState => ({
      ...prevState,
      admin_memo: value,
    }));
  };

  const onStatusChangeHandler = (value: string) => {
    setSelectedStatus(statuses.find(i => i.value === value));
    setForms(prevState => ({
      ...prevState,
      status: value,
    }));
  };

  const { t } = useTranslation('common');

  useEffect(() => {
    if (troubleReport) {
      setForms({
        admin_memo: troubleReport.admin_memo || '',
        status: troubleReport.status || '',
      });
      setSelectedStatus(statuses.find(i => i.value === troubleReport.status));
    }
  }, [troubleReport]);

  return (
    <App>
      <Header style={{ width: '100%' }}>
        <BackButton label={t('pages.operation.troubleReports.detail.title')} onClick={onBackToList} />
      </Header>
      <Wrapper>
        <SummaryWrapper>
          <TableTitle>{t('pages.operation.troubleReports.detail.dataTitle')}</TableTitle>
          <ListWrapper>
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.title')}
              value={troubleReport?.title}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.userMemo')}
              value={troubleReport?.user_memo || '-'}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.adminMemo')}
              component={
                <Textarea
                  value={forms.admin_memo}
                  style={{ maxWidth: 500 }}
                  placeholder={t('forms.troubleReports.adminMemoPlaceholder')}
                  name="name"
                  onChange={onAdminMemoChangeHandler}
                  maxLength={255}
                />
              }
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.status')}
              valueStyle={styleListKeyValue}
              component={
                <Dropdown
                  optionContainerWidth={250}
                  wrapperStyle={{ minWidth: 134 }}
                  selectBoxName="상태"
                  dropdownOptions={statuses}
                  defaultValue={selectedStatus}
                  placeholder={t('common.buttons.troubleReportStatusSelect')}
                  selectField="name"
                  onChangeSelectOption={({ value }) => onStatusChangeHandler(value)}
                />
              }
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.remoteData')}
              value={troubleReport?.remote_data ? JSON.stringify(troubleReport?.remote_data, null, 2) : '-'}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.createdAt')}
              value={dayjs(troubleReport?.created_at).format('YYYY.MM.DD HH:mm:ss')}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.roleCode')}
              value={troubleReport?.role_code}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.chargepoint.code')}
              value={troubleReport?.chargepoint.code}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.chargepoint.station.name')}
              value={troubleReport?.chargepoint.station.name}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.chargepoint.model.name')}
              value={troubleReport?.chargepoint.model.name}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.chargepoint.model.chargerType')}
              value={troubleReport?.chargepoint.model.charger_type}
              valueStyle={styleListKeyValue}
            />
            <ListKeyValue
              title={t('pages.operation.troubleReports.detail.info.chargepoint.model.vendor.name')}
              value={troubleReport?.chargepoint.model.vendor.name}
              valueStyle={styleListKeyValue}
            />
          </ListWrapper>
        </SummaryWrapper>
        <CustomGap />
        <SubmitButtonsWrapper>
          <ButtonWrapper>
            <Button
              full
              label={t('common.buttons.cancel')}
              color={COLOR.GRAY2}
              borderColor={COLOR.GRAY2}
              bg="transparent"
              onClick={onCancelPopup}
            />
            <Button full label={t('common.buttons.updateAction')} onClick={() => onSubmit(forms)} />
          </ButtonWrapper>
        </SubmitButtonsWrapper>
      </Wrapper>
      <PopupContainer visible={popupSuccessVisible} onClose={onCloseSuccessPopup}>
        <PopupCreate
          title={t('common.toast.successToModifyTroubleReportsTitle')}
          message={t('common.toast.successToModifyTroubleReportsMessage')}
          onConfirm={onCloseSuccessPopup}
        />
      </PopupContainer>
      <PopupContainer visible={popupCancelVisible} onClose={onCloseCancelPopup}>
        <PopupCreate
          warning
          title={t('common.toast.cancelUpdateTroubleReports')}
          message={t('common.toast.askCancel')}
          footer={
            <SubmitCancel
              confirmLabel={t('common.buttons.cancelUpdateAction')}
              onCancel={onCloseCancelPopup}
              onConfirm={onConfirmCancelPopup}
            />
          }
        />
      </PopupContainer>
      <PopupContainer visible={popupFailedVisible} onClose={onCloseFailedPopup}>
        <PopupCreate
          warning
          title={t('common.toast.failedUpdateTroubleReportTitle')}
          message={t('common.toast.failedUpdateTroubleReportMessage')}
          onConfirm={onCloseFailedPopup}
        />
      </PopupContainer>
    </App>
  );
}
