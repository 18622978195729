import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { APIMethod, IRequest, IResponse } from './type';
import { COOKIE } from '@/lib/const';
import { log } from '@/lib/util';
import Cookies from 'js-cookie';

const errorMessage = (params: AxiosError) => {
  if (params.config) {
    log(`====Error====`, 'padding:5px 10px;font-size:14px;background:#111;color:red;');
    log(params.config.url ?? '', `font-size:10px; color:#888;`);
    log(params?.config);
  }
};
const buildHeaders = (headers: { [key: string]: string } = {}) => {
  if (headers['Content-Type'] === 'multipart/form-data') {
    return {
      Accept: 'application/json',
      ...headers,
    };
  }

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  };
};

const thenResponse = (response: AxiosResponse): Promise<IResponse> => {
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve(response.data);
  }

  return Promise.reject(response.data);
};

const thenBlobResponse = (response: AxiosResponse<Blob>, fileName: string): Promise<Blob> => {
  if (response.status === 200 || response.status === 201) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    return Promise.resolve(response.data);
  }

  return Promise.reject(response.data);
};

const catchResponse = (error: AxiosError): Promise<IResponse> => {
  errorMessage(error);
  const data: IResponse = error.response?.data as IResponse;
  return Promise.reject(data);
};

const Request = ({
  method,
  url,
  headers,
  payload,
  optionOverride = {},
  useParams,
  responseType,
  fileName,
}: APIMethod) => {
  const option: AxiosRequestConfig = {
    url,
    method,
    headers: buildHeaders(headers),
    timeout: 10000,
    withCredentials: false,
    responseType: responseType ?? 'json',
  };

  if (method === 'get') {
    option.params = payload;
  }

  if (['post', 'patch', 'put', 'delete'].includes(method)) {
    if (useParams) {
      option.params = payload;
    } else {
      option.data = payload;
    }
  }
  const accessToken = Cookies.get(COOKIE.ACCESS_TOKEN);
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return axios({ ...option, ...optionOverride })
    .then(response => {
      if (responseType === 'blob' && fileName) {
        thenBlobResponse(response, fileName);
      }
      return thenResponse(response);
    })
    .catch(catchResponse);
};

const api = {
  get: ({ url, headers, optionOverride, payload, responseType, fileName }: IRequest) => {
    return Request({
      method: 'get',
      url,
      headers,
      optionOverride,
      payload,
      responseType,
      fileName,
    });
  },
  post: ({ url, headers, optionOverride, payload, useParams }: IRequest) => {
    return Request({ method: 'post', url, headers, optionOverride, payload, useParams });
  },
  patch: ({ url, headers, optionOverride, payload }: IRequest) => {
    return Request({ method: 'patch', url, headers, optionOverride, payload });
  },
  put: ({ url, headers, optionOverride, payload }: IRequest) => {
    return Request({ method: 'put', url, headers, optionOverride, payload });
  },
  delete: ({ url, headers, optionOverride, payload }: IRequest) => {
    return Request({ method: 'delete', url, headers, optionOverride, payload });
  },
};

export default api;
