import { useEffect, useState } from 'react';
import { useAppDispatch } from 'model';
import { Events, log, useRoutes } from 'lib';
import { signIn } from 'pages/common';
import { MENU } from '@/pages/router';
import { _action, _event } from '.';
import Presenter from './presenter';
import { FailedReasonType, SignInPayload } from './type';
import { getLocations } from '@/lib/apis/infra';
import { spinner } from '@/assets/images';

/**
 * @name Container
 */
export default function Container() {
  const dispatch = useAppDispatch();
  const { navigate } = useRoutes();
  const [popupVisible, setPopupVisible] = useState(false);
  const [failedReason, setFailedReason] = useState<FailedReasonType>('id');

  const onInit = (data: { name: string; email: string; tokens: { access_token: string } }) => {
    // console.clear();
    log(`ACELO EV`);
    log(data?.name, `font-size:12px;color:#888`);
    log(data?.email, `font-size:12px;color:#888`);
    log(data?.tokens?.access_token, `font-size:12px;color:Teal;`);
    log(`*---------------------------*`, `font-size:12px;color:SlateBlue;`);
    // dispatch(_action.setInit());
  };

  const [loading, isLoading] = useState<boolean>(false);

  const onSignIn = async (payload: SignInPayload) => {
    isLoading(true);
    const res = await signIn({ payload, dispatch });
    if (res.status === 'info') {
      isLoading(false);
      navigate(MENU.AUTH.COMPANY_INFO);
    }
    if (res?.status === 'ok') {
      isLoading(false);
      onInit(res as unknown as { name: string; email: string; tokens: { access_token: string } });
      navigate(MENU.DASHBOARD);
    } else {
      isLoading(false);
      setPopupVisible(true);
    }
  };

  const onMode = (mode: string) => {
    dispatch(_action.setMode(mode));
  };

  // TODO 삭제
  useEffect(() => {
    // 초기화
    Events.addListener(_event.SET_MODE, onMode);
    return () => {
      Events.removeListener(_event.SET_MODE, onMode);
    };
  }, []);

  return (
    <div>
      {loading && (
        <div
          style={{
            zIndex: 3000,
            position: 'fixed',
            inset: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}>
          <img src={spinner} width={300} />
        </div>
      )}
      <Presenter
        onSignIn={onSignIn}
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
        failedReason={failedReason}
      />
    </div>
  );
}
