/**
 * @name Content
 * @description
 */
import styled from 'styled-components';
import ReactPDF, { PDFDownloadLink, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Button, Div, ImageButton, Row } from 'lib';
import PdfDocument from './pdf-document';
import { icon_ext_pdf } from 'assets/images';
export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <Row>
        {/* <PDFDownloadLink document={<PdfDocument />} fileName={`file.pdf`}>
          {({ blob, url, loading, error }: any) =>
            loading ? 'Loading document...' : <ImageButton style={{ width: 60 }} source={icon_ext_pdf} />
          }
        </PDFDownloadLink> */}
        {/* PDF시작 */}
        <PDFViewer>
          <PdfDocument />
        </PDFViewer>
      </Row>
    </Content>
  );
}
//*--------------------------------------------------*
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Content = styled.div`
  /**-------본문--*/
  .wrap {
  }
  /* <!--media query --> */
  @media all and (min-width: 0) and (max-width: 480px) {
    /* background-color: red; */
  }
  @media all and (min-width: 480px) and (max-width: 1000px) {
    /* background-color: green; */
  }
  @media all and (min-width: 1000px) {
    /* background-color: blue; */
  }
`;
