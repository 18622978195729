import React from 'react';
import { App, BackButton } from 'components';
import { useTranslation } from 'react-i18next';
import { COLOR } from 'styles';
import { Button, Div, formatDate } from 'lib';
import { useAppDispatch, useAppSelector } from 'model';
import { nanoid } from 'nanoid';
import {
  ButtonAddModels,
  ButtonAddVendorModels,
  PopupContainer,
  PopupCreate,
  SubmitCancel,
  TextFiledCalendar,
  TextFiledGroup,
  TextInput,
} from '@/components/form';
import { actions } from '@/model/modules/device';
import {
  Header,
  NameInputContainer,
  RoamingContainer,
  RoamingHidingArea,
  RoamingInfoWrapper,
  RoamingInputContainer,
  RoamingInputLabel,
  RoamingInputsContainer,
  RoamingTitle,
  SubmitWrapper,
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
} from './ChargerRegister.styles';
import SelectStation from '@/components/organisms/Popups/SelectStation';
import { IChargePointModelConnector, IVendor } from '@/types/infra';
import { ICalendar } from '@/types/calendar';
import Checkbox from '@/components/atoms/Checkbox';
import { ChargerRegisterProps, RoamingField } from './ChargerRegister.type';
import { displayCoordinate, displayUnits } from '@/lib/util/displayData';
import typography from '@/styles/typography';
import Badge from '@/components/atoms/Badge';
import { SCREEN_MODE } from '@/types/mode';

export default function ChargerRegisterPresenter({
  isModify,
  popupCancelVisible,
  handleCloseCancelPopup,
  onCancelPopup,
  handleOpenStationSelectPopup,
  selectedVendor,
  onSelectedModel,
  nameInputDesc,
  validationResult,
  requiredFieldsWarning,
  onNameChangeHandler,
  forms,
  handleIsRoamingRequired,
  isNameDuplicated,
  onHeartbeatChangeHandler,
  handleRoamingInput,
  onSubmit,
  handleCancelStationSelectPopup,
  stationSelectPopupVisible,
  popupSuccessVisible,
  handleDone,
  popupFailedVisible,
  handleCloseFailedPopup,
}: ChargerRegisterProps) {
  const { t } = useTranslation('common');
  const { selectedStation, selectedModel, payload, view, mode } = useAppSelector(state => state.device);
  const dispatch = useAppDispatch();

  return (
    <App>
      <Header>
        <BackButton label={isModify ? view.name : t('forms.charger.registerCharger')} onClick={onCancelPopup} />
      </Header>
      <main className="inc_form" style={{ width: 1080 }}>
        <TextFiledGroup
          label={t('pages.infra.chargerManage.installationLocationInfo')}
          required
          component={
            <>
              <Table>
                <TableHead>
                  <TR>
                    <TH>{t('pages.accountManage.station.manageStation.name')}</TH>
                    <TH>{t('pages.accountManage.station.manageStation.id')}</TH>
                    <TH>{t('pages.accountManage.station.manageStation.type')}</TH>
                    <TH>{t('pages.accountManage.station.manageStation.location')}</TH>
                    <TH>{t('pages.accountManage.station.manageStation.address')}</TH>
                    <TH>{`${t('pages.accountManage.station.manageStation.latitude')},${t(
                      'pages.accountManage.station.manageStation.longitude',
                    )}`}</TH>
                  </TR>
                </TableHead>
                {selectedStation && (
                  <TableBody>
                    <TR>
                      <TD $customFont={typography.body5}>{selectedStation.name}</TD>
                      <TD $customFont={typography.body4}>{selectedStation.code}</TD>
                      <TD $customFont={typography.body6}>
                        {`${selectedStation.station_type?.type_group} > ${selectedStation.station_type?.type_detail}`}
                      </TD>
                      <TD $customFont={typography.body4}>{selectedStation.kr_location?.name}</TD>
                      <TD color={COLOR.GRAY2} $customFont={typography.caption1}>
                        {`${selectedStation.address} ${selectedStation.address_detail}`}
                      </TD>
                      <TD $customFont={typography.body4}>
                        {displayCoordinate(selectedStation.latitude, selectedStation.longitude)}
                      </TD>
                    </TR>
                  </TableBody>
                )}
              </Table>
              {!selectedStation && (
                <ButtonAddModels
                  title={t('common.buttons.selectStationToAddCharger')}
                  label={t('common.buttons.selectStation')}
                  onClick={handleOpenStationSelectPopup}
                />
              )}
            </>
          }
        />
        <TextFiledGroup
          label={t('pages.infra.chargerManage.manufacturerModelInfo')}
          required
          component={
            <>
              <Table>
                <TableHead>
                  <TR>
                    <TH>{t('forms.model.model')}</TH>
                    <TH>{t('forms.model.manufacturerName')}</TH>
                    <TH>{t('forms.model.manufacturerID')}</TH>
                    <TH>{t('forms.model.type')}</TH>
                    <TH>{t('forms.model.simultaneousCharging')}</TH>
                    <TH>
                      {t('common.maximumOutput')}
                      <br />
                      {t('common.unit.brackets.kWh')}
                    </TH>
                    <TH>{t('forms.model.numberOfConnectors')}</TH>
                    <TH>{t('forms.model.typeOfConnectors')}</TH>
                    <TH>{`${t('common.maximumVoltage')}${t('common.unit.brackets.V')}`}</TH>
                    <TH>{`${t('common.maximumAmpere')}${t('common.unit.brackets.A')}`}</TH>
                    <TH>{`${t('common.efficiency')}${t('common.unit.brackets.percent')}`}</TH>
                  </TR>
                </TableHead>
                {selectedModel && (
                  <TableBody>
                    <TR>
                      <TD $customFont={typography.body5}>{selectedModel.name}</TD>
                      <TD $customFont={typography.body4}>{selectedModel.vendor.name}</TD>
                      <TD $customFont={typography.body4}>{selectedModel.vendor.vendor_id}</TD>
                      <TD>
                        <Badge
                          mode={selectedModel.charger_type === 'FAST' ? 'Fast' : 'Standard'}
                          label={selectedModel.charger_type === 'FAST' ? '급속' : '완속'}
                        />
                      </TD>
                      <TD>
                        <Badge
                          mode={selectedModel.is_concurrency ? 'Available' : 'Unavailable'}
                          label={selectedModel.is_concurrency ? '가능' : '불가능'}
                        />
                      </TD>
                      <TD $customFont={typography.body6}>{selectedModel.max_power_kw}</TD>
                      <TD $customFont={typography.body6}>{`${selectedModel.connector_count}${t(
                        'common.unit.numberOf',
                      )}`}</TD>
                      <TD $customFont={typography.body6}>
                        {selectedModel.model_connectors &&
                          selectedModel.model_connectors.map((connector: IChargePointModelConnector, index: number) => (
                            <React.Fragment key={nanoid()}>
                              {index > 0 && <br />}
                              {`${t(`common.connector.${connector.types}`)} / ${displayUnits(connector.max_kw, 'kW')}`}
                            </React.Fragment>
                          ))}
                      </TD>
                      <TD $customFont={typography.body6}>{displayUnits(selectedModel.max_voltage, 'V')}</TD>
                      <TD $customFont={typography.body6}>{displayUnits(selectedModel.max_ampare, 'A')}</TD>
                      <TD $customFont={typography.body6}>{displayUnits(selectedModel.efficiencies, '%')}</TD>
                    </TR>
                  </TableBody>
                )}
              </Table>
              {!selectedModel && !selectedVendor && (
                <ButtonAddVendorModels
                  onChange={(modelSeq: string, vendor?: IVendor) => {
                    onSelectedModel(modelSeq, vendor);
                    dispatch(actions.setPayload({ model_seq: modelSeq }));
                  }}
                />
              )}
            </>
          }
        />
        <article className="w400 auto" style={{ width: 400 }}>
          <TextFiledGroup
            required
            label={t('forms.model.chargerName')}
            desc={nameInputDesc}
            validCheckResult={validationResult.name}
            displayWarning={requiredFieldsWarning?.name}
            warningText={t('forms.model.nameEmptyWarning')}
            displayWarning2={requiredFieldsWarning?.requireDuplicateCheck}
            warningText2={t('forms.charger.nameDuplicateCheckWarning')}
            component={
              <NameInputContainer>
                <TextInput
                  defaultValue={view.name}
                  disabled={selectedStation === null}
                  style={{ maxWidth: 270 }}
                  placeholder={t('forms.model.manufacturerPlaceholder')}
                  name="name"
                  onChange={onNameChangeHandler}
                />
                <Button
                  label={t('common.buttons.duplicateCheck')}
                  disabled={forms.name === '' || view.name === payload.name}
                  onClick={isNameDuplicated}
                  style={{ flex: 1 }}
                />
              </NameInputContainer>
            }
          />
          <TextFiledGroup
            label={t('forms.model.heartbeatInterval')}
            required
            desc={t('forms.model.heartbeatIntervalDesc')}
            displayWarning={requiredFieldsWarning?.heartbeat_interval}
            warningText={t('forms.model.heartbeatIntervalWarning')}
            component={
              <TextInput
                type="number"
                defaultValue={view.heartbeat_interval ?? payload?.heartbeat_interval}
                disabled={false}
                placeholder={t('forms.model.heartbeatIntervalWarning')}
                name="heartbeat_interval"
                onChange={onHeartbeatChangeHandler}
                value={payload?.heartbeat_interval}
              />
            }
          />
          {/* <TextFiledGroup */}
          {/*  label={t('forms.model.manufacturerCountry')} */}
          {/*  component={ */}
          {/*    <CountriesSearch */}
          {/*      popupStyle={{ height: 200 }} */}
          {/*      onChange={(item: ICountry) => { */}
          {/*        dispatch(actions.setPayload({ country_code: item?.code2 })); */}
          {/*      }} */}
          {/*    /> */}
          {/*  } */}
          {/* /> */}
          <TextFiledGroup
            label={t('forms.model.date')}
            component={
              <TextFiledCalendar
                disabled={false}
                value={isModify ? formatDate(view.produced_at) ?? formatDate(payload?.produced_at) : ''}
                placeholder={t('forms.charger.producedDatePlaceholder')}
                onChange={(data: ICalendar) => {
                  dispatch(
                    actions.setPayload({ ...payload, produced_at: `${data?.year}.${data?.month}.${data?.date}` }),
                  );
                }}
              />
            }
          />
          <TextFiledGroup
            label={t('forms.model.installationDate')}
            component={
              <TextFiledCalendar
                disabled={false}
                value={isModify ? formatDate(view.installed_at) ?? formatDate(payload?.installed_at) : ''}
                placeholder={t('forms.charger.installedDatePlaceholder')}
                onChange={(data: ICalendar) => {
                  dispatch(
                    actions.setPayload({ ...payload, installed_at: `${data?.year}.${data?.month}.${data?.date}` }),
                  );
                }}
              />
            }
          />
          {/* 환경부 로밍 - 체크박스 기능 삭제 */}
          {/* <RoamingInfoWrapper>
            <Checkbox
              label={t('forms.charger.roamingME')}
              id="check-roaming-ME"
              isCheckedFromParent={payload.is_roaming}
              onClick={handleIsRoamingRequired}
            />
            <RoamingHidingArea $isRoamingChecked={payload.is_roaming}>
              <RoamingContainer>
                <RoamingTitle>{t('forms.charger.connector1')}</RoamingTitle>
                <RoamingInputsContainer>
                  <RoamingInputContainer>
                    <RoamingInputLabel>{t('forms.charger.roamingStationId')}</RoamingInputLabel>
                    <TextInput
                      defaultValue={payload.roaming_station_id}
                      disabled={false}
                      placeholder={t('forms.charger.stationInputPlaceholder')}
                      name="left-roaming-station-id"
                      onChange={value => handleRoamingInput({ fieldName: RoamingField.STATION_ID, value })}
                    />
                  </RoamingInputContainer>
                  <RoamingInputContainer>
                    <RoamingInputLabel>{t('forms.charger.roamingChargerId')}</RoamingInputLabel>
                    <TextInput
                      defaultValue={payload.roaming_chargepoint_id1}
                      disabled={false}
                      placeholder={t('forms.charger.chargerInputPlaceholder')}
                      name="left-roaming-charge-point-id"
                      onChange={value => handleRoamingInput({ fieldName: RoamingField.CHARGEPOINT_ID1, value })}
                    />
                  </RoamingInputContainer>
                </RoamingInputsContainer>
              </RoamingContainer>
              <RoamingContainer>
                <RoamingTitle>{t('forms.charger.connector2')}</RoamingTitle>
                <RoamingInputsContainer>
                  <RoamingInputContainer>
                    <RoamingInputLabel>{t('forms.charger.roamingStationId')}</RoamingInputLabel>
                    <TextInput
                      defaultValue={payload.roaming_station_id}
                      disabled={false}
                      placeholder={t('forms.charger.stationInputPlaceholder')}
                      name="right-roaming-station-id"
                      onChange={value => handleRoamingInput({ fieldName: RoamingField.STATION_ID, value })}
                    />
                  </RoamingInputContainer>
                  <RoamingInputContainer>
                    <RoamingInputLabel>{t('forms.charger.roamingChargerId')}</RoamingInputLabel>
                    <TextInput
                      defaultValue={payload.roaming_chargepoint_id2}
                      disabled={false}
                      placeholder={t('forms.charger.chargerInputPlaceholder')}
                      name="right-roaming-charge-point-id"
                      onChange={value => handleRoamingInput({ fieldName: RoamingField.CHARGEPOINT_ID2, value })}
                    />
                  </RoamingInputContainer>
                </RoamingInputsContainer>
              </RoamingContainer>
            </RoamingHidingArea>
          </RoamingInfoWrapper> */}
        </article>
      </main>
      <SubmitWrapper>
        <Div>
          <Button
            full
            label={t('common.buttons.cancel')}
            color={COLOR.GRAY1}
            borderColor={COLOR.GRAY2}
            bg="transparent"
            onClick={() => {
              onCancelPopup();
            }}
          />
          <div style={{ width: 32 }} />
          <Button
            full
            label={isModify ? t('common.buttons.updateAction') : t('common.buttons.registerAction')}
            onClick={
              onSubmit
              // () => {
              // 초기화
              // dispatch(actions.setInit())
              // Events.emit(_event.SUBMIT_CREATE, payload);
              // }
            }
          />
        </Div>
      </SubmitWrapper>
      <PopupContainer onClose={handleCancelStationSelectPopup} visible={stationSelectPopupVisible}>
        <SelectStation handleClosePopup={handleCancelStationSelectPopup} />
      </PopupContainer>
      <PopupContainer visible={popupSuccessVisible} onClose={handleDone}>
        <PopupCreate
          title={isModify ? t('common.toast.successToModifyCharger') : t('common.toast.successToRegisterCharger')}
          message={isModify ? t('common.toast.successToModifyCharger2') : t('common.toast.successToRegisterCharger2')}
          onConfirm={handleDone}
        />
      </PopupContainer>
      <PopupContainer visible={popupFailedVisible} onClose={handleCloseFailedPopup}>
        <PopupCreate
          warning
          title={isModify ? t('common.toast.failedUpdateChargerTitle') : t('common.toast.failedRegisterChargerTitle')}
          message={
            isModify ? t('common.toast.failedUpdateChargerMessage') : t('common.toast.failedRegisterChargerMessage')
          }
          onConfirm={handleCloseFailedPopup}
        />
      </PopupContainer>
      <PopupContainer visible={popupCancelVisible}>
        <PopupCreate
          warning
          title={isModify ? t('common.toast.cancelUpdateCharger') : t('common.toast.cancelRegisterCharger')}
          message={isModify ? t('common.toast.askCancel') : t('common.toast.askCancelWithoutSave')}
          footer={
            <SubmitCancel
              confirmLabel={
                isModify ? t('common.buttons.cancelUpdateAction') : t('common.buttons.cancelRegisterAction')
              }
              onCancel={handleCloseCancelPopup}
              onConfirm={handleDone}
            />
          }
        />
      </PopupContainer>
    </App>
  );
}
