import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import typography from '@/styles/typography';
import Pagination from '@/components/organisms/Paginations/Pagination';
import {
  InnerCellContainer,
  InnerCellTextButton,
  InnerTD,
  InnerTR,
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
  Wrapper,
} from './ChargepointTable.style';
import { useAppDispatch, useAppSelector } from '@/model/index';
import { DeviceItem, ICharger } from '@/types/infra';
import Badge from '@/components/atoms/Badge';
import { BadgeMode, BadgeType } from '@/components/atoms/Badge/Badge.type';
import { DeviceStatus } from '@/lib/apis/dashborad/type';
import { COLOR } from '@/styles/index';
import { MENU } from '@/pages/router';

export default function ChargepointTable({
  itemsPerPage,
  currentPage,
  pageHandler,
  queryString,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  queryString: string;
}) {
  const { t } = useTranslation('common');
  const { list, totalCount } = useAppSelector(state => state.chargepoint);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchKeyword = searchParams.get('search');

  const getDeviceStatus = (
    device: DeviceItem,
    charger?: ICharger,
  ): {
    mode: string;
    label: string;
  } => {
    const obj = {
      Available: {
        mode: 'Available',
        label: '사용가능',
      },
      Preparing: {
        mode: 'Connected',
        label: '충전중',
      },
      Charging: {
        mode: 'Connected',
        label: '충전중',
      },
      SuspendedEVSE: {
        mode: 'Unavailable',
        label: '사용불가능',
      },
      SuspendedEV: {
        mode: 'Unavailable',
        label: '사용불가능',
      },
      Finishing: {
        mode: 'Connected',
        label: '충전중',
      },
      Reserved: {
        mode: 'Unavailable',
        label: '사용불가능',
      },
      Unavailable: {
        mode: 'Unavailable',
        label: '사용불가능',
      },
      Faulted: {
        mode: 'Unavailable',
        label: '사용불가능',
      },
      CommunicationFault: {
        mode: 'Unavailable',
        label: '사용불가능',
      },
    };
    if (charger) {
      if (!charger.channel_id)
        return {
          mode: 'Unavailable',
          label: '사용불가능',
        };
    }
    const result = obj[device.status] ?? {
      mode: 'Unavailable',
      label: '사용불가능',
    };
    return result;
  };

  const handleNavigateToDetail = (seq: string) => {
    navigate(`${MENU.OPERATION.CHARGEPOINT_OPERATION_DETAIL}/${seq}`);
  };

  return (
    <Wrapper>
      <Table>
        <colgroup>
          <col style={{ width: 56 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 138 }} />
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 104 }} />
          {/* <col style={{ minWidth: 138 }} /> */}
          <col style={{ minWidth: 61 }} />
          <col style={{ minWidth: 61 }} />
        </colgroup>
        <TableHead>
          <TR>
            <TH>No.</TH>
            <TH>{t('forms.model.chargerName')}</TH>
            <TH>{t('forms.charger.name')}</TH>
            <TH>{t('forms.model.type')}</TH>
            <TH>{t('pages.infra.chargerManage.search.concurrency')}</TH>
            {/* <TH>{t('forms.model.firmware')}</TH> */}
            {/* <TH>{t('forms.charger.failStatus')}</TH> */}
            <TH>{t('forms.charger.chargerStatus')}</TH>
            <TH>{t('forms.charger.connectorStatus')}</TH>
            {/* TODO: 차후 추가해야 합니다. */}
            {/* <TH>{t('common.buttons.remoteControl')}</TH>
            <TH>{t('common.buttons.multiControl')}</TH> */}
          </TR>
        </TableHead>
        <TableBody>
          {list.length > 0 &&
            list.map((item: ICharger, index: number) => (
              <TR key={nanoid()}>
                <TD $customFont={typography.body4}>
                  {totalCount - (currentPage - 1) * itemsPerPage - index}
                  {/* {(currentPage - 1) * 10 + (idx + 1)} */}
                </TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.seq)}>
                      {item.name}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body5} color={COLOR.BLUE4}>
                  <InnerCellContainer>
                    <InnerCellTextButton onClick={() => handleNavigateToDetail(item.seq)}>
                      {item.station.name}
                    </InnerCellTextButton>
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4}>
                  <InnerCellContainer>
                    <Badge
                      mode={item.model.charger_type === 'FAST' ? 'Fast' : 'Standard'}
                      label={item.model.charger_type === 'FAST' ? '급속' : '완속'}
                    />
                  </InnerCellContainer>
                </TD>
                <TD $customFont={typography.body4}>
                  <InnerCellContainer>
                    <Badge
                      mode={item.model.is_concurrency ? 'Available' : 'Unavailable'}
                      label={item.model.is_concurrency ? '가능' : '불가'}
                    />
                  </InnerCellContainer>
                </TD>
                {/* <TD $customFont={typography.body5}>{item.firmware_version ?? '-'}</TD> */}
                {/* 
                NOTE : jjh
                trouble_reports에서 가지고 옴 
                */}
                <TD $customFont={typography.body4}>
                  <InnerCellContainer>
                    <Badge
                      mode={
                        getDeviceStatus(
                          item.device_status_items?.find(device => device.connector_number === 0) as DeviceItem,
                        ).mode as unknown as BadgeType
                      }
                      label={
                        getDeviceStatus(
                          item.device_status_items?.find(device => device.connector_number === 0) as DeviceItem,
                        ).label
                      }
                    />
                  </InnerCellContainer>
                </TD>
                {/* <TD $customFont={typography.body4}>
                  <InnerCellContainer>
                    <Badge
                      mode={
                        getDeviceStatus(
                          item.device_status_items?.find(device => device.connector_number === 0) as DeviceItem,
                          item,
                        ).mode as unknown as BadgeType
                      }
                      label={
                        getDeviceStatus(
                          item.device_status_items?.find(device => device.connector_number === 0) as DeviceItem,
                          item,
                        ).label
                      }
                    />
                  </InnerCellContainer>
                </TD> */}
                <TD>
                  {/* 
                    NOTE : db에 값이 제대로 매핑되서 들어가 있지 않아서 추후에 수정해야 될 거 같습니다.
                  */}
                  {item.connectors?.length > 0 &&
                    item.connectors.map(connector => (
                      <InnerTR key={nanoid()}>
                        <InnerTD $customFont={typography.body5}>{`${t(
                          `common.connector.${connector.types}`,
                        )}`}</InnerTD>
                        <InnerTD $customFont={typography.body4}>
                          <InnerCellContainer>
                            <Badge
                              mode={
                                getDeviceStatus(
                                  item.device_status_items.find(
                                    device => connector.connector_number === device.connector_number,
                                  ) as DeviceItem,
                                  item,
                                ).mode as unknown as BadgeType
                              }
                              label={
                                getDeviceStatus(
                                  item.device_status_items.find(
                                    device => connector.connector_number === device.connector_number,
                                  ) as DeviceItem,
                                  item,
                                ).label
                              }
                            />
                          </InnerCellContainer>
                        </InnerTD>
                      </InnerTR>
                    ))}
                </TD>
              </TR>
            ))}
        </TableBody>
      </Table>
      {list.length > 0 && (
        <Pagination
          listLength={totalCount}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onClick={pageHandler}
        />
      )}
    </Wrapper>
  );
}
