import React, { useEffect } from 'react';
import { useAppDispatch } from 'model';
import { Events, log, objectToQueryString, SESSION_STORAGE, useAxios, useContext } from 'lib';
import { API_HOST } from 'config';
import dayjs from 'dayjs';
import DashboardPresenter from './Dashboard.presenter';
import { actions } from '@/model/modules/dashboard';
/**
 * @name DashboardContainer
 */
export default function DashboardContainer() {
  const { get } = useAxios();
  const { business_code } = useContext();
  const dispatch = useAppDispatch();

  async function onInit() {
    // 한달전
    // const today = dayjs().add(-30, 'day').format('YYYYMMDD')
    const today = dayjs(new Date());
    dispatch(actions.setPeriod({ tz_start_date: today }));
    await onFilter({ calc_type: 'REALTIME', tz_start_date: today, tz_end_date: today });
    // // token출력
    // const token = sessionStorage.getItem(SESSION_STORAGE.TOKEN);
    // // console.log(token);
    // console.log(`%c${token}`, 'width:100%;font-size:10px;padding:5px 10px;color:#ccc;');
  }
  // 통계유형,시작날짜,종료날짜,충전소선택
  async function onFilter(payload?: any) {
    const temp = { ...payload, business_code };
    const queryStringPayload = objectToQueryString(temp);
    // console.group()
    // console.log(temp)
    // console.groupEnd()
    // 충전소매출랭킹
    // const sale = await get(`${API_HOST}/dashboards/rank/stations/sale?${queryStringPayload}`);
    // if (sale?.isSuccess) {
    //   dispatch(actions.setRank(sale));
    // }

    // // 매출총액
    // const total = await get(`${API_HOST}/dashboards/sales/total?${queryStringPayload}`);
    // if (total?.isSuccess) {
    //   dispatch(actions.setWidget({ total: total?.data }));
    // }
    // // 이용자수
    // const count = await get(`${API_HOST}/dashboards/users/count?${queryStringPayload}`);
    // if (count?.isSuccess) {
    //   dispatch(actions.setWidget({ count: count?.data }));
    // }
    // // 충전기 이용횟수
    // const uses = await get(`${API_HOST}/dashboards/chargepoint/uses?${queryStringPayload}`);
    // if (uses?.isSuccess) {
    //   dispatch(actions.setWidget({ uses: uses?.data }));
    // }
    // // 장애
    // const troubles = await get(`${API_HOST}/dashboards/chargepoint/troubles?${queryStringPayload}`);
    // if (troubles?.isSuccess) {
    //   dispatch(actions.setWidget({ troubles: troubles?.data }));
    // }
    // // 최고매출시간
    // const hours = await get(`${API_HOST}/dashboards/sales/hours?${queryStringPayload}`);
    // if (hours?.isSuccess) {
    //   dispatch(actions.setWidget({ hours: hours?.data }));
    // }
    // // 충전소타입별매출
    // const type = await get(`${API_HOST}/dashboards/sales/station/type?${queryStringPayload}`);
    // if (type?.isSuccess) {
    //   dispatch(actions.setWidget({ type: type?.data }));
    // }
  }

  useEffect(() => {
    onInit();
    // addListener
    // Events.addListener(_event.SUBMIT_FILTER, onFilter);
    return () => {
      // removeListener
      // Events.removeListener(_event.SUBMIT_FILTER, onFilter);
    };
  }, []);

  return <DashboardPresenter />;
}
