import { API_HOST } from '@/config/index';
import api from '@/lib/apis/api';
import { IResponse } from '../../api/type';
import { Paging } from '@/lib/apis/infra/type';
import { objectToQueryString } from '@/lib/index';
import { ITrouble } from '@/types/trouble';

export const getTroubleList = async (payload: Paging): Promise<IResponse<ITrouble[]>> => {
  const temp = { ...payload, size: 10 };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/troubles/cpo?${param}`;
  return api.get({ url });
};

export const getTroubleExcelFile = async ({ payload, fileName }: { payload?: Paging; fileName: string }) => {
  const temp = { ...payload, size: 10 };
  const param = objectToQueryString(temp);
  const url = `${API_HOST}/troubles/cpo/excel?${param}`;
  return api.get({ url, responseType: 'blob', fileName });
};
