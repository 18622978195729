import React from 'react';
import { App } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'model';
import { COLOR } from 'styles';
import { addComma, H3, Row, Text } from '@/lib/index';
import { TextFiledSearch } from '@/components/form';
import ChargepointTable from '@/components/organisms/Tables/ChargepointTable';

export default function ChargepointListPresenter({
  itemsPerPage,
  currentPage,
  pageHandler,
  handleSearch,
  handleSearchKeyword,
  searchKeyword,
  queryString,
}: {
  itemsPerPage: number;
  currentPage: number;
  pageHandler: (page: number) => void;
  handleSearch: () => void;
  handleSearchKeyword: (str: string) => void;
  searchKeyword?: string;
  queryString: string;
}) {
  const { t } = useTranslation('common');
  const { totalCount } = useAppSelector(state => state.chargepoint);
  const dispatch = useAppDispatch();
  return (
    <App>
      <Header>
        <Row>
          <div style={{ marginRight: 20 }}>
            <H3 color={COLOR.BLACK3}>{t('pages.operation.status')}</H3>
            <Text color={COLOR.BLACK3} className="body6">
              {`${t('common.unit.total')} ${addComma(totalCount)}${t('common.unit.cases')}`}
            </Text>
          </div>
          <div className="search" style={{ marginLeft: 20 }}>
            <TextFiledSearch
              placeholder={t('pages.operation.chargepoint.searchPlaceholder')}
              defaultValue={searchKeyword}
              onChange={str => {
                handleSearchKeyword(str);
              }}
              onSubmit={() => {
                handleSearch();
              }}
            />
          </div>
        </Row>
      </Header>
      <ChargepointTable
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        pageHandler={pageHandler}
        queryString={queryString}
      />
    </App>
  );
}

export const Header = styled.div`
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR.GRAY5};
  align-items: center;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterWrapper = styled.div`
  padding: 8px 32px;
`;
